import React, { FunctionComponent, useEffect, useState } from 'react';

import Slider, { SliderItems } from '../Slider/Slider';

import DatavizCardLarge from '../DatavizCards/DatavizCardLarge/DatavizCardLarge';
import DatavizCardDonut from '../DatavizCards/DatavizCardDonut/DatavizCardDonut';
import DatavizCardBar from '../DatavizCards/DatavizCardBar/DatavizCardBar';

import './styles.scss';
import SelectContainer from '../Select/SelectContainer';
import { PERIMETRE } from '../../../templates/PortraitTemplate';

const Statistics: FunctionComponent<{
  type: string,
  data: any,
  dataFrance: any,
  sources: string[],
  selected: any,
  setSelected: any
}
> = ({
  type,
  data,
  dataFrance,
  sources,
  selected,
  setSelected
}) => {

    const [loading, setLoading] = useState(false);
    const [source, setSource]: null | any = useState(null);

    const [sliderArray, setSliderArray] = useState<SliderItems[] | null>(null);

    useEffect(() => {
      let newSources: any = {};
      sources.map((s: any) => {
        newSources[s.slug] = {
          indicator: s.indicator,
          source_complete: s.source_complete,
          source_front: s.source_front,
          source_url: s.source_url
        }
      })
      setSource(newSources);
    }, [])

    useEffect(() => {
      if (!!source && !!selected) {
        // console.log('');
        // console.log('%c ------ SET SLIDER -------', 'font-size:14px;');
        // console.log(source);
        // console.log(selected);
        // console.log('%c -------------------------', 'font-size:14px;');

        setSliderArray([
          {
            slide: [
              {
                cmpClassname: "slide_child w100",
                cmp: <DatavizCardLarge
                  key={`Largew100_slide1}`}
                  title={source?.population.indicator}
                  nbBig={PERIMETRE.QPV === type ? selected.pop_2015_qpv.toLocaleString('FR') : selected.population.toLocaleString('FR')}
                  nbBigLabel="habitants"
                  nbQPV={!!selected && !!selected.qpv_count ? selected.qpv_count : ''}
                  location={PERIMETRE.QPV === type ? selected.code : selected.name}
                  imgSrc="population"
                  link={source.population.source_url}
                  source={!!source.population.source_front ? `Source: ${source.population.source_front}` : source.population.source_front}
                  tooltip={source.population.source_complete}
                  type={type}
                />
              }, {
                cmpClassname: "slide_child w40",
                cmp: <DatavizCardDonut
                  key={`Donutw40_slide1`}
                  title={!!source.pop0_14_fm ? source.pop0_14_fm.indicator : source.pop0_14.indicator}
                  percent={PERIMETRE.QPV === type ? selected?.pop0_14_2016 : selected?.pop0_14}
                  large={false}
                  imgSrc="young"
                  link={!!source.pop0_14_fm ? source.pop0_14_fm.source_url : source.pop0_14.source_url}
                  source={`Source: ${!!source.pop0_14_fm ? source.pop0_14_fm.source_front : source.pop0_14.source_front}`}
                  tooltip={!!source.pop0_14_fm ? source.pop0_14_fm.source_complete : source.pop0_14.source_complete}
                />
              }, {
                cmpClassname: "slide_child w60",
                cmp: <DatavizCardDonut
                  key={`Donutw60_slide1`}
                  title={source.popf.indicator}
                  percent={PERIMETRE.QPV === type ? selected.popf_demo_2016 : selected.popf}
                  large={false}
                  imgSrc="women"
                  link={source.popf.source_url}
                  source={`Source: ${source.popf.source_front}`}
                  tooltip={source.popf.source_complete}
                />
              }]
          }, {
            slide: [{
              cmpClassname: "slide_child w100",
              cmp: <DatavizCardLarge
                key={`Largew100_slide2}`}
                title="Tissu économique"
                nbBig={'ND' !== selected.sirene ? selected.sirene.toLocaleString('FR') : 'ND'}
                nbBigLabel={source.ce.indicator}
                location={'null'}
                imgSrc="companies"
                link={source.ce.source_url}
                source={`Source: ${source.ce.source_front}`}
                tooltip={source.ce.source_complete}
                type={type}
              />
            }, {
              cmpClassname: "slide_child w50 slide_bar",
              cmp: <DatavizCardBar
                key={`Barw50_1__slide2`}
                title={source.tx.indicator}
                percent={PERIMETRE.QPV === type ? selected.tx_job_demo_2016 : selected.tx_job}
                percent_fr={!!dataFrance ? dataFrance.datas[0].tx_job : ''}
                nbBig={PERIMETRE.QPV === type ? selected.tx_job_demo_2016 : selected.tx_job}
                nbBigLabel={source.tx.indicator}
                location={'null'}
                imgSrc="young"
                link={source.tx.source_url}
                source={`Source: ${source.tx.source_front}`}
                tooltip={source.tx.source_complete}
              />
            }, {
              cmpClassname: "slide_child w50 slide_bar",
              cmp: <DatavizCardBar
                key={`Barw50_2_slide2`}
                title={source.tx15_24.indicator}
                percent={PERIMETRE.QPV === type ? selected.tx_job15_24_demo_2016 : selected.tx_job15_24}
                percent_fr={!!dataFrance ? dataFrance.datas[0].tx_job15_24 : ''}
                nbBig={PERIMETRE.QPV === type ? selected?.tx_job15_24_demo_2016 : selected.tx_job15_24}
                nbBigLabel={source.ce.indicator}
                location={'null'}
                imgSrc="women"
                link={source.tx15_24.source_url}
                source={`Source: ${source.tx15_24.source_front}`}
                tooltip={source.tx15_24.source_complete}
              />
            }]
          }
        ]);
      }
    }, [source, selected])

    return (
      <div className="portrait-statistics">
        <div className="nav-map">
          <h3 className="nav-title">Portrait des quartiers prioritaires de la ville</h3>
          <SelectContainer
            type={type}
            data={data}
            selected={selected}
            setSelected={setSelected}
          />
        </div>
        <div className="stats">
          {!loading && null !== source &&
            <div className="inner-stats">

              {(!!selected && !!sliderArray) && 
                <Slider
                  key={`slider-${type}`}
                  name={"sliderStatisques"}
                  className={"stats"}
                  mode={"slideshow"}
                  autoplay={false}
                  sliderItems={sliderArray}
                />
              }

              {!selected &&
                <div className="no-stats">
                  {type === PERIMETRE.REGION && <span>PRÉCISEZ OU cliquez sur LA CARTE POUR AFFICHER LE PORTRAIT d'une <b>région</b></span>}
                  {type === PERIMETRE.DEPARTEMENT && <span>PRÉCISEZ OU cliquez sur LA CARTE POUR AFFICHER LE PORTRAIT d'un <b>département</b></span>}
                  {type === PERIMETRE.OUTRE_MER && <span>PRÉCISEZ OU cliquez  sur LA CARTE POUR AFFICHER LE PORTRAIT<br /> d'un <b>territoire d'outre-mer</b></span>}
                  {type === PERIMETRE.QPV && <span>Choisissez un <b>qpv</b> en cherchant son nom<br /> ou en cherchant le nom d'une commune</span>}
                </div>
              }
            </div>
          }
        </div>
        {/* {sources.map((s: any, si: any) => (
                <li key={si}>{s.source_front}<br></br>{s.source_complete}<br></br>{s.source_url}</li>
            ))} */}
      </div>
    )
  }

export default Statistics;