import React, { useEffect, useState } from "react";
import ButtonRound from "../Buttons/ButtonRound";
import { FaArrowUp, FaArrowRight, FaArrowLeft, FaSortAlphaDown, FaSortAlphaDownAlt } from 'react-icons/fa';

import "./styles.scss";

interface SliderProps {
  name?: string,
  mode?: string,
  className?: string,
  autoplay?: boolean,
  sliderItems?: SliderItems[],
}

export interface SliderItems {
  slide?: SliderItemsChildren[],
}
export interface SliderItemsChildren {
  cmpClassname: string;
  cmp?: any;
  test?: string
}

const Slider = ({
  name,
  mode,
  className,
  autoplay,
  sliderItems
}: SliderProps) => {

  const [attr, setAttr] = useState<any>(null);
  
  useEffect(() => {
    setAttr(!!mode ? {[`data-uk-${mode}`]: "finite: false;autoplay: false;"} : {});
  }, []);

  return (
    <>
      {/* {console.log("SliderItems", sliderItems)} */}
      {(sliderItems && !!attr) &&
        <div key={`slider-${name}`} id={name} className={`${mode} ${mode}-stats`} {...attr} >
          <ul className={`${mode}_items uk-${mode}-items`}>
            {sliderItems?.map((item, i) => (
              <li className={`${mode}__item item`} key={name + `_${mode}Item_` + i}>
                <div className="item_inner">
                  {item.slide && item.slide.map((slideElt, s) => (
                    <div className={slideElt.cmpClassname} key={`is-${s}-${name}}`}>
                      {slideElt.cmp}
                    </div>
                  ))}
                </div>
              </li>
            )
            )}
          </ul>
          {!!sliderItems && sliderItems.length > 1 &&
            <div className={`${mode}_nav nav-arrows`}>
              <div className="nav_inner">
                <button className={`btn btn-no-text btn-nav btn-nav-prev`} data-uk-slideshow-item="0">
                  <span className="btn_icon"><i className="fa fas fa-arrow-left"></i></span>
                  <span className="btn_label">Précédent</span>
                </button>
                <button className={`btn btn-no-text btn-nav btn-nav-next`} data-uk-slideshow-item="1">
                  <span className="btn_icon"><i className="fa fas fa-arrow-right"></i></span>
                  <span className="btn_label">Suivant</span>
                </button>
              </div>
            </div>
          }
        </div>
      }
    </>
  )
};


export default Slider;