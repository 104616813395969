import React from 'react';

import './styles.scss';

interface DonutChartProps {
  percent: string,
}

export default function DonutChart({ percent }: DonutChartProps) {

  let radius = 7;

  const varPI = 3.14159265358979;
  let circumference = 2 * varPI * radius;
  let strokeWidth = "4px";
  const darkBlue = "#2C3E6E";
  const lightBlue = "#CFD8F0";


  const blueCircle = {
    strokeDasharray: circumference,
    strokeDashoffset: circumference - circumference * (isNaN(parseFloat(percent)) ? 0 : parseFloat(percent)) / 100,
    strokeWidth: strokeWidth
  }

  return (
    <div className="percent">
      <div className="donut">
        <svg className="circle-chart" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
          <circle className="circle-chart__background" stroke={lightBlue} strokeWidth={strokeWidth} fill="none" cx="50%" cy="50%" r={radius} />
          <circle className="circle-chart__circle" stroke={darkBlue} strokeLinecap="butt" fill="none" cx="50%" cy="50%" r={radius} style={blueCircle} />
        </svg>
      </div>
    </div>
  )
}
