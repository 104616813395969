import React from 'react';
import { useInView } from 'react-intersection-observer';
import CountUp from 'react-countup';
import InseeSrc from '../../InseeSrc/InseeSrc';
import DonutChart from "../../Charts/DonutChart/DonutChart";

import './styles.scss';

interface DatavizCardDonutProps {
  large: boolean,
  title: string,
  percent: string,
  imgSrc: string,
  link: string,
  source: string,
  tooltip: string
}

export default function DatavizCardDonut({ large, title, percent, imgSrc, link, source, tooltip }: DatavizCardDonutProps) {

  // if (percent != "ND") {
  //   percent = percent.replace(",", ".");
  // }

  // console.log('');
  //  console.log('___PERCENT___');
  // console.log(title, imgSrc);
  // console.log(percent);
  // console.log('');


  const [ref, inView] = useInView({
		threshold: 0.3,
		triggerOnce: false,
	});

  return (
    <div className={`card card-dataviz card-donut ${percent === "0" ? "filter" : ""}`} ref={ref}>
      <div className="card_inner">
        <h3 className="card__title"><span>{title.replace('(France métropolitaine)', '')}</span></h3>
        <div className="card__data data">
          <div className="data_inner">
            <DonutChart percent={inView ? percent : "ND"} />
            <div className="card__image">
              <img src={`/assets/images/svg/${percent === "ND" ? imgSrc+'-nodata' : imgSrc}.svg`} alt={` ${title}`} />
            </div>
          </div>
          <div className="data_inner">
            <div className="data__values">
              <div className="data___value">
                {percent === "ND" ? (
                  <span className="no-data">Pas de données</span>
                ) : (
                    <>
                      <span className={`number ${"ND" !== percent ? "" : "no-data"}`}>
                        {"ND" !== percent ? (
                          <CountUp
                            start={0}
                            end={inView ? parseFloat(percent.replace(",", ".")) : 0}
                            duration={0.75}
                            separator=""
                            decimals={(parseFloat(percent.replace(",", ".")) % 1 !== 0) ? 1 : 0}
                            decimal=","
                            prefix=""
                            suffix=""
                          // onEnd={() => console.log('Ended! 👏')}
                          // onStart={() => console.log('Started! 💨')}
                          />
                        ) : (
                            <>Pas de données</>
                          )
                        }
                      </span>
                      <span className="unit">{"ND" !== percent ? "%" : ""}</span>
                    </>
                  )}
              </div>
            </div>
            <div className="data__source">
              {percent !== "ND" &&
                <InseeSrc link={link} source={source} tooltip={tooltip} />
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}