import React, { FunctionComponent, useEffect, useState } from 'react';
import { PERIMETRE, selected } from '../../../../../templates/PortraitTemplate';

import "./styles.scss";

export interface item {
    value: string,
    label: string
}

const Guyane: FunctionComponent<{
    data: Array<Object>,
    selected?: any,
    region?: string,
    setSelected: React.Dispatch<selected | null>,
    mode?: string
}> = ({
    data,
    selected,
    region,
    setSelected,
    mode
}) => {


        const [selectItem, setSelectItem] = useState<item | null>(null);
        // const [items, setItems] = useState<item[]>([]);


        const loadInfos = (regNum: string, regName: string) => {
            if (null !== data) {
                setSelectItem({ value: regNum, label: regName });
                let item: selected = data.find((el: selected) => el.region === regNum)!;
                setSelected(item);
            }
        }

        return (
            <div className="map-wrapper-mini">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90 90">
                    {'outre-mer' === mode &&
                        <>
                            <g className="map-svg map-reunion map-fr-outre-mer map-decorative" fill="#ffffff" stroke="#CDEEF5" strokeLinejoin="round" strokeWidth="1">
                                <path d="M67.41 33.5L44.08 10.17H25.09V16l-8.84 8.84v16.99L25.42 51v6.5l-4.33 4.33v6.67l-7 7 4.33 4.33h8.16L31.42 75l3.16 3.33h17.34l9.83-10v-10l14.16-14.16V33.5z" />
                            </g>
                            <g className="map-svg map-reunion map-fr-outre-mer map-decorative" fill="none" stroke="#CDEEF5" strokeLinejoin="round" strokeWidth="1">
                                <path className="reg_under" d="M67.41 33.5L44.08 10.17H25.09V16l-8.84 8.84v16.99L25.42 51v6.5l-4.33 4.33v6.67l-7 7 4.33 4.33h8.16L31.42 75l3.16 3.33h17.34l9.83-10v-10l14.16-14.16V33.5z" />
                            </g>
                            <g className={`map-svg map-reunion map-fr-outre-mer region-selection-${selected?.region}`} strokeWidth="2">
                                <path className="reg reg-03" onClick={() => loadInfos("03", "")} d="M67.41 33.5L44.08 10.17H25.09V16l-8.84 8.84v16.99L25.42 51v6.5l-4.33 4.33v6.67l-7 7 4.33 4.33h8.16L31.42 75l3.16 3.33h17.34l9.83-10v-10l14.16-14.16V33.5z" />
                            </g>
                        </>
                    }
                </svg>
            </div>
        )
    }

export default Guyane;