import React from 'react';

import './styles.scss';

interface BarChartProps {
  percent: string
}

export default function BarChart({ percent }: BarChartProps) {

  // const percentStyle = {
  //   width: (isNaN(parseFloat(percent)) ? 0 : parseFloat(percent))
  // }
  

  return (
    <div className="bar-wrapper">
      <div className="bar" data-percent={(isNaN(parseFloat(percent)) ? 0 : parseFloat(percent)*10)}></div>
    </div>
  )
}