import React, { FunctionComponent, useEffect, useState } from 'react';
import { PERIMETRE, selected } from '../../../../../templates/PortraitTemplate';

import "./styles.scss";

export interface item {
    value: string,
    label: string
}

const Mayotte: FunctionComponent<{
    data: Array<Object>,
    selected?: any,
    region?: string,
    setSelected: React.Dispatch<selected | null>,
    mode?: string
}> = ({
    data,
    selected,
    region,
    setSelected,
    mode
}) => {


        const [selectItem, setSelectItem] = useState<item | null>(null);
        // const [items, setItems] = useState<item[]>([]);


        const loadInfos = (regNum: string, regName: string) => {
            if (null !== data) {
                setSelectItem({ value: regNum, label: regName });
                let item: selected = data.find((el: selected) => el.region === regNum)!;
                setSelected(item);
            }
        }

        return (
            <div className="map-wrapper-mini">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90 90">
                    {'outre-mer' === mode &&
                        <>
                            <g className="map-svg map-reunion map-fr-outre-mer map-decorative" fill="#ffffff" stroke="#CDEEF5" strokeLinejoin="round" strokeWidth="1">
                                <path d="M32.92 59.17v4.33h-5.33v-4.33h5.33zm24.66-25v-6.5l-4.5-4.5h-14l-9.83-9.83L19.59 23l6.33 6.33h6.67v18.5l10.5 10.5v5.5H32.92v6.5l6.33 6.33 4.5-4.5 4.17 4.17 4.67-4.67L45.92 65l10.67-10.67-6.5-6.5v-5.67l7.49-7.99zm5.84.33l4.67 4.67 2.33-2.33V27.5l-7 7z" />
                            </g>
                            <g className="map-svg map-reunion map-fr-outre-mer map-decorative" fill="none" stroke="#CDEEF5" strokeLinejoin="round" strokeWidth="1">
                                <path className="reg_under" d="M32.92 59.17v4.33h-5.33v-4.33h5.33zm24.66-25v-6.5l-4.5-4.5h-14l-9.83-9.83L19.59 23l6.33 6.33h6.67v18.5l10.5 10.5v5.5H32.92v6.5l6.33 6.33 4.5-4.5 4.17 4.17 4.67-4.67L45.92 65l10.67-10.67-6.5-6.5v-5.67l7.49-7.99zm5.84.33l4.67 4.67 2.33-2.33V27.5l-7 7z" />
                            </g>
                            <g className={`map-svg map-reunion map-fr-outre-mer region-selection-${selected?.region}`} strokeWidth="2">
                                <path className="reg reg-06" onClick={() => loadInfos("06", "")} d="M32.92 59.17v4.33h-5.33v-4.33h5.33zm24.66-25v-6.5l-4.5-4.5h-14l-9.83-9.83L19.59 23l6.33 6.33h6.67v18.5l10.5 10.5v5.5H32.92v6.5l6.33 6.33 4.5-4.5 4.17 4.17 4.67-4.67L45.92 65l10.67-10.67-6.5-6.5v-5.67l7.49-7.99zm5.84.33l4.67 4.67 2.33-2.33V27.5l-7 7z" />
                            </g>
                        </>
                    }
                </svg>
            </div>
        )
    }

export default Mayotte;

