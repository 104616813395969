import React, { useEffect, useRef, useState } from "react"
import Fuse from "fuse.js";

import "./styles-perimeter.scss";
import { uniqBy } from "../../../utils/Data";
import slugify from "slugify";
import { cleanAccent } from "../../../utils/StringUtils";

const SearchPerimeter: React.FC<{ options: any, placeholder?: string, onChange?: any, selectedItem?: any, customClass?: string }> = ({ options, placeholder, onChange, selectedItem, customClass }) => {

    const refSearch = useRef(null);

    const [search, setSearch] = useState<string>('');

    const [searchEngine, setSearchEngine] = useState<any>(null);

    const [searchCount, setSearchCount] = useState<number>(0);

    const [searchResults, setSearchResults] = useState<any>([]);

    const [toggleResult, setToggleResult] = useState(false);

    const [searchItems, setSearchItems] = useState([]);

    const searchOptions = {
        includeScore: true,
        includeMatches: true,
        useExtendedSearch: true,
        isCaseSensitive: false,
        threshold: 0.1,
        keys: [
            {
                name: 'label',
                weight: 6
            },
            {
                name: 'noAccent',
                weight: 5
            },
            {
                name: 'slug',
                weight: 5
            },
            {
                name: 'text',
                weight: 5
            },
            {
                name: 'value',
                weight: 1
            },
            {
                name: 'groupLabel',
                weight: 3
            }
        ]
    }

    const onChangeSearch = async (value: string) => {
        let results: any[] = [];

        setSearch(value);
        if (!!value) {
            let tmp = await searchEngine.search(value.trim().split(/,/).join(' | '));

            //If same score for many results then order by groupLabel by ASC
            tmp = Object.entries(tmp).sort((a:any,b:any) => {
                return (a[1].score === b[1].score) ? parseInt(a[1].item.postalCode) - parseInt(b[1].item.postalCode) : true;
            }).map(s => s[1]);

            tmp.forEach((t: any) => {
                if (t.matches.find((m: any) => 'groupLabel' === m.key)) {
                    results.push(t.item.data);
                } else {
                    const to = {
                        label: t.item.data.label,
                        options: []
                    };
                    t.item.data.options.forEach((o: any) => {
                        if (o.label.toLowerCase().includes(value.toLowerCase().trim()) || 
                            o.slug.includes(value.toLowerCase().trim()) ||
                            o.noAccent.includes(value.toLowerCase().trim()) ||
                            o.text.includes(slugify(value, {lower: true, replacement: ''}).replace(/-/, ''))
                        ) {
                            to.options.push(o);
                        }
                    });
                    results.push(to);
                }
            });

            results = uniqBy(results, (t: any) => t.label);

            // if(value === 'marseille'){
            // console.log(results);
            // }

        } else {
            results = searchItems;
        }

        setSearchCount(results.length);
        setSearchResults(results);
        setToggleResult(true);
    }

    const onClickOption = (object: any) => {
        setSearch('');
        setToggleResult(false);
        onChange(object);
    }

    const NoOptionsMessage = () => {
        return (
            <span className="no-data">
                <b>Pas de résultat</b>
            </span>
        );
    };

    const handeClick = (e: any) => {
        switch (e.target.className) {
            case 'select-option':
                setToggleResult(false);
                break;
            default:
                setToggleResult((!!refSearch.current && !!refSearch.current.contains(e.target)));
        }
    }

    useEffect(() => {
        let tmp: any[] = [];

        options.forEach((o: any) => {

            // o.slug = slugify(o.label, {lower: true}),
            // o.noAccent = cleanAccent(o.label).toLowerCase(),

            o.options.map((dos: any) => {
                dos.slug = slugify(dos.label, {lower: true});
                dos.text = slugify(dos.label, {lower: true, replacement: ''}).replace(/-/, '');
                dos.noAccent = cleanAccent(dos.label).toLowerCase();
                return dos;
            })

            o.options.forEach((oo: any) => {
                tmp.push({
                    label: oo.label,
                    value: oo.value,
                    groupLabel: o.label,
                    postalCode: o.postalCode,
                    slug: slugify(oo.label, {lower: true}),
                    text: slugify(oo.label, {lower: true, replacement: ''}).replace(/-/, ''),
                    noAccent: cleanAccent(oo.label).toLowerCase(),
                    data: o
                })
            })
        })

        setSearchEngine(new Fuse(tmp, searchOptions));
        setSearchItems(options);

        // if(!!selectedItem){
        //     setSearch(selectedItem.label);
        // }

    }, [options]);

    useEffect(() => {
        document.addEventListener('click', handeClick, false);

        return () => {
            document.removeEventListener('click', handeClick, false);
        }
    }, []);

    return (
        <div ref={refSearch} className={customClass}>
            <form className="form form-search" noValidate onSubmit={(e) => e.preventDefault()}>
                <div className="form_inner">
                    <div className="form-row row row-block row-autocomplete">
                        <div className={`input-wrapper filters ${!!selectedItem ? 'has-value' : ''}`}>
                            <input type="text" className={`input-filter ${!!selectedItem ? 'has-value' : ''}`} name="search" placeholder={!!selectedItem ? selectedItem.label : placeholder} value={search} onFocus={(e) => onChangeSearch(e.currentTarget.value)} onChange={(e) => onChangeSearch(e.currentTarget.value)} autoComplete="off" />
                            <div className={`search-results-perimeter search-results ${(!!toggleResult) ? "search-open" : null}`}>
                                <div className="search-results_inner">
                                    <div className="search-results-group">
                                        {0 < searchResults.length ?
                                            searchResults.map((sr: any) => {
                                                return 0 < sr.options.length ?
                                                    <div key={`${search}-${sr.label}`} className="search-results-subgroup">
                                                        <label>{sr.label}</label>
                                                        <ul>
                                                            {sr.options.map((o: any) => (
                                                                <li key={`${search}-${o.value}`} onClick={() => onClickOption(o)}>
                                                                    <span className="select-option">
                                                                        {o.label}
                                                                    </span>
                                                                </li>
                                                            ))
                                                            }
                                                        </ul>
                                                    </div>
                                                    : null
                                            })
                                            : (!!search) ? <NoOptionsMessage /> : null
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </form>
        </div>
    );
}

export default SearchPerimeter;