import React, { FunctionComponent, useEffect, useState } from 'react';
import { PERIMETRE, selected } from '../../../../../templates/PortraitTemplate';

import "./styles.scss";

export interface item {
    value: string,
    label: string
}

const Martinique: FunctionComponent<{
    data: Array<Object>,
    selected?: any,
    region?: string,
    setSelected: React.Dispatch<selected | null>,
    mode?: string
}> = ({
    data,
    selected,
    region,
    setSelected,
    mode
}) => {


        const [selectItem, setSelectItem] = useState<item | null>(null);
        // const [items, setItems] = useState<item[]>([]);


        const loadInfos = (regNum: string, regName: string) => {
            if (null !== data) {
                setSelectItem({ value: regNum, label: regName });
                let item: selected = data.find((el: selected) => el.region === regNum)!;
                setSelected(item);
            }
        }

        return (
            <div className="map-wrapper-mini">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90 90">
                    {'outre-mer' === mode &&
                        <>
                            <g className="map-svg map-martinique map-fr-outre-mer map-decorative" fill="#ffffff" stroke="#CDEEF5" strokeLinejoin="round" strokeWidth="1">
                                <path d="M72.25 62.42l3.5-3.5-11.17-11.34.83-.83 2.84-2.83-4.67-4.67h-5.33l4-4-4.83-4.83h7.66v-5H54.42l-9-8.83h-4l-8.34-8.34H19.59l-5.5 5.5v8.67l7.66 7.5v8.5l11.17 11.16h14.33l-3.5 3.5 7 7.17h-6.17l-3.33-3.33-7.83 7.66v7.5h8.16l3-2.83h22l2 2-6.16 6.16 4.33 4.34 9.16-9.17v-6.67z" />
                            </g>
                            <g className="map-svg map-martinique map-fr-outre-mer map-decorative" fill="none" stroke="#CDEEF5" strokeLinejoin="round" strokeWidth="1">
                                <path className="reg_under" d="M72.25 62.42l3.5-3.5-11.17-11.34.83-.83 2.84-2.83-4.67-4.67h-5.33l4-4-4.83-4.83h7.66v-5H54.42l-9-8.83h-4l-8.34-8.34H19.59l-5.5 5.5v8.67l7.66 7.5v8.5l11.17 11.16h14.33l-3.5 3.5 7 7.17h-6.17l-3.33-3.33-7.83 7.66v7.5h8.16l3-2.83h22l2 2-6.16 6.16 4.33 4.34 9.16-9.17v-6.67z" />
                            </g>
                            <g className={`map-svg map-martinique map-fr-outre-mer region-selection-${selected?.region}`} strokeWidth="2">
                                <path className="reg reg-02" onClick={() => loadInfos("02", "")} d="M72.25 62.42l3.5-3.5-11.17-11.34.83-.83 2.84-2.83-4.67-4.67h-5.33l4-4-4.83-4.83h7.66v-5H54.42l-9-8.83h-4l-8.34-8.34H19.59l-5.5 5.5v8.67l7.66 7.5v8.5l11.17 11.16h14.33l-3.5 3.5 7 7.17h-6.17l-3.33-3.33-7.83 7.66v7.5h8.16l3-2.83h22l2 2-6.16 6.16 4.33 4.34 9.16-9.17v-6.67z" />
                            </g>
                        </>
                    }
                </svg>
            </div>
        )
    }

export default Martinique;

