import React, { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import BarChart from '../../Charts/BarChart/BarChart'
import InseeSrc from "../../InseeSrc/InseeSrc"
import CountUp from 'react-countup';

import './styles.scss';

interface DatavizCardLargeProps {
  title: string,
  nbBig: string,
  nbBigLabel: string,
  nbQPV?: string,
  location: string,
  imgSrc: string,
  link: string,
  source: string
  tooltip: string,
  percent: string,
  percent_fr: string,
}

export default function DatavizCardBar({ title, nbBig, nbBigLabel, imgSrc, link, source, tooltip, percent, percent_fr }: DatavizCardLargeProps) {

  // useEffect(() => { 
  //   if (percent != "ND") {
  //     percent = percent.replace(",", ".");
  //   }
  //   if (percent_fr != "ND") {
  //     percent_fr = percent_fr.replace(",", ".");
  //   }
  // }, []);

  // console.log("Percent: " + percent);
  // console.log("Percent Float: " + parseFloat(percent));
  // console.log("Percent_fr: " + percent_fr);
  // console.log("Percent_fr Float: " + parseFloat(percent_fr));


  const [ref, inView] = useInView({
		threshold: 0.3,
		triggerOnce: false,
	});

  return (
    <div className={`card card-dataviz card-bar ${"ND" !== percent ? percent + "" : "nodata"}`} ref={ref}>
      <div className="card_inner">
        <h3 className="card__title"><span>{title}</span></h3>
        <div className="card__data data">
          <div className="data_inner">
            <BarChart percent={inView ? percent : "ND"} />
            <div className="card__image">
              {/* <img src={`/assets/images/svg/${percent === "ND" ? imgSrc+'-nodata' : imgSrc}.svg`} alt={` ${title}`} /> */}
            </div>
          </div>
          <div className="data_inner">
            <div className="data__values">
              <div className="data___value">
                {percent === "ND" ? (
                  <span className="no-data">Pas de données</span>
                ) : (
                    <>

                      <span className={`number`}>
                        <CountUp
                          start={0}
                          end={inView ? parseFloat(percent.replace(",", ".")) : 0}
                          duration={0.75}
                          separator=" "
                          decimals={(parseFloat(percent.replace(",", ".")) % 1 !== 0) ? 1 : 0}
                          decimal=","
                          prefix=""
                          suffix=""
                          // onEnd={() => console.log(`DatavizCardBard ${title} ${percent} ${parseFloat(percent)} Ended! 👏`)}
                          // onStart={() => console.log(`DatavizCardBard ${title} Started! 💨`)}
                        />
                      </span>
                      <span className="unit">%</span>
                    </>
                  )}
              </div>
              {!!percent_fr &&
                <span className="country">
                  <CountUp
                    start={0}
                    end={inView ? parseFloat(percent_fr.replace(",", ".")) : 0}
                    duration={0.75}
                    separator=" "
                    decimals={(parseFloat(percent_fr.replace(",", ".")) % 1 !== 0) ? 1 : 0}
                    decimal=","
                    prefix=""
                    suffix=""
                    // onEnd={() => console.log(`DatavizCardBard Country ${title} Ended! 👏`)}
                    // onStart={() => console.log(`DatavizCardBard Country ${title} Started! 💨`)}
                  />
                  % dans les QPV en France</span>
              }
            </div>
            <div className="data__source">
              {percent !== "ND" &&
                <InseeSrc link={link} source={source} tooltip={tooltip} />
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
