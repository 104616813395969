import React, { FunctionComponent, useEffect, useState } from "react"
import { navigate } from "gatsby"
import "./styles.scss";

import Select, { components } from 'react-select'
import { FaCaretDown } from 'react-icons/fa';

import { PERIMETRE, selected } from '../../../templates/PortraitTemplate';
import SearchPerimeter from "./SearchPerimeter";

import slugify from "slugify";

// Change icon for select
const DropdownIndicator = (props: any) => {
    return (
        <components.DropdownIndicator {...props}>
            <FaCaretDown />
        </components.DropdownIndicator>
    );
};

export interface item {
    label: string,
    value?: string,
    options?: any[]
}

const SelectContainer: FunctionComponent<{
    type: string,
    data: Array<Object>,
    setSelected: React.Dispatch<selected | null>,
    selected: selected
}> = ({
    type,
    data,
    selected,
    setSelected
}) => {

        const [catSelected, setCatSelected] = useState<string>(type);
        const [selectItem, setSelectItem] = useState<item | null>(null);
        const [items, setItems] = useState<item[]>([]);
        const [groupedOptions, setGroupedOptions] = useState<any[]>([]);

        const category = [
            { value: PERIMETRE.NATIONAL, label: 'Portrait France métropolitaine' },
            { value: PERIMETRE.REGION, label: 'Portrait par région' },
            { value: PERIMETRE.DEPARTEMENT, label: 'Portrait par département' },
            { value: PERIMETRE.OUTRE_MER, label: 'Portrait de l\'Outre-mer' },
            { value: PERIMETRE.QPV, label: 'Portrait par QPV' }
        ]

        function compare(a: any, b: any) {
            return  slugify(a.label.toLowerCase()) >  slugify(b.label.toLowerCase())? 1 : slugify(b.label.toLowerCase()) > slugify(a.label.toLowerCase()) ? -1 : 0;
        }



        const filterOption = ({ label, value }: any, string: string) => {

            if (!label || !value) {
                return;
            }

            // default search on options
            if (label.toLocaleLowerCase().includes(string.toLocaleLowerCase()) || value.toLocaleLowerCase().includes(string.toLocaleLowerCase())) return true;

            // Search on group options
            // check if a group as the filter string as label
            const searchItems = items.filter(group => {
                return group.label.toLocaleLowerCase().includes(string)
            });

            if (!!searchItems) {
                searchItems.forEach((item: any) => {
                    // Check if current option is in group
                    const option = item.options.find((opt: any) => opt.value.toLocaleLowerCase() === value.toLocaleLowerCase()) || item.label.toLocaleLowerCase().includes(string.toLocaleLowerCase());

                    if (!!option) {
                        return true;
                    }
                })
            }
            return false;
        };

        const buildItems = () => {
            let newItems: item[] = [];
            let newItemsGroup: any = {};
            let groups: any[] = [];
            data?.forEach((el: selected) => {
                switch (type) {
                    case PERIMETRE.QPV:
                        el?.cities?.forEach((c: any) => {
                            if ('undefined' === typeof newItemsGroup[c.city]) {
                                newItemsGroup[c.city] = {
                                    label: `${c.city} (${c.postalCode})`,
                                    postalCode: c.postalCode,
                                    options: []
                                };
                                groups.push({
                                    label: `${c.city} (${c.postalCode})`,
                                    options: []
                                });
                            }
    
                            newItemsGroup[c.city].options.push({
                                // label: `${el.name} ${!!el.dpt_code ? `${(el.dpt_code)}` : ''}`,
                                label: `${el.name}`,
                                value: el.code!
                            });
                        })

                        // if ('undefined' === typeof newItemsGroup[el.main_city]) {
                        //     newItemsGroup[el.main_city] = {
                        //         label: `${el.main_city} (${el.main_postcode})`,
                        //         options: []
                        //     };
                        //     groups.push({
                        //         label: `${el.main_city} (${el.main_postcode})`,
                        //         options: []
                        //     });
                        // }

                        // newItemsGroup[el.main_city].options.push({
                        //     // label: `${el.name} ${!!el.dpt_code ? `${(el.dpt_code)}` : ''}`,
                        //     label: `${el.name}`,
                        //     value: el.code!
                        // });
                        break;
                    case PERIMETRE.DEPARTEMENT:
                        newItems.push({
                            value: el.department!,
                            // label: `${el.name} ${!!el.region ? `${(el.region)}` : ''}`
                            label: `${el.name}`
                        })
                        break;
                    default:
                        newItems.push({
                            value: el.region!,
                            // label: `${el.name} ${!!el.region ? `${(el.region)}` : ''}`
                            label: `${el.name}`
                        })
                }
            })

            if (0 < groups.length) {
                setGroupedOptions(groups);
            }

            if (0 < Object.entries(newItemsGroup).length) {
                setItems(Object.entries(newItemsGroup).map(ig => ig[1]));
            } else {
                setItems(newItems.sort(compare));
            }
        }

        const handleSelectCategory = (e: any) => {
            navigate(`/portrait-${e.value}`);
        }

        const handleSelectItem = (e: any) => {
            setSelectItem({ value: e.value, label: e.label });
            let item: selected = data.find((el: selected) => {
                switch (type) {
                    case PERIMETRE.QPV:
                        return el.code === e.value;
                        break;
                    case PERIMETRE.DEPARTEMENT:
                        return el.department === e.value;
                        break;
                    default:
                        return el.region === e.value;
                }
            })!;
            setSelected(item);
        }

        const NoOptionsMessage = () => {
            return (
                <span className="no-data">
                    <b>Pas de résultat</b>
                </span>
            );
        };


        useEffect(() => {
            if (!!selected && selected.region && selected.name) {
                setSelectItem({ value: selected.region, label: selected.name });
            }
            if (!!selected && selected.department && selected.name) {
                setSelectItem({ value: selected.department, label: selected.name });
            }

            if (PERIMETRE.NATIONAL === type) {
                setSelected(data[0]);
            } else {
                buildItems();
            }
        }, [selected])

 
        return (
            <div className="select-container">
                <div>
                    <h4 className="select__label">Sélectionner un périmètre</h4>
                    
                    <Select
                        options={category}
                        value={category.find(obj => obj.value === catSelected)}
                        onChange={handleSelectCategory}
                        classNamePrefix="filter"
                        className={`select select-cat ${null !== catSelected ? "has-value" : ""}`}
                        components={{ DropdownIndicator }}
                        placeholder="Choisir"
                    // menuIsOpen={true}
                    />
                </div>
                {PERIMETRE.NATIONAL !== type &&
                    <div>
                        <h4 className="select__label">Préciser ce périmètre</h4>
                        {'qpv' === type ?
                            <SearchPerimeter
                                options={items}
                                selectedItem={selectItem}
                                placeholder={"Commune ou nom d'un QPV"}
                                onChange={handleSelectItem}
                                customClass={`select select-item ${null !== selectItem && Object.keys(selectItem).length > 0 ? "has-value" : ""} ${'qpv' === type ? "drop-qpv" : null}`}
                            />
                            :
                            <Select
                                // filterOption={'qpv' === type ? filterOption : null}
                                options={items}
                                value={selectItem}
                                onChange={handleSelectItem}
                                classNamePrefix="filter"
                                className={`select select-item ${null !== selectItem && Object.keys(selectItem).length > 0 ? "has-value" : ""} ${'qpv' === type ? "drop-qpv" : null}`}
                                placeholder={"Choisir"}
                                components={{ NoOptionsMessage }}
                                // menuIsOpen={true}
                            />

                        }
                    </div>
                }
            </div>
        )
    }

export default SelectContainer;