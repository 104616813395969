import React, { FunctionComponent, useEffect, useState } from 'react';
import * as L from 'leaflet';
import qpvShapes from '../../../../../maps/metropole.json';

import 'leaflet/dist/leaflet.css';
import './styles.scss';

const MapQpv: FunctionComponent<{ selected: any }> = ({ selected }) => {

    type place = {
        id: string,
        bounds: any | null,
        lat: number | null,
        long: number | null
    }

    const [previousPlace, setPreviousPlace] = useState<place | null>(null);
    const [currentPlace, setCurrentPlace] = useState<place | null>({ id: "france", bounds: null, lat: 46.7908822, long: 1 });

    const [map, setMap] = useState<L.Map | null>(null);

    const [shapes, setShapes] = useState<any | null>(qpvShapes);

    const francePos = { id: "france", zoom: 7, lat: 46.7908822, long: 1 };

    const territoryPosition = {
        france: { id: "france", zoom: 7, lat: 46.7908822, long: 1 },
        martinique: { id: "martinique", zoom: 7, lat: 14.641528, long: -61.024174 },
        guadeloupe: { id: "guadeloupe", zoom: 7, lat: 15.992850, long: -61.727531 },
        la_reunion: { id: "la_reunion", zoom: 7, lat: 44.294559, long: 0.111310 },
        guyanne: { id: "guyane", zoom: 7, lat: 4.938000, long: -52.335049 },
        mayotte: { id: "mayotte", zoom: 7, lat: -12.781350, long: 45.232029 },
        saint_martin: { id: "saint_martin", zoom: 7, lat: 18.070829, long: -63.050079 },
        polynesie_francaise: { id: "polynesie_francaise", zoom: 7, lat: -17.537260, long: -149.566025 }
    }


    const initMap = () => {
        // console.log("init map");
        let m = L.map('map', {
            center: [territoryPosition.france.lat, territoryPosition.france.long],
            zoom: territoryPosition.france.zoom,
            attributionControl: false,
            boxZoom: false,
            doubleClickZoom: false,
            zoomControl: false,
            dragging: false,
            scrollWheelZoom: false,
            keyboard: false,
        })
        L.tileLayer('https://api.maptiler.com/maps/79471961-a834-4c19-8f4b-2ce2a3bd45bd/{z}/{x}/{y}.jpg?key=dIdJmambYrg4Y7bb9KKT',
            {
                attribution: '<a href="https://www.maptiler.com/copyright/" target="_blank">&copy; MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap contributors</a>',
                crossOrigin: true,
            }).addTo(m);
        setMap(m);
    }


    const loadQpvInfos = () => {
        if (null !== map && !!selected) {
            let qpvShapeInfos = shapes.features.filter((el: any) => el.properties.CODE_QP === selected.code);

            var style = {
                "color": "#2F3F6D",
                "opacity": 1,
                "fillColor": "#E3706A",
                "fillOpacity": 0.9
            };
            L.geoJSON(qpvShapeInfos[0].geometry, {
                style: style
            }).addTo(map);

            setCurrentPlace({ id: selected.code, bounds: getBounds(qpvShapeInfos[0].geometry.coordinates), lat: null, long: null })
        }
    }

    const getBounds = (coord: any) => {
        // let corner1: any = [], corner2: any = [];
        // let c1lat = 100;
        // let c1long = 100;
        // let c2lat = 0;
        // let c2long = 0;

        // coord[0][0].forEach((c: any) => {
        //     if (c[0] < c1long) {
        //         c1long = c[0]
        //     }
        //     if (c[1] < c1lat) {
        //         c1lat = c[1]
        //     }
        //     if (c[0] > c2long) {
        //         c2long = c[0]
        //     }
        //     if (c[1] > c2lat) {
        //         c2lat = c[1]
        //     }
        // });

        // corner1 = [c1lat, c1long];
        // corner2 = [c2lat, c2long];

        var lat = coord[0][0].map((p:any) => p[0]);
        var lng = coord[0][0].map((p:any) => p[1]);

        var min_coords = [Math.min.apply(null, lng), Math.min.apply(null, lat)];
        var max_coords = [Math.max.apply(null, lng), Math.max.apply(null, lat)];

        return new L.LatLngBounds(min_coords, max_coords);
    }

    useEffect(() => {
        if (null !== selected) {
            loadQpvInfos();
        }
    }, [selected])

    useEffect(() => {

        if (null !== map && null !== previousPlace && null !== currentPlace) {
            // console.log("prev not null");
            if (currentPlace.id !== previousPlace.id) {
                if (null !== currentPlace.bounds) {
                    // console.log("fit to bound");
                    // console.log(currentPlace);
                    map.fitBounds(currentPlace.bounds);
                } else if (null !== currentPlace.lat && null !== currentPlace.long) {
                    // console.log("pan to");
                    map.panTo(L.latLng([currentPlace.lat, currentPlace.long]));
                }
            }
        } else if (null !== map && null !== currentPlace) {
            // console.log("prev null");
            if (null !== currentPlace.bounds) {
                // console.log("fit to bound 2");
                map.fitBounds(currentPlace.bounds);
            } else if (null !== currentPlace.lat && null !== currentPlace.long) {
                // console.log("pan to 2");
                map.panTo(L.latLng([currentPlace.lat, currentPlace.long]));
            }
            
            setPreviousPlace(currentPlace);
        }
    }, [currentPlace])


    useEffect(() => {
        initMap();
    }, [])

    return (
        <div className={`map-container__inner ${!!selected ? 'show' : 'hide'}`}>
            <div id="map" className="map-canvas"></div>
        </div>
    )
}

export default MapQpv;