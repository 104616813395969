import React, { FunctionComponent, useState, useEffect } from 'react';
import Layout from '../components/structure/Layout/Layout';

import Split from "../components/structure/Split/Split"
import SEO from '../components/structure/Seo/Seo';

import MapContainer from '../components/element/Maps/Map';
import Statistics from '../components/element/Statistics/Statistics';

// import capitalize from '../utils/StringUtils';
import "./styles.scss";

export interface selected {
  code?: string
  dpt_code?: string
  dpt_name?: string
  main_city?: string
  main_postcode?: string
  name?: string
  pop0_14_2016?: string
  pop_2015_qpv?: string
  popf_demo_2016?: string
  region?: string
  department?: string
  sirene?: string
  tx_job15_24_demo_2016?: string
  tx_job_demo_2016?: string
  cities: any
}

export enum PERIMETRE {
  NATIONAL = "national",
  REGION = "region",
  DEPARTEMENT = "departement",
  OUTRE_MER = "outre-mer",
  QPV = "qpv"
}

const PortraitTemplate: FunctionComponent<{ pageContext: any }> = ({ pageContext }) => {

  const { type, datas, sources, france, selectedData } = pageContext;

  const [selected, setSelected] = useState<selected | null>(null);

  const selectPerimeter = (data: any) => {
    if('national' !== type && !!window.history){
      const aLocation = document.location.pathname.split(/\//);
      const title: string|null = (!!document.querySelector('title')?.text) ? document.querySelector('title').text : '';
      window.history.pushState(data, title, `${document.location.origin}/${aLocation[1]}/${data.slug}`);
    }
    
    setSelected(data);
  }

  useEffect(() => {
    // console.log("selected:", selected);
    // console.log(sources);
    setSelected(selectedData);
  }, [selectedData])



  return (
    <Layout current='portrait-des-qpv'>
      <SEO 
      title={`Portrait ${!!type ? 'qpv' == type ? type : "QPV " + type : ""} ${!!selected ? selected.name : ""}`} 
      description={`Détail des portraits économqies et démographique ${!!type ? type : ""} ${!!selected ? selected.name : ""}`} 
      />
      <div className={`page page-portrait page-${type}`}>
        <Split
          left={
            <MapContainer
              data={datas}
              sources={sources}
              type={type}
              selected={selected}
              setSelected={selectPerimeter}
            />
          }
          right={
            <Statistics
              type={type}
              data={datas}
              dataFrance={france}
              sources={sources}
              selected={selected}
              setSelected={selectPerimeter}
            />
          }
        />
      </div>
    </Layout>
  )
}

export default PortraitTemplate;