import React, { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import CountUp from 'react-countup';

import InseeSrc from '../../InseeSrc/InseeSrc';
import { PERIMETRE } from '../../../../templates/PortraitTemplate'

import './styles.scss';

interface DatavizCardLargeProps {
    title: string,
    nbBig: string,
    nbBigLabel: string,
    nbQPV?: string,
    location: string,
    imgSrc: string,
    link: string,
    source: string
    tooltip: string,
    type: string,
}

export default function DatavizCardLarge({
    title,
    nbBig,
    nbBigLabel,
    nbQPV,
    location,
    imgSrc,
    link,
    source,
    tooltip,
    type
}: DatavizCardLargeProps) {

    useEffect(() => {
        // console.log("DataVizCardLarge", title, nbBig)
    }, [])

    if (!!nbQPV && nbQPV != "ND") {
        nbQPV = nbQPV.replace(",", ".");
    }


    const [ref, inView] = useInView({
        threshold: 0.3,
        triggerOnce: false,
    });

    const displayLocation = (location: string) => {
        switch (location.toLowerCase()) {
            case 'calvados':
            case 'cantal':
            case 'cher':
            case 'doubs':
            case 'finistère':
            case 'gard':
            case 'gers':
            case 'jura':
            case 'loir-et-cher':
            case 'loiret':
            case 'lot':
            case 'lot-et-garonne':
            case 'maine-et-loire':
            case 'morbihan':
            case 'nord':
            case 'pas-de-calais':
            case 'puy-de-dôme':
            case 'bas-rhin':
            case 'haut-rhin':
            case 'rhône':
            case 'tarn':
            case 'tarn-et-garonne':
            case 'var':
            case 'vaucluse':
            case 'territoire de belfort':
            case 'val-de-marne':
            case 'val-d\'oise':
                return `dans le ${location}`;
            case 'ain':
            case 'aisne':
            case 'allier':
            case 'ariège':
            case 'aube':
            case 'aude':
            case 'aveyron':
            case 'eure':
            case 'eure-et-Loir':
            case 'hérault':
            case 'indre':
            case 'oise':
            case 'orne':
            case 'yonne':
                return `dans l'${location}`;
            case 'creuse':
            case 'drôme':
            case 'loire':
            case 'manche':
            case 'marne':
            case 'meuse':
            case 'nièvre':
            case 'somme':
            case 'vienne':
            case 'sarthe':
                return `dans la ${location}`;
            case 'alpes-de-haute-provence':
            case 'hautes-alpes':
            case 'alpes-maritimes':
            case 'ardennes':
            case 'bouches-du-rhône':
            case 'côtes-d\'armor':
            case 'pyrénées-atlantiques':
            case 'hautes-pyrénées':
            case 'pyrénées-orientales':
            case 'yvelines':
            case 'deux-sèvres':
            case 'vosges':
            case 'hauts-de-seine':
            case 'landes':
                return `dans les ${location}`;
            case 'la réunion':
            case 'saint-martin':
            case 'mayotte':
                return `à ${location}`;
            case 'martinique':
                return `à la ${location}`;
            default:
                return `en ${location}`
        }
    }

    return (
        <div className="card card-dataviz card-large" ref={ref}>
            <div className="card_inner">
                <h2 className="card__title">{title}</h2>
                <div className="card__image">
                    <img src={`/assets/images/svg/${nbBig === "ND" ? imgSrc + '-nodata' : imgSrc}.svg`} alt={` ${title}`} />
                </div>
                <div className="card__data data">
                    <div className="data_inner">
                        <div className={`data__values ${nbBig === "ND" && "no-data-values"}`}>
                            <div className="data___value">
                                {nbBig === "ND" ? (
                                    <span className="no-data">Pas de données</span>
                                ) : (
                                        <CountUp
                                            start={0}
                                            end={inView ? parseFloat(nbBig.replace(",", ".")) : 0}
                                            duration={0.75}
                                            separator=" "
                                            decimals={(parseFloat(nbBig.replace(",", ".")) % 1 !== 0) ? 1 : 0}
                                            decimal=","
                                            prefix=""
                                            suffix=""
                                        // onEnd={() => console.log('Ended! 👏')}
                                        // onStart={() => console.log('Started! 💨')}
                                        />
                                    )}
                            </div>
                            <div className={`data___unity ${nbBig === "ND" && "no-data"}`}>
                                <div className="data____label">
                                    {"création d'entreprise" === nbBigLabel ? (
                                        <>
                                            {parseFloat(nbBig.replace(",", ".")) > 1 ? (
                                                <span>{nbBigLabel.replace("création", "créations")}</span>
                                            ) : (
                                                    <>
                                                        {parseFloat(nbBig.replace(",", ".")) == 0 ? (
                                                            <span>{nbBigLabel}</span>
                                                        ) : (
                                                                <span>{nbBigLabel.replace("création", "Création")}</span>
                                                            )}
                                                    </>
                                                )}
                                            <span> en 2018</span>
                                        </>
                                    ) : (
                                            <span>{nbBigLabel}</span>
                                        )}
                                </div>
                                <div className="data____legend">
                                    {PERIMETRE.QPV === type && 'null' !== location ? (
                                        <span className="sublabel">dans ce QPV</span>
                                    ) : ('null' !== location && !!nbQPV) ? (
                                        <span className="sublabel">dans&nbsp;
                                            <CountUp
                                                start={0}
                                                end={inView ? parseFloat(nbQPV.replace(",", ".")) : 0}
                                                duration={0.75}
                                                separator=" "
                                                decimals={(parseFloat(nbQPV.replace(",", ".")) % 1 !== 0) ? 1 : 0}
                                                decimal=","
                                                prefix=""
                                                suffix=""
                                            // onEnd={() => console.log('Ended! 👏')}
                                            // onStart={() => console.log('Started! 💨')}
                                            />
                                            {/* &nbsp;QVP en {location} */}
                                        &nbsp;QVP {displayLocation(location)}
                                        </span>
                                    ) : (
                                                <span className="sublabel">
                                                    {PERIMETRE.NATIONAL === type &&
                                                        <>
                                                            dans ce QVP
                                                        </>
                                                    }
                                                    {PERIMETRE.REGION === type &&
                                                        <>
                                                            dans cette région
                                                        </>
                                                    }
                                                    {PERIMETRE.DEPARTEMENT === type &&
                                                        <>
                                                            dans ce département
                                                        </>
                                                    }
                                                    {PERIMETRE.OUTRE_MER === type &&
                                                        <>
                                                            dans ce QVP
                                                        </>
                                                    }
                                                    {PERIMETRE.QPV === type &&
                                                        <>
                                                            dans ce QVP
                                                        </>
                                                    }
                                                </span>
                                            )}
                                </div>
                            </div>
                        </div>
                        <div className="data__source">
                            {nbBig !== "ND" &&
                                <InseeSrc link={link} source={source} tooltip={tooltip} />
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

