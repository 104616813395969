import React, { FunctionComponent, useEffect, useState } from 'react';
import { PERIMETRE, selected } from '../../../../../templates/PortraitTemplate';

import "./styles.scss";

export interface item {
    value: string,
    label: string
}

const Polynesie: FunctionComponent<{
    data: Array<Object>,
    selected?: any,
    region?: string,
    setSelected: React.Dispatch<selected | null>,
    mode?: string
}> = ({
    data,
    selected,
    region,
    setSelected,
    mode
}) => {


        const [selectItem, setSelectItem] = useState<item | null>(null);
        // const [items, setItems] = useState<item[]>([]);


        const loadInfos = (regNum: string, regName: string) => {
            if (null !== data) {
                setSelectItem({ value: regNum, label: regName });
                let item: selected = data.find((el: selected) => el.region === regNum)!;
                setSelected(item);
            }
        }

        return (
            <div className="map-wrapper-mini">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 224 125">
                    {'outre-mer' === mode &&
                        <>
                            <g className="map-svg map-reunion map-fr-outre-mer map-decorative" fill="#ffffff" stroke="#CDEEF5" strokeLinejoin="round" strokeWidth="1">

                                <g fill="none" stroke="#72DBEF" strokeLinejoin="round">
                                    <path d="M193.31 86.68v2" />
                                    <path strokeDasharray="3.9961,1.998" d="M193.31 90.68v28.97" />
                                    <path d="M193.31 120.65v2" />
                                </g>
                                <g fill="none" stroke="#72DBEF" strokeLinejoin="round">
                                    <path d="M32.07 60.3v2" />
                                    <path strokeDasharray="3.9961,1.998" d="M32.07 64.3v28.97" />
                                    <path d="M32.07 94.27v2" />
                                </g>
                                <path fill="none" stroke="#72DBEF" strokeLinejoin="round" strokeMiterlimit="10" strokeDasharray="4,2" d="M78.8 29.46h47.96" />


                                <path d="M112.64 53.38h13.71l4.4 4.39h7.29v5.5l2.8 2.8v18.68l3.3 3.3h12.79v-3l7.94 7.94v9.34l-6.47 6.47h-7.17l-9.18-9.18v-4.48l-5.85-5.85h-5.85l-5.37 5.37h-10.32l-6.15-6.15h-8.53V75.16l-3.06-3.06V60.97l5.72-5.72h8.13l1.87-1.88z" />
                                <path d="M55.41 49.65h19.15l3.05 3.05-4.07 4.07v9.14H65.6c-.11 0-10.13-10.13-10.13-10.13l-.06-6.13z" />
                                <path d="M100.18 2.12h6.19l3.5 3.5v3.74l-3.55 3.55-3.5-3.5-2.67 2.68-2.72-2.73 2.75-2.74z" />
                                <path d="M1.24 76.56L3.6 74.2v-2.36h1.98l3.75 3.74v2.32l-2.25 2.25-2.06-2.06H1.24z" />
                                <path d="M218.64 105.52h3.13v3.12h-1.62l-1.51-1.51z" />


                            </g>
                            <g className="map-svg map-reunion map-fr-outre-mer map-decorative" fill="none" stroke="#CDEEF5" strokeLinejoin="round" strokeWidth="1">

                                <path className="reg_under" d="M112.64 53.38h13.71l4.4 4.39h7.29v5.5l2.8 2.8v18.68l3.3 3.3h12.79v-3l7.94 7.94v9.34l-6.47 6.47h-7.17l-9.18-9.18v-4.48l-5.85-5.85h-5.85l-5.37 5.37h-10.32l-6.15-6.15h-8.53V75.16l-3.06-3.06V60.97l5.72-5.72h8.13l1.87-1.88z" />
                                <path className="reg_under" d="M55.41 49.65h19.15l3.05 3.05-4.07 4.07v9.14H65.6c-.11 0-10.13-10.13-10.13-10.13l-.06-6.13z" />
                                <path className="reg_under" d="M100.18 2.12h6.19l3.5 3.5v3.74l-3.55 3.55-3.5-3.5-2.67 2.68-2.72-2.73 2.75-2.74z" />
                                <path className="reg_under" d="M1.24 76.56L3.6 74.2v-2.36h1.98l3.75 3.74v2.32l-2.25 2.25-2.06-2.06H1.24z" />
                                <path className="reg_under" d="M218.64 105.52h3.13v3.12h-1.62l-1.51-1.51z" />

                            </g>
                            <g className={`map-svg map-reunion map-fr-outre-mer region-selection-${selected?.region}`} strokeWidth="2">

                                <g onClick={() => loadInfos("987", "")} className="reg reg-987">
                                    <path d="M112.64 53.38h13.71l4.4 4.39h7.29v5.5l2.8 2.8v18.68l3.3 3.3h12.79v-3l7.94 7.94v9.34l-6.47 6.47h-7.17l-9.18-9.18v-4.48l-5.85-5.85h-5.85l-5.37 5.37h-10.32l-6.15-6.15h-8.53V75.16l-3.06-3.06V60.97l5.72-5.72h8.13l1.87-1.88z" />
                                    <path d="M55.41 49.65h19.15l3.05 3.05-4.07 4.07v9.14H65.6c-.11 0-10.13-10.13-10.13-10.13l-.06-6.13z" />
                                    <path d="M100.18 2.12h6.19l3.5 3.5v3.74l-3.55 3.55-3.5-3.5-2.67 2.68-2.72-2.73 2.75-2.74z" />
                                    <path d="M1.24 76.56L3.6 74.2v-2.36h1.98l3.75 3.74v2.32l-2.25 2.25-2.06-2.06H1.24z" />
                                    <path d="M218.64 105.52h3.13v3.12h-1.62l-1.51-1.51z" />
                                </g>

                            </g>
                        </>
                    }
                </svg>
            </div>
        )
    }

export default Polynesie;