import React, { FunctionComponent, useEffect, useState } from 'react';
import { PERIMETRE, selected } from '../../../../../templates/PortraitTemplate';

import "./styles.scss";

export interface item {
    value: string,
    label: string
}

const SaintMartin: FunctionComponent<{
    data: Array<Object>,
    selected?: any,
    region?: string,
    setSelected: React.Dispatch<selected | null>,
    mode?: string
}> = ({
    data,
    selected,
    region,
    setSelected,
    mode
}) => {


        const [selectItem, setSelectItem] = useState<item | null>(null);
        // const [items, setItems] = useState<item[]>([]);


        const loadInfos = (regNum: string, regName: string) => {
            if (null !== data) {
                setSelectItem({ value: regNum, label: regName });
                let item: selected = data.find((el: selected) => el.region === regNum)!;
                setSelected(item);
            }
        }

        return (
            <div className="map-wrapper-mini">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90 90">
                    {'outre-mer' === mode &&
                        <>
                            <g className="map-svg map-reunion map-fr-outre-mer map-decorative" fill="#ffffff" stroke="#CDEEF5" strokeLinejoin="round" strokeWidth="1">
                                <path d="M64.77 55.41l-2.49-2.56 6.41-6.57-6.77-6.94 7.48-7.67L61.92 24l-6.06 6.21-2.49-2.56-4.63 4.75 2.49 2.56-5.34 5.48-3.56-3.65-12.47 12.78V66h3.92l9.98-10.23h6.06l5.7 5.84h14.25V55.4h-5v.01zm-45.24-3.29v4.38h-6.77l-7.13 7.3L1 59.06v-6.94h18.53zM89 28.75h-8.55V24H89v4.75z" />
                            </g>
                            <g className="map-svg map-reunion map-fr-outre-mer map-decorative" fill="none" stroke="#CDEEF5" strokeLinejoin="round" strokeWidth="1">
                                <path className="reg_under" d="M64.77 55.41l-2.49-2.56 6.41-6.57-6.77-6.94 7.48-7.67L61.92 24l-6.06 6.21-2.49-2.56-4.63 4.75 2.49 2.56-5.34 5.48-3.56-3.65-12.47 12.78V66h3.92l9.98-10.23h6.06l5.7 5.84h14.25V55.4h-5v.01zm-45.24-3.29v4.38h-6.77l-7.13 7.3L1 59.06v-6.94h18.53zM89 28.75h-8.55V24H89v4.75z" />
                            </g>
                            <g className={`map-svg map-reunion map-fr-outre-mer region-selection-${selected?.region}`} strokeWidth="2">
                                <path className="reg reg-978" onClick={() => loadInfos("978", "")} d="M64.77 55.41l-2.49-2.56 6.41-6.57-6.77-6.94 7.48-7.67L61.92 24l-6.06 6.21-2.49-2.56-4.63 4.75 2.49 2.56-5.34 5.48-3.56-3.65-12.47 12.78V66h3.92l9.98-10.23h6.06l5.7 5.84h14.25V55.4h-5v.01zm-45.24-3.29v4.38h-6.77l-7.13 7.3L1 59.06v-6.94h18.53zM89 28.75h-8.55V24H89v4.75z" />
                            </g>
                        </>
                    }
                </svg>
            </div>
        )
    }

export default SaintMartin;