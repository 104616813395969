import React, { FunctionComponent, useEffect, useState } from 'react';
import { PERIMETRE, selected } from '../../../../../templates/PortraitTemplate';

import "./styles.scss";

export interface item {
    value: string,
    label: string
}

const Reunion: FunctionComponent<{
    data: Array<Object>,
    selected?: any,
    region?: string,
    setSelected: React.Dispatch<selected | null>,
    mode?: string
}> = ({
    data,
    selected,
    region,
    setSelected,
    mode
}) => {


        const [selectItem, setSelectItem] = useState<item | null>(null);
        // const [items, setItems] = useState<item[]>([]);


        const loadInfos = (regNum: string, regName: string) => {
            if (null !== data) {
                setSelectItem({ value: regNum, label: regName });
                let item: selected = data.find((el: selected) => el.region === regNum)!;
                setSelected(item);
            }
        }

        return (
            <div className="map-wrapper-mini">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90 90">
                    {'outre-mer' === mode &&
                        <>
                            <g className="map-svg map-reunion map-fr-outre-mer map-decorative" fill="#ffffff" stroke="#CDEEF5" strokeLinejoin="round" strokeWidth="1">
                                <path d="M78.25 47L65.41 34v-5L52.25 15.84H34.08l-7.16 7h-7.33v4.66l-7.84 8v6.83l8.34 8.5v4.5L36.75 72h12.83l2.17 2.16h20l5-5V62l-4.5-4.5 6-6z" />
                            </g>
                            <g className="map-svg map-reunion map-fr-outre-mer map-decorative" fill="none" stroke="#CDEEF5" strokeLinejoin="round" strokeWidth="1">
                                <path className="reg_under" d="M78.25 47L65.41 34v-5L52.25 15.84H34.08l-7.16 7h-7.33v4.66l-7.84 8v6.83l8.34 8.5v4.5L36.75 72h12.83l2.17 2.16h20l5-5V62l-4.5-4.5 6-6z" />
                            </g>
                            <g className={`map-svg map-reunion map-fr-outre-mer region-selection-${selected?.region}`} strokeWidth="2">
                                <path className="reg reg-04" onClick={() => loadInfos("04", "")} d="M78.25 47L65.41 34v-5L52.25 15.84H34.08l-7.16 7h-7.33v4.66l-7.84 8v6.83l8.34 8.5v4.5L36.75 72h12.83l2.17 2.16h20l5-5V62l-4.5-4.5 6-6z" />
                            </g>
                        </>
                    }
                </svg>
            </div>
        )
    }

export default Reunion;

