import React, { FunctionComponent, useEffect, useState } from 'react';
import { PERIMETRE, selected } from '../../../../../templates/PortraitTemplate';

import "./styles.scss";

export interface item {
    value: string,
    label: string
}

const MapGuadeloupe: FunctionComponent<{
    data: Array<Object>,
    selected?: any,
    region?: string,
    setSelected: React.Dispatch<selected | null>,
    mode?: string
}> = ({
    data,
    selected,
    region,
    setSelected,
    mode
}) => {


        const [selectItem, setSelectItem] = useState<item | null>(null);
        // const [items, setItems] = useState<item[]>([]);


        const loadInfos = (regNum: string, regName: string) => {
            if (null !== data) {
                setSelectItem({ value: regNum, label: regName });
                let item: selected = data.find((el: selected) => el.region === regNum)!;
                setSelected(item);
            }
        }

        return (
            <div className="map-wrapper-mini">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90 90">

                    {'outre-mer' === mode &&
                        <>
                            <g className="map-svg map-guadeloupe map-fr-outre-mer map-decorative" fill="#ffffff" stroke="#CDEEF5" strokeLinejoin="round" strokeWidth="1">
                                <path d="M39 43.33L35.67 39h-4.83l-6.17-6.17h-4.83l-3.67-3.67H9.84l-5.5 5.5v11.17l5.83 5.83V76l6 6h8.33L36 70.5v-10L32.5 57h0v-8.83l3.33-3.33 3.17.66 4.5 4.5h16.83L66 44.33h19.67v-4.67h-7.5l-8-8h-9.83l-5.5-5.5 3-3v-6.33L49 8l-9 9v5.67L45.33 28 39 34.5v8.83zm-6.17-15.16L30 31l2.5 2.5 2.83-2.83-2.5-2.5z" />
                            </g>
                            <g className="map-svg map-guadeloupe map-fr-outre-mer map-decorative" fill="none" stroke="#CDEEF5" strokeLinejoin="round" strokeWidth="1">
                                <path className="reg_under" d="M39 43.33L35.67 39h-4.83l-6.17-6.17h-4.83l-3.67-3.67H9.84l-5.5 5.5v11.17l5.83 5.83V76l6 6h8.33L36 70.5v-10L32.5 57h0v-8.83l3.33-3.33 3.17.66 4.5 4.5h16.83L66 44.33h19.67v-4.67h-7.5l-8-8h-9.83l-5.5-5.5 3-3v-6.33L49 8l-9 9v5.67L45.33 28 39 34.5v8.83zm-6.17-15.16L30 31l2.5 2.5 2.83-2.83-2.5-2.5z" />
                            </g>
                            <g className={`map-svg map-guadeloupe map-fr-outre-mer region-selection-${selected?.region}`} strokeWidth="2">
                                <path className="reg reg-01" onClick={() => loadInfos("01", "")} d="M39 43.33L35.67 39h-4.83l-6.17-6.17h-4.83l-3.67-3.67H9.84l-5.5 5.5v11.17l5.83 5.83V76l6 6h8.33L36 70.5v-10L32.5 57h0v-8.83l3.33-3.33 3.17.66 4.5 4.5h16.83L66 44.33h19.67v-4.67h-7.5l-8-8h-9.83l-5.5-5.5 3-3v-6.33L49 8l-9 9v5.67L45.33 28 39 34.5v8.83zm-6.17-15.16L30 31l2.5 2.5 2.83-2.83-2.5-2.5z" />
                            </g>
                        </>
                    }
                </svg>
            </div>
        )
    }

export default MapGuadeloupe;