import React from 'react';
import './styles.scss';

interface InseeSrcProps {
    link: string,
    source: string,
    tooltip: string,
    align?: string
}

export default function InseeSrc({ link, tooltip, source, align }: InseeSrcProps) {
    return (
        <>
            {!!source &&
                <div className={`src insee-src tooltip`}>
                    <a href={link} className="link link-secondary" data-uk-tooltip={`container: .page ; title: ${tooltip}; pos: bottom; uk-animation-scale-up;`}
                    target="_blank" rel="noopener">
                        <span className="link_label">{source}</span>
                    </a>
                </div>
            }

        </>
    )
}
