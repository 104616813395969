import React, { FunctionComponent } from 'react';
import MapQpv from './Qpv/MapQpv';
import MapFrance from './France/France';

import CountUp from 'react-countup';

import MapMartinique from './OutreMer/Martinique/Martinique';
import MapGuadeloupe from './OutreMer/Guadeloupe/Guadeloupe';
import MapReunion from './OutreMer/Reunion/Reunion';
import MapGuyane from './OutreMer/Guyane/Guyane';
import MapMayotte from './OutreMer/Mayotte/Mayotte';
import MapSaintMartin from './OutreMer/SaintMartin/SaintMartin';
import MapPolynesie from './OutreMer/Polynesie/Polynesie';

import { PERIMETRE } from '../../../templates/PortraitTemplate';


import './styles.scss';


const MapContainer: FunctionComponent<{
    data: any,
    sources: string[],
    type: string,
    selected: any,
    setSelected: any
}> = ({
    data,
    sources,
    type,
    selected,
    setSelected
}) => {

        //console.log(selected);
        // if (!!selected.qpv_count) {
        //     let countQPV = selected.qpv_count.replace(",", ".");
        // }


        const getTitle = () => {
            switch (type) {
                case PERIMETRE.NATIONAL:
                    return `France métropolitaine`;
                case PERIMETRE.REGION:
                    if (!!selected && !!selected.qpv_count) {
                        return `${selected?.name}`;
                    } else {
                        return;
                    }
                case PERIMETRE.DEPARTEMENT:
                    if (!!selected && !!selected.qpv_count) {
                        return `${selected?.name}`;
                    } else {
                        return;
                    }
                case PERIMETRE.OUTRE_MER:
                    //return `${selected?.dpt_name}`;
                    if (!!selected && !!selected.name) {
                        //eturn `${selected.name}`;
                        return;
                    } else {
                        return;
                    }
                case PERIMETRE.QPV:
                    if (!!selected && !!selected.dpt_name) {
                        // return `${selected.dpt_name}`;
                        return `${selected.main_city} (${selected.main_postcode})`;
                    } else {
                        return;
                    }
            }
        }
        const getInfos = () => {

            switch (type) {
                case PERIMETRE.NATIONAL:
                    if (!!selected && !!selected.qpv_count) {
                        return (
                            <>
                                <CountUp
                                    start={0}
                                    end={parseFloat(selected.qpv_count.replace(",", "."))}
                                    duration={0.75}
                                    separator=" "
                                    decimals={0}
                                    decimal=","
                                    prefix=""
                                    suffix=" QPV"
                                // onEnd={() => console.log('Ended! 👏')}
                                // onStart={() => console.log('Started! 💨')}
                                />
                                {/* {selected?.qpv_count} */}
                            </>
                        )
                    }
                    else {
                        return;
                    }

                case PERIMETRE.REGION:
                    if (!!selected && !!selected.qpv_count) {
                        return (
                            <CountUp
                                start={0}
                                end={parseFloat(selected?.qpv_count.replace(",", "."))}
                                duration={0.75}
                                separator=" "
                                decimals={0}
                                decimal=","
                                prefix=""
                                suffix=" QPV"
                            // onEnd={() => console.log('Ended! 👏')}
                            // onStart={() => console.log('Started! 💨')}
                            />
                        )

                    } else {
                        return;
                    }
                case PERIMETRE.DEPARTEMENT:
                    if (!!selected && !!selected.qpv_count) {
                        return (
                            <CountUp
                                start={0}
                                end={parseFloat(selected?.qpv_count.replace(",", "."))}
                                duration={0.75}
                                separator=" "
                                decimals={0}
                                decimal=","
                                prefix=""
                                suffix=" QPV"
                            // onEnd={() => console.log('Ended! 👏')}
                            // onStart={() => console.log('Started! 💨')}
                            />
                        )

                    } else {
                        return;
                    }
                case PERIMETRE.OUTRE_MER:
                    if (!!selected?.qpv_count) {
                        return;
                        return (
                            <CountUp
                                start={0}
                                end={parseFloat(selected?.qpv_count.replace(",", "."))}
                                duration={0.75}
                                separator=" "
                                decimals={0}
                                decimal=","
                                prefix=""
                                suffix=" QPV"
                            // onEnd={() => console.log('Ended! 👏')}
                            // onStart={() => console.log('Started! 💨')}
                            />
                        )

                    } else {
                        return;
                    }
                case PERIMETRE.QPV:
                    if (!!selected && !!selected.name) {
                        return `${selected?.name}`;
                    } else {
                        return;
                    }
            }
        }
        const getMode = () => {
            switch (type) {
                case PERIMETRE.NATIONAL:
                    return `france-metropolitaine`;
                case PERIMETRE.REGION:
                    return `region`;
                case PERIMETRE.DEPARTEMENT:
                    return `departement`;
                case PERIMETRE.OUTRE_MER:
                    return `outre-mer`;
                case PERIMETRE.QPV:
                    return `qpv`;
            }
        }
        const getRegion = () => {
            switch (type) {
                case PERIMETRE.NATIONAL:
                    return ``;
                case PERIMETRE.REGION:
                    if (!!selected?.region) {
                        return `${selected?.region}`;
                    } else {
                        return ``
                    }
                case PERIMETRE.DEPARTEMENT:
                    if (!!selected?.departement) {
                        return `${selected?.departement}`;
                    } else {
                        return ``
                    }
                case PERIMETRE.OUTRE_MER:
                    return `outre-mer`;
                case PERIMETRE.QPV:
                    return ``;
            }
        }

        return (
            <div className={`map map-${type}`}>
                <div className="map-inner">

                    <div className="map-text">
                        {(!!data && PERIMETRE.QPV !== type ) ? (
                            <h1 className="map-title">
                                <span>
                                    {null !== data ?
                                        getTitle()
                                        :
                                        <>&nbsp;</>
                                    }
                                </span>
                            </h1>
                        ):(
                            <h1 className="map-title">
                            <span>
                                    &nbsp;
                            </span>
                        </h1>
                        )
                        }
                        <div className="map-infos">
                            <span>
                                {null !== data ?
                                    getInfos()
                                    :
                                    <>&nbsp;</>
                                }
                            </span>
                        </div>
                    </div>
                    <div className="map-container">
                        {!!data && PERIMETRE.QPV === type ? (
                            <>
                                <MapFrance
                                    data={data}
                                    selected={selected}
                                    region={getRegion()}
                                    setSelected={setSelected}
                                    mode={getMode()}
                                />

                                <MapQpv selected={selected} />

                            </>
                        ) : (
                                <>
                                    {!!data && PERIMETRE.OUTRE_MER !== type ? (
                                        <MapFrance
                                            data={data}
                                            selected={selected}
                                            region={getRegion()}
                                            setSelected={setSelected}
                                            mode={getMode()}
                                        />
                                    ) : (
                                            <div className="map-mosaic">
                                                <ul>
                                                    <li className="map-outre-mer mini">
                                                        <div className="map_inner">
                                                            <MapMartinique
                                                                data={data}
                                                                selected={selected}
                                                                region={'02'}
                                                                setSelected={setSelected}
                                                                mode={getMode()}
                                                            />
                                                            <div className="map-text">
                                                                <h3><span>{data[5].name}</span></h3>
                                                                <div className="infos">
                                                                    <CountUp
                                                                        start={0}
                                                                        end={parseFloat(data[5].qpv_count.replace(",", "."))}
                                                                        duration={0.75}
                                                                        separator=" "
                                                                        decimals={0}
                                                                        decimal=","
                                                                        prefix=""
                                                                        suffix=""
                                                                    // onEnd={() => console.log('Ended! 👏')}
                                                                    // onStart={() => console.log('Started! 💨')}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li className="map-outre-mer mini">
                                                        <div className="map_inner">
                                                            <MapGuadeloupe
                                                                data={data}
                                                                selected={selected}
                                                                region={'01'}
                                                                setSelected={setSelected}
                                                                mode={getMode()}
                                                            />
                                                            <div className="map-text">
                                                                <h3><span>{data[6].name}</span></h3>
                                                                <div className="infos">
                                                                    <CountUp
                                                                        start={0}
                                                                        end={parseFloat(data[6].qpv_count.replace(",", "."))}
                                                                        duration={0.75}
                                                                        separator=" "
                                                                        decimals={0}
                                                                        decimal=","
                                                                        prefix=""
                                                                        suffix=""
                                                                    // onEnd={() => console.log('Ended! 👏')}
                                                                    // onStart={() => console.log('Started! 💨')}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li className="map-outre-mer mini">
                                                        <div className="map_inner">
                                                            <MapReunion
                                                                data={data}
                                                                selected={selected}
                                                                region={'04'}
                                                                setSelected={setSelected}
                                                                mode={getMode()}
                                                            />
                                                            <div className="map-text">
                                                                <h3><span>{data[1].name}</span></h3>
                                                                <div className="infos">
                                                                    <CountUp
                                                                        start={0}
                                                                        end={parseFloat(data[1].qpv_count.replace(",", "."))}
                                                                        duration={0.75}
                                                                        separator=" "
                                                                        decimals={0}
                                                                        decimal=","
                                                                        prefix=""
                                                                        suffix=""
                                                                    // onEnd={() => console.log('Ended! 👏')}
                                                                    // onStart={() => console.log('Started! 💨')}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li className="spacer"></li>
                                                    <li className="map-outre-mer mini">
                                                        <div className="map_inner">
                                                            <MapGuyane
                                                                data={data}
                                                                selected={selected}
                                                                region={'03'}
                                                                setSelected={setSelected}
                                                                mode={getMode()}
                                                            />
                                                            <div className="map-text">
                                                                <h3><span>{data[4].name}</span></h3>
                                                                <div className="infos">
                                                                    <CountUp
                                                                        start={0}
                                                                        end={parseFloat(data[4].qpv_count)}
                                                                        duration={0.75}
                                                                        separator=" "
                                                                        decimals={0}
                                                                        decimal=","
                                                                        prefix=""
                                                                        suffix=""
                                                                    // onEnd={() => console.log('Ended! 👏')}
                                                                    // onStart={() => console.log('Started! 💨')}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li className="map-outre-mer mini">
                                                        <div className="map_inner">
                                                            <MapMayotte
                                                                data={data}
                                                                selected={selected}
                                                                region={'06'}
                                                                setSelected={setSelected}
                                                                mode={getMode()}
                                                            />
                                                            <div className="map-text">
                                                                <h3><span>{data[2].name}</span></h3>
                                                                <div className="infos">
                                                                    <CountUp
                                                                        start={0}
                                                                        end={parseFloat(data[2].qpv_count.replace(",", "."))}
                                                                        duration={0.75}
                                                                        separator=" "
                                                                        decimals={0}
                                                                        decimal=","
                                                                        prefix=""
                                                                        suffix=""
                                                                    // onEnd={() => console.log('Ended! 👏')}
                                                                    // onStart={() => console.log('Started! 💨')}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li className="map-outre-mer mini">
                                                        <div className="map_inner">
                                                            <MapSaintMartin
                                                                data={data}
                                                                selected={selected}
                                                                region={'978'}
                                                                setSelected={setSelected}
                                                                mode={getMode()}
                                                            />
                                                            <div className="map-text">
                                                                <h3><span>{data[0].name}</span></h3>
                                                                <div className="infos">
                                                                    <CountUp
                                                                        start={0}
                                                                        end={parseFloat(data[0].qpv_count.replace(",", "."))}
                                                                        duration={0.75}
                                                                        separator=" "
                                                                        decimals={0}
                                                                        decimal=","
                                                                        prefix=""
                                                                        suffix=""
                                                                    // onEnd={() => console.log('Ended! 👏')}
                                                                    // onStart={() => console.log('Started! 💨')}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li className="spacer"></li>
                                                    <li className="map-outre-mer">
                                                        <div className="map_inner">
                                                            <MapPolynesie
                                                                data={data}
                                                                selected={selected}
                                                                region={'987'}
                                                                setSelected={setSelected}
                                                                mode={getMode()}
                                                            />
                                                            <div className="map-text">
                                                                <h3><span>{data[3].name}</span></h3>
                                                                <div className="infos">
                                                                    <CountUp
                                                                        start={0}
                                                                        end={parseFloat(data[3].qpv_count.replace(",", "."))}
                                                                        duration={0.75}
                                                                        separator=" "
                                                                        decimals={0}
                                                                        decimal=","
                                                                        prefix=""
                                                                        suffix=""
                                                                    // onEnd={() => console.log('Ended! 👏')}
                                                                    // onStart={() => console.log('Started! 💨')}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        )
                                    }
                                </>
                            )}
                    </div>
                </div>
            </div>
        )
    }

export default MapContainer;