import React, { FunctionComponent, useEffect, useState } from 'react';
import { PERIMETRE, selected } from '../../../../templates/PortraitTemplate';

import "./styles.scss";

export interface item {
  value: string,
  label: string
}

const MapFrance: FunctionComponent<{
  data?: Array<Object>,
  selected?: any,
  region?: string,
  setSelected?: React.Dispatch<selected | null>,
  mode?: string
}> = ({
  data,
  selected,
  region,
  setSelected,
  mode
}) => {


    const [selectItem, setSelectItem] = useState<item | null>(null);
    // const [items, setItems] = useState<item[]>([]);


    const loadInfos = (regNum: string, regName: string) => {
      if (null !== data) {
        setSelectItem({ value: regNum, label: regName });
        let item: selected = data?.find((el: selected) => el.region === regNum)!;
          setSelected(item);

      }
    }

    const loadInfosDept = (deptNum: string, deptName: string) => {
      if (null !== data) {
        setSelectItem({ value: deptNum, label: deptName });
        let item: selected = data?.find((el: selected) => el.department === deptNum)!;
        setSelected(item);
      }
    }
    // const loadName = (regNum: string, regName: string) => {
    //   if (null !== data) {
    //     return (
    //       data.find((el: selected) => el.region === regNum)!;
    //     )
    //   }
    // }


    return (
      <div className="map-wrapper">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 580 580">
          <defs>


            <filter id="drop-shadow" x="-40" y="-40" width="250" height="200" >
              <feGaussianBlur in="SourceAlpha" stdDeviation="40" />
              <feOffset dx="0" dy="0" result="offsetblur" />
              <feFlood floodColor="rgba(67,207,234,0.7)" />
              <feComposite in2="offsetblur" operator="in" />
              <feMerge>
                <feMergeNode />
                <feMergeNode in="SourceGraphic" />
              </feMerge>
            </filter>



          </defs>

          {'outre-mer' !== mode &&
            <g className="map-svg map-fr map-fr-region map-decorative">
              <g>
                <polygon fill="#ffffff" className="map-fr-metropolitaine" points="529.89,462.66 529.27,442.83 524.31,443.27 522.37,462.02 513.53,459.86 
    500.61,466.32 488.76,481.01 493.71,486.17 490.03,489.17 490.9,494.79 497.13,498.89 492.19,508.16 499.1,509.89 495.41,517.83 
    503.84,522.35 499.52,526.69 514.18,538.98 520.42,540.28 527.31,527.54 529.03,507.29 534.01,498.25 534.43,473.67 			"/>
                <polygon fill="#ffffff" className="map-fr-metropolitaine" points="498.39,422.85 495.57,420.83 502.4,408.33 497.19,404.68 490.32,409.15 
    470.14,400.24 473.83,384.44 474.97,379.67 460.85,359.08 472.94,353.04 478.2,340.94 470.53,325.62 467.3,324.39 466.93,321.16 
    471.75,315.91 467.3,307.04 464.88,307.83 462.86,292.52 459.62,289.27 444.28,292.52 437.45,300.97 433.81,300.57 
    434.09,290.47 434.2,285.64 437.84,280.8 437.84,277.57 448.35,266.26 448.75,260.64 466.48,237.62 464.13,233.94 466.93,229.96 
    469.35,228.77 474.97,232.78 478.61,233.18 485.06,224.3 482.64,217.81 482.23,196.06 491.54,167.8 503.21,152.08 493.54,145.18 
    483.46,146.41 471.75,138.75 468.11,141.57 447.55,138.75 443.09,127.05 427.75,123.41 426.95,126.24 422.53,126.24 
    414.05,119.78 405.17,120.59 391.03,108.49 384.2,107.28 384.2,94.38 379.76,91.13 374.09,94.75 373.66,101.62 361.58,102.83 
    357.96,98.79 360.38,83.86 355.54,80.63 344.65,82.24 343.81,74.18 336.93,68.14 333.72,73.78 328.87,68.94 328.87,60.86 
    326.06,55.61 315.15,61.26 308.32,53.6 306.28,40.28 291.75,44.72 278.42,48.76 270.8,53.6 269.44,78.59 269.17,83.86 
    272.41,87.9 267.56,89.91 263.83,94.8 258.67,101.62 246.16,107.28 233.24,107.67 219.13,117.37 219.5,125.43 229.21,129.45 
    228.41,131.89 217.9,131.06 215.48,136.31 206.62,138.75 199.35,134.31 177.15,130.28 176.76,133.49 172.71,133.08 170.29,121.4 
    173.53,117.37 171.92,114.13 164.65,111.3 162.23,116.56 154.95,114.13 152.53,111.3 149.3,111.3 152.13,130.28 161.41,140.37 
    157.39,163.37 163.83,169.39 160.62,175.06 150.5,167.01 144.86,169.83 147.3,175.46 133.56,169.03 126.29,170.22 123.07,175.46 
    120.64,175.46 108.16,154.51 90.8,157.71 89.61,162.96 82.71,162.15 81.89,165.79 76.26,165.38 75.05,161.34 68.56,160.95 
    66.15,164.19 61.73,162.15 45.59,172.25 47.6,179.11 62.94,179.91 64.15,185.58 53.27,183.55 53.27,188.36 60.95,188.79 
    60.52,193.62 49.23,194.03 48.82,199.68 52.85,198.87 58.48,206.14 57.69,211.4 64.96,213 64.55,208.17 77.89,212.58 
    87.86,215.66 96.44,218.27 101.68,229.96 105.29,226.73 112.58,229.14 125.89,232.38 122.67,237.62 122.67,242.87 128.74,246.07 
    135.57,242.04 145.25,245.28 136.4,246.51 132.75,250.95 142.86,258.19 134.8,267.07 143.28,274.77 143.66,286.46 164.65,298.55 
    172.32,297.74 166.65,306.62 173.08,314.7 169.47,321.97 171.51,326.41 165.42,326.8 165.42,331.65 174.31,336.09 182.79,345.79 
    183.16,348.65 184.83,360.31 190.5,371.58 184.01,364.33 179.57,349.83 170.29,339.71 167.87,342.15 162.64,387.33 167.48,383.3 
    167.48,388.95 163.83,392.58 162.97,397.63 156.97,432.15 152.13,441.02 152.13,444.65 150.84,446.12 141.24,456.75 
    147.3,461.59 151.74,460.38 154.13,465.22 151.74,471.7 161.81,473.71 167.48,478.94 179.57,480.16 184.83,488.23 195.85,485 
    202.98,493.08 210.63,494.68 213.06,493.08 217.49,494.28 223.15,492.25 226.49,492.77 233.65,493.87 232.05,487.84 
    234.42,485.42 242.15,487.76 261.89,493.87 265.92,498.72 271.99,499.11 275.22,503.95 288.52,512.04 298.23,509.62 
    311.14,514.48 314.35,509.62 326.06,507.2 331.69,510.04 331.69,504.35 328.08,501.95 327.71,485.82 327.65,483.4 332.11,478.56 
    330.07,473.3 336.73,467.38 341,463.61 343,464.82 349.07,456.35 353.09,458.77 366.02,449.08 369.24,453.92 372.89,453.71 
    384.58,453.1 388.19,458.77 394.28,458.77 396.7,453.52 404.77,460.38 413.25,456.75 416.47,458.77 416.47,464.82 426.55,466.44 
    437.84,472.9 439.87,468.47 444.7,470.86 449.54,466.82 454.8,469.25 466.93,463.18 466.93,456.75 477.01,450.3 477.4,445.44 
    484.65,441.4 484.65,435.77 498.39,430.52"/>
              </g>
              <g>
                <polygon className="map-fr" fill="#FFFFFF" points="529.89,462.66 529.27,442.83 524.31,443.27 522.37,462.02 513.53,459.86 
    500.61,466.32 488.76,481.01 493.71,486.17 490.03,489.17 490.9,494.79 497.13,498.89 492.19,508.16 499.1,509.89 495.41,517.83 
    503.84,522.35 499.52,526.69 514.18,538.98 520.42,540.28 527.31,527.54 529.03,507.29 534.01,498.25 534.43,473.67"/>
                <polygon className="map-fr" fill="#FFFFFF" points="498.39,422.85 495.57,420.83 502.4,408.33 497.19,404.68 490.32,409.15 
    470.14,400.24 473.83,384.44 474.97,379.67 460.85,359.08 472.94,353.04 478.2,340.94 470.53,325.62 467.3,324.39 466.93,321.16 
    471.75,315.91 467.3,307.04 464.88,307.83 462.86,292.52 459.62,289.27 444.28,292.52 437.45,300.97 433.81,300.57 
    434.09,290.47 434.2,285.64 437.84,280.8 437.84,277.57 448.35,266.26 448.75,260.64 466.48,237.62 464.13,233.94 466.93,229.96 
    469.35,228.77 474.97,232.78 478.61,233.18 485.06,224.3 482.64,217.81 482.23,196.06 491.54,167.8 503.21,152.08 493.54,145.18 
    483.46,146.41 471.75,138.75 468.11,141.57 447.55,138.75 443.09,127.05 427.75,123.41 426.95,126.24 422.53,126.24 
    414.05,119.78 405.17,120.59 391.03,108.49 384.2,107.28 384.2,94.38 379.76,91.13 374.09,94.75 373.66,101.62 361.58,102.83 
    357.96,98.79 360.38,83.86 355.54,80.63 344.65,82.24 343.81,74.18 336.93,68.14 333.72,73.78 328.87,68.94 328.87,60.86 
    326.06,55.61 315.15,61.26 308.32,53.6 306.28,40.28 291.75,44.72 278.42,48.76 270.8,53.6 269.44,78.59 269.17,83.86 
    272.41,87.9 267.56,89.91 263.83,94.8 258.67,101.62 246.16,107.28 233.24,107.67 219.13,117.37 219.5,125.43 229.21,129.45 
    228.41,131.89 217.9,131.06 215.48,136.31 206.62,138.75 199.35,134.31 177.15,130.28 176.76,133.49 172.71,133.08 170.29,121.4 
    173.53,117.37 171.92,114.13 164.65,111.3 162.23,116.56 154.95,114.13 152.53,111.3 149.3,111.3 152.13,130.28 161.41,140.37 
    157.39,163.37 163.83,169.39 160.62,175.06 150.5,167.01 144.86,169.83 147.3,175.46 133.56,169.03 126.29,170.22 123.07,175.46 
    120.64,175.46 108.16,154.51 90.8,157.71 89.61,162.96 82.71,162.15 81.89,165.79 76.26,165.38 75.05,161.34 68.56,160.95 
    66.15,164.19 61.73,162.15 45.59,172.25 47.6,179.11 62.94,179.91 64.15,185.58 53.27,183.55 53.27,188.36 60.95,188.79 
    60.52,193.62 49.23,194.03 48.82,199.68 52.85,198.87 58.48,206.14 57.69,211.4 64.96,213 64.55,208.17 77.89,212.58 
    87.86,215.66 96.44,218.27 101.68,229.96 105.29,226.73 112.58,229.14 125.89,232.38 122.67,237.62 122.67,242.87 128.74,246.07 
    135.57,242.04 145.25,245.28 136.4,246.51 132.75,250.95 142.86,258.19 134.8,267.07 143.28,274.77 143.66,286.46 164.65,298.55 
    172.32,297.74 166.65,306.62 173.08,314.7 169.47,321.97 171.51,326.41 165.42,326.8 165.42,331.65 174.31,336.09 182.79,345.79 
    183.16,348.65 184.83,360.31 190.5,371.58 184.01,364.33 179.57,349.83 170.29,339.71 167.87,342.15 162.64,387.33 167.48,383.3 
    167.48,388.95 163.83,392.58 162.97,397.63 156.97,432.15 152.13,441.02 152.13,444.65 150.84,446.12 141.24,456.75 
    147.3,461.59 151.74,460.38 154.13,465.22 151.74,471.7 161.81,473.71 167.48,478.94 179.57,480.16 184.83,488.23 195.85,485 
    202.98,493.08 210.63,494.68 213.06,493.08 217.49,494.28 223.15,492.25 226.49,492.77 233.65,493.87 232.05,487.84 
    234.42,485.42 242.15,487.76 261.89,493.87 265.92,498.72 271.99,499.11 275.22,503.95 288.52,512.04 298.23,509.62 
    311.14,514.48 314.35,509.62 326.06,507.2 331.69,510.04 331.69,504.35 328.08,501.95 327.71,485.82 327.65,483.4 332.11,478.56 
    330.07,473.3 336.73,467.38 341,463.61 343,464.82 349.07,456.35 353.09,458.77 366.02,449.08 369.24,453.92 372.89,453.71 
    384.58,453.1 388.19,458.77 394.28,458.77 396.7,453.52 404.77,460.38 413.25,456.75 416.47,458.77 416.47,464.82 426.55,466.44 
    437.84,472.9 439.87,468.47 444.7,470.86 449.54,466.82 454.8,469.25 466.93,463.18 466.93,456.75 477.01,450.3 477.4,445.44 
    484.65,441.4 484.65,435.77 498.39,430.52"/>
              </g>

            </g>
          }

          {'region' === mode &&
            <>
              <g className="map-svg map-fr map-fr-region map-decorative" fill="none" stroke="#CDEEF5" strokeLinejoin="round" strokeWidth="1">
                <polygon
                  className="reg_under"
                  strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
		530.3,462.76 529.69,442.93 524.72,443.37 522.79,462.12 513.95,459.96 501.03,466.42 489.17,481.11 494.13,486.27 490.45,489.27 
		491.32,494.89 497.55,498.99 492.61,508.26 499.52,509.99 495.83,517.93 504.25,522.45 499.94,526.79 514.59,539.08 520.84,540.38 
		527.73,527.64 529.45,507.39 534.43,498.35 534.85,473.77 	"/>
                <polygon
                  className="reg_under"
                  strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
		502.82,408.43 497.61,404.78 490.73,409.25 470.55,400.34 474.25,384.54 475.39,379.77 461.27,359.18 454.84,358.82 451.97,360.81 
		447.15,357.99 445.12,363.23 449.96,368.9 449.96,374.1 444.31,372.11 432.2,377.35 428.58,380.97 424.95,384.61 421.71,394.31 
		416.46,393.91 418.09,401.57 424.13,403.16 427.37,408.83 419.31,414.47 405.19,408.83 405.19,404.78 401.53,405.18 402.73,399.15 
		399.51,396.73 396.71,401.17 397.92,405.58 395.89,406.82 391.45,404.78 389.03,408.43 397.12,422.95 392.2,427.33 386.19,432.65 
		385,441.5 373.3,453.81 385,453.2 388.61,458.87 394.7,458.87 397.12,453.62 405.19,460.48 413.66,456.85 416.89,458.87 
		416.89,464.92 426.97,466.54 438.26,473 440.29,468.57 445.12,470.96 449.96,466.92 455.22,469.35 467.35,463.28 467.35,456.85 
		477.43,450.4 477.81,445.54 485.06,441.5 485.06,435.87 498.81,430.62 498.81,422.95 495.99,420.93 	"/>
                <polygon
                  className="reg_under"
                  strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
		470.95,325.72 467.72,324.49 467.35,321.26 472.17,316.01 467.72,307.14 465.3,307.93 463.28,292.62 460.04,289.37 444.7,292.62 
		437.87,301.07 434.23,300.67 434.51,290.57 428.17,296.63 424.13,296.63 423.33,293.41 420.11,293.01 415.27,297.84 408.02,288.58 
		400.74,285.33 395.1,286.95 388.24,300.67 382.97,296.26 378.51,299.06 374.08,297.46 374.5,303.52 362.79,301.89 360.37,295.82 
		358.38,289.37 349.9,284.52 345.44,274.87 341.01,278.88 334.56,275.65 329.73,277.66 325.28,272.41 319.2,272.41 316.78,275.23 
		313.58,275.23 307.9,280.9 307.9,287.35 301.07,286.56 296.62,292.62 306.27,302.7 307.72,309.59 309.12,316.42 301.83,323.3 
		306.27,328.91 305.08,330.93 305.08,336.99 307.12,339.01 307.12,344.68 299.44,348.3 299.44,352.74 294.58,357.99 294.58,363.23 
		291.34,366.07 291.34,370.91 291.34,374.93 293.81,377.35 295,390.26 305.48,390.26 316.37,374.1 320.82,377.35 323.66,385.41 
		328.92,376.16 331.72,376.16 336.56,371.68 339.4,372.11 342.24,376.95 351.9,376.95 359.18,383.02 359.58,389.86 365.62,396.73 
		367.25,403.59 373.3,407.61 376.93,403.16 389.03,408.43 391.45,404.78 395.88,406.82 397.92,405.58 396.71,401.17 399.51,396.73 
		402.73,399.15 401.53,405.18 405.19,404.78 405.19,408.84 419.31,414.47 427.37,408.84 424.13,403.16 418.09,401.57 416.46,393.91 
		421.71,394.31 424.95,384.61 428.58,380.97 432.2,377.35 444.31,372.11 449.96,374.1 449.96,368.9 445.12,363.23 447.15,357.99 
		451.97,360.81 454.84,358.82 461.27,359.18 473.36,353.14 478.62,341.04 	"/>
                <polygon
                  className="reg_under"
                  strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
		305.08,330.93 306.27,328.91 301.83,323.3 309.12,316.42 307.72,309.59 306.27,302.7 296.62,292.62 293.81,292.52 289.54,292.42 
		287.75,290.99 285.34,292.2 278.64,290.78 277.46,294.59 267.57,292.81 266.19,294.79 258.28,293.01 255.07,286.16 251.42,286.16 
		248.19,282.92 248.19,275.65 235.69,260.73 231.25,264.37 227.6,264.37 227.6,258.29 218.32,250.24 211.48,254.66 197.75,254.66 
		191.3,259.92 182.82,258.29 191.3,277.66 190.07,288.98 196.13,295.04 187.24,295.04 179.57,295.04 172.74,297.84 167.07,306.72 
		173.5,314.8 169.89,322.07 171.93,326.51 165.84,326.9 165.84,331.75 174.73,336.19 183.21,345.89 183.58,348.75 185.25,360.4 
		190.91,371.68 184.43,364.43 179.99,349.93 170.71,339.81 168.28,342.25 163.06,387.43 167.9,383.4 167.9,389.05 164.25,392.68 
		163.39,397.73 157.39,432.25 152.55,441.12 152.55,444.75 151.26,446.21 141.66,456.85 147.72,461.69 152.16,460.48 154.55,465.32 
		152.16,471.8 162.23,473.81 167.9,479.04 179.99,480.26 185.25,488.33 196.27,485.1 196.33,475.42 201.79,473.4 207.83,462.51 
		205.81,459.69 206.23,446.34 198.55,442.69 198.55,438.68 201.79,437.48 201.79,427.77 207.04,426.58 212.06,424.46 217.51,422.15 
		220.33,424.17 230.82,419.7 234.84,421.74 242.94,416.89 246.17,410.06 243.75,407.2 245.35,404.39 251.42,403.99 248.58,395.9 
		251.19,391.83 255.07,385.83 259.48,385.41 265.55,376.16 265.55,366.46 273.64,366.46 279.66,370.91 291.34,370.91 291.34,366.07 
		294.58,363.23 294.58,357.99 299.44,352.74 299.44,348.3 307.12,344.68 307.12,339.01 305.08,336.99 	"/>
                <polygon
                  className="reg_under"
                  strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
		249.43,200.61 245.35,194.94 229.63,187.7 230.06,182.43 221.97,180.4 221.97,183.65 214.71,183.65 211.05,180.01 211.05,174.34 
		206.23,174.34 195.33,181.63 191.3,180.01 188.47,181.63 185.25,176.79 178.38,176.79 179.99,187.27 177.57,189.29 177.57,197.37 
		181.21,200.61 178.75,207.04 173.5,209.48 173.5,217.14 170.32,217.55 166.67,214.7 158.62,217.14 157.81,221.16 148.91,219.57 
		140.86,222.79 140.86,228.44 126.31,232.48 123.09,237.72 123.09,242.97 129.16,246.17 135.98,242.14 145.67,245.38 136.82,246.61 
		133.17,251.05 143.28,258.29 135.22,267.17 143.69,274.87 144.08,286.56 165.06,298.65 172.74,297.84 179.57,295.04 187.24,295.04 
		196.13,295.04 190.07,288.98 191.3,277.66 182.82,258.29 191.3,259.92 197.75,254.66 211.48,254.66 218.32,250.24 218.32,245.8 
		223.19,234.11 223.19,227.63 230.82,224.81 233.24,227.23 237.68,224.4 246.17,209.48 244.98,203.43 	"/>
                <polygon
                  className="reg_under"
                  strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
		177.57,197.37 177.57,189.29 179.99,187.27 178.38,176.79 172.74,175.56 167.47,179.61 161.04,175.16 150.91,167.11 145.28,169.93 
		147.72,175.56 133.98,169.13 126.71,170.32 123.49,175.56 121.06,175.56 108.58,154.61 91.21,157.81 90.03,163.05 83.12,162.25 
		82.31,165.89 76.68,165.48 75.47,161.43 68.98,161.05 66.57,164.29 62.15,162.25 46.01,172.35 48.01,179.21 63.35,180.01 
		64.57,185.68 53.69,183.65 53.69,188.46 61.36,188.89 60.93,193.72 49.65,194.13 49.23,199.78 53.27,198.97 58.9,206.24 
		58.11,211.5 65.37,213.1 64.97,208.27 78.31,212.68 88.28,215.76 96.86,218.37 102.1,230.06 105.71,226.83 113,229.24 
		126.31,232.48 140.86,228.44 140.86,222.79 148.91,219.57 157.81,221.16 158.62,217.14 166.67,214.7 170.32,217.55 173.5,217.14 
		173.5,209.48 178.75,207.04 181.21,200.61 	"/>
                <polygon
                  className="reg_under"
                  strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
		277.24,130.38 280.07,125.93 276.05,118.68 277.69,108.76 278.06,106.57 271.22,95.67 264.25,94.9 259.09,101.72 246.57,107.38 
		233.65,107.77 219.54,117.47 219.92,125.53 229.63,129.55 228.82,131.99 218.32,131.15 215.9,136.41 207.04,138.85 199.77,134.41 
		177.56,130.38 177.18,133.59 173.13,133.18 170.71,121.5 173.94,117.47 172.34,114.23 165.06,111.4 162.65,116.66 155.37,114.23 
		152.95,111.4 149.72,111.4 152.55,130.38 161.83,140.47 157.81,163.47 164.25,169.48 161.04,175.16 167.47,179.61 172.74,175.56 
		178.38,176.79 185.25,176.79 188.47,181.63 191.3,180.01 195.33,181.63 206.23,174.34 211.05,174.34 211.05,180.01 214.71,183.65 
		221.97,183.65 221.97,180.4 230.06,182.43 229.63,187.7 245.35,194.94 243.75,186.87 251.42,182.85 251.42,177.18 244.98,171.15 
		248.58,165.07 263.5,163.05 270.38,155.79 269.18,150.15 273.22,148.53 276.05,142.07 280.89,138.85 	"/>
                <polygon
                  className="reg_under"
                  strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
		364.02,111.4 362.4,108.59 362,102.93 358.38,98.89 360.8,83.96 355.96,80.73 345.07,82.34 344.23,74.28 337.35,68.24 
		334.14,73.88 329.29,69.04 329.29,60.96 326.48,55.71 315.57,61.36 308.74,53.7 306.7,40.38 292.16,44.82 278.84,48.86 
		271.22,53.7 269.86,78.69 269.59,83.96 272.83,88 267.97,90.01 264.25,94.9 271.22,95.67 278.06,106.57 277.69,108.76 
		276.05,118.68 280.07,125.93 277.24,130.38 280.89,138.85 276.05,142.07 287.75,141.26 291.78,141.26 306.7,145.28 314.74,148.26 
		321.64,147.82 326.64,145.41 338.31,160.47 346.86,149.03 343.92,144.78 347.08,141.77 344.73,139.35 344.73,133.72 350.59,130.57 
		357.16,133.09 358.76,129.55 356.34,121.07 	"/>
                <polygon
                  className="reg_under"
                  strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
		503.62,152.18 493.96,145.28 483.87,146.51 472.17,138.85 468.53,141.67 447.96,138.85 443.51,127.14 428.17,123.51 427.37,126.34 
		422.94,126.34 414.47,119.88 405.58,120.69 391.45,108.59 384.62,107.38 384.62,94.48 380.18,91.23 374.5,94.85 374.08,101.72 
		362,102.93 362.4,108.59 364.02,111.4 356.34,121.07 358.76,129.55 357.16,133.09 350.59,130.57 344.73,133.72 344.73,139.35 
		347.08,141.77 343.92,144.78 346.86,149.03 338.31,160.47 334.56,165.32 338.58,168.3 339.41,174.34 335.83,179.24 335.75,184.85 
		343.84,192.52 344.23,198.97 347.45,198.97 355.96,209.06 361.6,207.04 366.44,209.89 376.53,209.89 376.53,205.45 382.6,205.45 
		386.19,205.45 387.01,210.28 392.25,214.7 389.85,217.55 391.45,221.56 400.34,221.56 402.35,224.81 409.62,225.21 409.62,221.96 
		418.09,221.96 418.09,215.93 423.76,210.69 423.76,206.24 430.59,203.03 438.26,207.47 442.69,205.85 447.15,208.66 451.97,206.24 
		459.07,211.28 465.3,215.93 468.13,223.2 467.35,230.06 469.77,228.87 475.39,232.88 479.03,233.28 485.48,224.4 483.06,217.91 
		482.64,196.15 491.96,167.9"/>
                <polygon
                  className="reg_under"
                  strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
		338.58,168.3 334.56,165.32 338.31,160.47 326.64,145.41 321.64,147.82 314.74,148.26 306.7,145.28 291.78,141.26 287.75,141.26 
		276.05,142.07 273.22,148.53 269.18,150.15 270.38,155.79 274.02,161.85 274.02,171.95 277.24,171.95 280.07,180.8 284.1,180.8 
		288.17,190.53 293.39,187.7 300.29,190.6 306.7,193.35 305.08,197.76 309.12,200.61 315.99,197.37 322.43,197.37 325.28,192.95 
		323.25,189.69 335.75,184.85 335.83,179.24 339.41,174.34"/>
                <polygon
                  className="reg_under"
                  strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
		318.02,233.28 320.82,229.63 318.42,220.78 323.66,218.74 323.66,210.69 326.87,208.27 322.43,197.37 315.99,197.37 309.12,200.61 
		305.08,197.76 306.7,193.35 300.29,190.6 293.39,187.7 288.17,190.53 284.1,180.8 280.07,180.8 277.24,171.95 274.02,171.95 
		274.02,161.85 270.38,155.79 263.5,163.05 248.58,165.07 244.98,171.15 251.42,177.18 251.42,182.85 243.75,186.87 245.35,194.94 
		249.43,200.61 244.98,203.43 246.17,209.48 237.68,224.4 233.24,227.23 230.82,224.81 223.19,227.63 223.19,234.11 218.32,245.8 
		218.32,250.24 227.6,258.29 227.6,264.37 231.25,264.37 235.69,260.73 248.19,275.65 248.19,282.92 251.42,286.16 255.07,286.16 
		258.28,293.01 266.19,294.79 267.57,292.81 277.45,294.59 278.64,290.78 285.34,292.2 287.75,290.99 289.54,292.42 293.81,292.52 
		296.62,292.62 301.07,286.56 307.9,287.35 307.9,280.9 313.58,275.23 316.78,275.23 319.2,272.41 325.28,272.41 325.28,251.84 
		318.81,242.56"/>
                <polygon
                  className="reg_under"
                  strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
		468.13,223.2 465.3,215.93 459.07,211.28 451.97,206.24 447.15,208.66 442.69,205.85 438.26,207.47 430.59,203.03 423.76,206.24 
		423.76,210.69 418.09,215.93 418.09,221.96 409.62,221.96 409.62,225.21 402.35,224.81 400.34,221.56 391.45,221.56 389.85,217.55 
		392.25,214.7 387.01,210.28 386.19,205.45 382.6,205.45 376.53,205.45 376.53,209.89 366.44,209.89 361.6,207.04 355.96,209.06 
		347.45,198.97 344.23,198.97 343.84,192.52 335.75,184.85 323.25,189.69 325.28,192.95 322.43,197.37 326.87,208.27 323.66,210.69 
		323.66,218.74 318.42,220.78 320.82,229.63 318.02,233.28 318.81,242.56 325.28,251.84 325.28,272.41 329.73,277.66 334.56,275.65 
		341.01,278.88 345.44,274.87 349.9,284.52 358.38,289.37 360.37,295.82 362.79,301.89 374.5,303.52 374.08,297.46 378.51,299.06 
		382.97,296.26 388.24,300.67 395.1,286.95 400.74,285.33 408.02,288.58 415.27,297.84 420.11,293.01 423.33,293.41 424.13,296.63 
		428.17,296.63 434.51,290.57 434.62,285.74 438.26,280.9 438.26,277.66 448.77,266.36 449.17,260.73 466.9,237.72 464.55,234.04 
		467.35,230.06"/>
                <polygon
                  className="reg_under"
                  strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
		389.03,408.43 376.93,403.16 373.3,407.61 367.25,403.59 365.62,396.73 359.58,389.86 359.18,383.02 351.9,376.95 342.24,376.95 
		339.4,372.11 336.56,371.68 331.72,376.16 328.92,376.16 323.66,385.41 320.82,377.35 316.37,374.1 305.48,390.26 295,390.26 
		293.81,377.35 291.34,374.93 291.34,370.91 279.66,370.91 273.64,366.46 265.55,366.46 265.55,376.16 259.48,385.41 255.07,385.83 
		251.19,391.83 248.58,395.9 251.42,403.99 245.35,404.39 243.75,407.2 246.17,410.06 242.94,416.89 234.84,421.74 230.82,419.7 
		220.33,424.17 217.51,422.15 212.06,424.46 207.04,426.58 201.79,427.77 201.79,437.48 198.55,438.68 198.55,442.69 206.23,446.34 
		205.81,459.69 207.83,462.51 201.79,473.4 196.33,475.42 196.27,485.1 203.39,493.18 211.05,494.78 213.47,493.18 217.91,494.38 
		223.57,492.35 226.91,492.87 234.07,493.97 232.46,487.93 234.84,485.52 242.56,487.86 262.31,493.97 266.34,498.82 272.41,499.21 
		275.63,504.05 288.94,512.14 298.65,509.72 311.56,514.58 314.77,509.72 326.48,507.3 332.11,510.14 332.11,504.45 328.5,502.05 
		328.13,485.92 328.07,483.5 332.53,478.66 330.49,473.4 337.15,467.48 341.42,463.71 343.42,464.92 349.48,456.45 353.51,458.87 
		366.44,449.18 369.66,454.02 373.3,453.81 385,441.5 386.19,432.65 392.2,427.33 397.12,422.95"/>
              </g>

              {!!data &&

                <g className={`map-svg map-fr map-fr-region region-selection-${selected?.region}`} strokeWidth="2">
                  <polygon
                    className="reg reg-94"
                    onClick={() => loadInfos("94", "")}
                    data-uk-tooltip={`title: ${data.find((el: "94") => el.region === "94")?.name}; cls: tooltip-map; animation: uk-animation-scale-up;`}
                    strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
		530.3,462.76 529.69,442.93 524.72,443.37 522.79,462.12 513.95,459.96 501.03,466.42 489.17,481.11 494.13,486.27 490.45,489.27 
		491.32,494.89 497.55,498.99 492.61,508.26 499.52,509.99 495.83,517.93 504.25,522.45 499.94,526.79 514.59,539.08 520.84,540.38 
		527.73,527.64 529.45,507.39 534.43,498.35 534.85,473.77 	"/>
                  <polygon
                    className="reg reg-93"
                    onClick={() => loadInfos("93", "")} data-uk-tooltip={`title: ${data.find((el: "93") => el.region === "93")?.name}; cls: tooltip-map; animation: uk-animation-scale-up;`}
                    strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
		502.82,408.43 497.61,404.78 490.73,409.25 470.55,400.34 474.25,384.54 475.39,379.77 461.27,359.18 454.84,358.82 451.97,360.81 
		447.15,357.99 445.12,363.23 449.96,368.9 449.96,374.1 444.31,372.11 432.2,377.35 428.58,380.97 424.95,384.61 421.71,394.31 
		416.46,393.91 418.09,401.57 424.13,403.16 427.37,408.83 419.31,414.47 405.19,408.83 405.19,404.78 401.53,405.18 402.73,399.15 
		399.51,396.73 396.71,401.17 397.92,405.58 395.89,406.82 391.45,404.78 389.03,408.43 397.12,422.95 392.2,427.33 386.19,432.65 
		385,441.5 373.3,453.81 385,453.2 388.61,458.87 394.7,458.87 397.12,453.62 405.19,460.48 413.66,456.85 416.89,458.87 
		416.89,464.92 426.97,466.54 438.26,473 440.29,468.57 445.12,470.96 449.96,466.92 455.22,469.35 467.35,463.28 467.35,456.85 
		477.43,450.4 477.81,445.54 485.06,441.5 485.06,435.87 498.81,430.62 498.81,422.95 495.99,420.93 	"/>
                  <polygon
                    className="reg reg-84"
                    onClick={() => loadInfos("84", "")} data-uk-tooltip={`title: ${data.find((el: "84") => el.region === "84")?.name}; cls: tooltip-map; animation: uk-animation-scale-up;`}
                    strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
		470.95,325.72 467.72,324.49 467.35,321.26 472.17,316.01 467.72,307.14 465.3,307.93 463.28,292.62 460.04,289.37 444.7,292.62 
		437.87,301.07 434.23,300.67 434.51,290.57 428.17,296.63 424.13,296.63 423.33,293.41 420.11,293.01 415.27,297.84 408.02,288.58 
		400.74,285.33 395.1,286.95 388.24,300.67 382.97,296.26 378.51,299.06 374.08,297.46 374.5,303.52 362.79,301.89 360.37,295.82 
		358.38,289.37 349.9,284.52 345.44,274.87 341.01,278.88 334.56,275.65 329.73,277.66 325.28,272.41 319.2,272.41 316.78,275.23 
		313.58,275.23 307.9,280.9 307.9,287.35 301.07,286.56 296.62,292.62 306.27,302.7 307.72,309.59 309.12,316.42 301.83,323.3 
		306.27,328.91 305.08,330.93 305.08,336.99 307.12,339.01 307.12,344.68 299.44,348.3 299.44,352.74 294.58,357.99 294.58,363.23 
		291.34,366.07 291.34,370.91 291.34,374.93 293.81,377.35 295,390.26 305.48,390.26 316.37,374.1 320.82,377.35 323.66,385.41 
		328.92,376.16 331.72,376.16 336.56,371.68 339.4,372.11 342.24,376.95 351.9,376.95 359.18,383.02 359.58,389.86 365.62,396.73 
		367.25,403.59 373.3,407.61 376.93,403.16 389.03,408.43 391.45,404.78 395.88,406.82 397.92,405.58 396.71,401.17 399.51,396.73 
		402.73,399.15 401.53,405.18 405.19,404.78 405.19,408.84 419.31,414.47 427.37,408.84 424.13,403.16 418.09,401.57 416.46,393.91 
		421.71,394.31 424.95,384.61 428.58,380.97 432.2,377.35 444.31,372.11 449.96,374.1 449.96,368.9 445.12,363.23 447.15,357.99 
		451.97,360.81 454.84,358.82 461.27,359.18 473.36,353.14 478.62,341.04 	"/>
                  <polygon
                    className="reg reg-75"
                    onClick={() => loadInfos("75", "")} data-uk-tooltip={`title: ${data.find((el: "75") => el.region === "75")?.name}; cls: tooltip-map; animation: uk-animation-scale-up;`}
                    strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
		305.08,330.93 306.27,328.91 301.83,323.3 309.12,316.42 307.72,309.59 306.27,302.7 296.62,292.62 293.81,292.52 289.54,292.42 
		287.75,290.99 285.34,292.2 278.64,290.78 277.46,294.59 267.57,292.81 266.19,294.79 258.28,293.01 255.07,286.16 251.42,286.16 
		248.19,282.92 248.19,275.65 235.69,260.73 231.25,264.37 227.6,264.37 227.6,258.29 218.32,250.24 211.48,254.66 197.75,254.66 
		191.3,259.92 182.82,258.29 191.3,277.66 190.07,288.98 196.13,295.04 187.24,295.04 179.57,295.04 172.74,297.84 167.07,306.72 
		173.5,314.8 169.89,322.07 171.93,326.51 165.84,326.9 165.84,331.75 174.73,336.19 183.21,345.89 183.58,348.75 185.25,360.4 
		190.91,371.68 184.43,364.43 179.99,349.93 170.71,339.81 168.28,342.25 163.06,387.43 167.9,383.4 167.9,389.05 164.25,392.68 
		163.39,397.73 157.39,432.25 152.55,441.12 152.55,444.75 151.26,446.21 141.66,456.85 147.72,461.69 152.16,460.48 154.55,465.32 
		152.16,471.8 162.23,473.81 167.9,479.04 179.99,480.26 185.25,488.33 196.27,485.1 196.33,475.42 201.79,473.4 207.83,462.51 
		205.81,459.69 206.23,446.34 198.55,442.69 198.55,438.68 201.79,437.48 201.79,427.77 207.04,426.58 212.06,424.46 217.51,422.15 
		220.33,424.17 230.82,419.7 234.84,421.74 242.94,416.89 246.17,410.06 243.75,407.2 245.35,404.39 251.42,403.99 248.58,395.9 
		251.19,391.83 255.07,385.83 259.48,385.41 265.55,376.16 265.55,366.46 273.64,366.46 279.66,370.91 291.34,370.91 291.34,366.07 
		294.58,363.23 294.58,357.99 299.44,352.74 299.44,348.3 307.12,344.68 307.12,339.01 305.08,336.99 	"/>
                  <polygon
                    className="reg reg-52"
                    onClick={() => loadInfos("52", "")} data-uk-tooltip={`title: ${data.find((el: "52") => el.region === "52")?.name}; cls: tooltip-map; animation: uk-animation-scale-up;`}
                    strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
		249.43,200.61 245.35,194.94 229.63,187.7 230.06,182.43 221.97,180.4 221.97,183.65 214.71,183.65 211.05,180.01 211.05,174.34 
		206.23,174.34 195.33,181.63 191.3,180.01 188.47,181.63 185.25,176.79 178.38,176.79 179.99,187.27 177.57,189.29 177.57,197.37 
		181.21,200.61 178.75,207.04 173.5,209.48 173.5,217.14 170.32,217.55 166.67,214.7 158.62,217.14 157.81,221.16 148.91,219.57 
		140.86,222.79 140.86,228.44 126.31,232.48 123.09,237.72 123.09,242.97 129.16,246.17 135.98,242.14 145.67,245.38 136.82,246.61 
		133.17,251.05 143.28,258.29 135.22,267.17 143.69,274.87 144.08,286.56 165.06,298.65 172.74,297.84 179.57,295.04 187.24,295.04 
		196.13,295.04 190.07,288.98 191.3,277.66 182.82,258.29 191.3,259.92 197.75,254.66 211.48,254.66 218.32,250.24 218.32,245.8 
		223.19,234.11 223.19,227.63 230.82,224.81 233.24,227.23 237.68,224.4 246.17,209.48 244.98,203.43 	"/>
                  <polygon
                    className="reg reg-53"
                    onClick={() => loadInfos("53", "")} data-uk-tooltip={`title: ${data.find((el: "53") => el.region === "53")?.name}; cls: tooltip-map; animation: uk-animation-scale-up;`}
                    strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
		177.57,197.37 177.57,189.29 179.99,187.27 178.38,176.79 172.74,175.56 167.47,179.61 161.04,175.16 150.91,167.11 145.28,169.93 
		147.72,175.56 133.98,169.13 126.71,170.32 123.49,175.56 121.06,175.56 108.58,154.61 91.21,157.81 90.03,163.05 83.12,162.25 
		82.31,165.89 76.68,165.48 75.47,161.43 68.98,161.05 66.57,164.29 62.15,162.25 46.01,172.35 48.01,179.21 63.35,180.01 
		64.57,185.68 53.69,183.65 53.69,188.46 61.36,188.89 60.93,193.72 49.65,194.13 49.23,199.78 53.27,198.97 58.9,206.24 
		58.11,211.5 65.37,213.1 64.97,208.27 78.31,212.68 88.28,215.76 96.86,218.37 102.1,230.06 105.71,226.83 113,229.24 
		126.31,232.48 140.86,228.44 140.86,222.79 148.91,219.57 157.81,221.16 158.62,217.14 166.67,214.7 170.32,217.55 173.5,217.14 
		173.5,209.48 178.75,207.04 181.21,200.61 	"/>
                  <polygon
                    className="reg reg-28"
                    onClick={() => loadInfos("28", "")} data-uk-tooltip={`title: ${data.find((el: "28") => el.region === "28")?.name}; cls: tooltip-map; animation: uk-animation-scale-up;`}
                    strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
		277.24,130.38 280.07,125.93 276.05,118.68 277.69,108.76 278.06,106.57 271.22,95.67 264.25,94.9 259.09,101.72 246.57,107.38 
		233.65,107.77 219.54,117.47 219.92,125.53 229.63,129.55 228.82,131.99 218.32,131.15 215.9,136.41 207.04,138.85 199.77,134.41 
		177.56,130.38 177.18,133.59 173.13,133.18 170.71,121.5 173.94,117.47 172.34,114.23 165.06,111.4 162.65,116.66 155.37,114.23 
		152.95,111.4 149.72,111.4 152.55,130.38 161.83,140.47 157.81,163.47 164.25,169.48 161.04,175.16 167.47,179.61 172.74,175.56 
		178.38,176.79 185.25,176.79 188.47,181.63 191.3,180.01 195.33,181.63 206.23,174.34 211.05,174.34 211.05,180.01 214.71,183.65 
		221.97,183.65 221.97,180.4 230.06,182.43 229.63,187.7 245.35,194.94 243.75,186.87 251.42,182.85 251.42,177.18 244.98,171.15 
		248.58,165.07 263.5,163.05 270.38,155.79 269.18,150.15 273.22,148.53 276.05,142.07 280.89,138.85 	"/>
                  <polygon
                    className="reg reg-32"
                    onClick={() => loadInfos("32", "")} data-uk-tooltip={`title: ${data.find((el: "32") => el.region === "32")?.name}; cls: tooltip-map; animation: uk-animation-scale-up;`}
                    strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
		364.02,111.4 362.4,108.59 362,102.93 358.38,98.89 360.8,83.96 355.96,80.73 345.07,82.34 344.23,74.28 337.35,68.24 
		334.14,73.88 329.29,69.04 329.29,60.96 326.48,55.71 315.57,61.36 308.74,53.7 306.7,40.38 292.16,44.82 278.84,48.86 
		271.22,53.7 269.86,78.69 269.59,83.96 272.83,88 267.97,90.01 264.25,94.9 271.22,95.67 278.06,106.57 277.69,108.76 
		276.05,118.68 280.07,125.93 277.24,130.38 280.89,138.85 276.05,142.07 287.75,141.26 291.78,141.26 306.7,145.28 314.74,148.26 
		321.64,147.82 326.64,145.41 338.31,160.47 346.86,149.03 343.92,144.78 347.08,141.77 344.73,139.35 344.73,133.72 350.59,130.57 
		357.16,133.09 358.76,129.55 356.34,121.07 	"/>
                  <polygon
                    className="reg reg-44"
                    onClick={() => loadInfos("44", "")} data-uk-tooltip={`title: ${data.find((el: "44") => el.region === "44")?.name}; cls: tooltip-map; animation: uk-animation-scale-up;`}
                    strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
		503.62,152.18 493.96,145.28 483.87,146.51 472.17,138.85 468.53,141.67 447.96,138.85 443.51,127.14 428.17,123.51 427.37,126.34 
		422.94,126.34 414.47,119.88 405.58,120.69 391.45,108.59 384.62,107.38 384.62,94.48 380.18,91.23 374.5,94.85 374.08,101.72 
		362,102.93 362.4,108.59 364.02,111.4 356.34,121.07 358.76,129.55 357.16,133.09 350.59,130.57 344.73,133.72 344.73,139.35 
		347.08,141.77 343.92,144.78 346.86,149.03 338.31,160.47 334.56,165.32 338.58,168.3 339.41,174.34 335.83,179.24 335.75,184.85 
		343.84,192.52 344.23,198.97 347.45,198.97 355.96,209.06 361.6,207.04 366.44,209.89 376.53,209.89 376.53,205.45 382.6,205.45 
		386.19,205.45 387.01,210.28 392.25,214.7 389.85,217.55 391.45,221.56 400.34,221.56 402.35,224.81 409.62,225.21 409.62,221.96 
		418.09,221.96 418.09,215.93 423.76,210.69 423.76,206.24 430.59,203.03 438.26,207.47 442.69,205.85 447.15,208.66 451.97,206.24 
		459.07,211.28 465.3,215.93 468.13,223.2 467.35,230.06 469.77,228.87 475.39,232.88 479.03,233.28 485.48,224.4 483.06,217.91 
		482.64,196.15 491.96,167.9"/>
                  <polygon
                    className="reg reg-11"
                    onClick={() => loadInfos("11", "")} data-uk-tooltip={`title: ${data.find((el: "11") => el.region === "11")?.name}; cls: tooltip-map; animation: uk-animation-scale-up;`} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
		338.58,168.3 334.56,165.32 338.31,160.47 326.64,145.41 321.64,147.82 314.74,148.26 306.7,145.28 291.78,141.26 287.75,141.26 
		276.05,142.07 273.22,148.53 269.18,150.15 270.38,155.79 274.02,161.85 274.02,171.95 277.24,171.95 280.07,180.8 284.1,180.8 
		288.17,190.53 293.39,187.7 300.29,190.6 306.7,193.35 305.08,197.76 309.12,200.61 315.99,197.37 322.43,197.37 325.28,192.95 
		323.25,189.69 335.75,184.85 335.83,179.24 339.41,174.34"/>
                  <polygon
                    className="reg reg-24"
                    onClick={() => loadInfos("24", "")} data-uk-tooltip={`title: ${data.find((el: "24") => el.region === "24")?.name}; cls: tooltip-map; animation: uk-animation-scale-up;`}
                    strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
		318.02,233.28 320.82,229.63 318.42,220.78 323.66,218.74 323.66,210.69 326.87,208.27 322.43,197.37 315.99,197.37 309.12,200.61 
		305.08,197.76 306.7,193.35 300.29,190.6 293.39,187.7 288.17,190.53 284.1,180.8 280.07,180.8 277.24,171.95 274.02,171.95 
		274.02,161.85 270.38,155.79 263.5,163.05 248.58,165.07 244.98,171.15 251.42,177.18 251.42,182.85 243.75,186.87 245.35,194.94 
		249.43,200.61 244.98,203.43 246.17,209.48 237.68,224.4 233.24,227.23 230.82,224.81 223.19,227.63 223.19,234.11 218.32,245.8 
		218.32,250.24 227.6,258.29 227.6,264.37 231.25,264.37 235.69,260.73 248.19,275.65 248.19,282.92 251.42,286.16 255.07,286.16 
		258.28,293.01 266.19,294.79 267.57,292.81 277.45,294.59 278.64,290.78 285.34,292.2 287.75,290.99 289.54,292.42 293.81,292.52 
		296.62,292.62 301.07,286.56 307.9,287.35 307.9,280.9 313.58,275.23 316.78,275.23 319.2,272.41 325.28,272.41 325.28,251.84 
		318.81,242.56"/>
                  <polygon
                    className="reg reg-27"
                    onClick={() => loadInfos("27", "")} data-uk-tooltip={`title: ${data.find((el: "27") => el.region === "27")?.name}; cls: tooltip-map; animation: uk-animation-scale-up;`} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
		468.13,223.2 465.3,215.93 459.07,211.28 451.97,206.24 447.15,208.66 442.69,205.85 438.26,207.47 430.59,203.03 423.76,206.24 
		423.76,210.69 418.09,215.93 418.09,221.96 409.62,221.96 409.62,225.21 402.35,224.81 400.34,221.56 391.45,221.56 389.85,217.55 
		392.25,214.7 387.01,210.28 386.19,205.45 382.6,205.45 376.53,205.45 376.53,209.89 366.44,209.89 361.6,207.04 355.96,209.06 
		347.45,198.97 344.23,198.97 343.84,192.52 335.75,184.85 323.25,189.69 325.28,192.95 322.43,197.37 326.87,208.27 323.66,210.69 
		323.66,218.74 318.42,220.78 320.82,229.63 318.02,233.28 318.81,242.56 325.28,251.84 325.28,272.41 329.73,277.66 334.56,275.65 
		341.01,278.88 345.44,274.87 349.9,284.52 358.38,289.37 360.37,295.82 362.79,301.89 374.5,303.52 374.08,297.46 378.51,299.06 
		382.97,296.26 388.24,300.67 395.1,286.95 400.74,285.33 408.02,288.58 415.27,297.84 420.11,293.01 423.33,293.41 424.13,296.63 
		428.17,296.63 434.51,290.57 434.62,285.74 438.26,280.9 438.26,277.66 448.77,266.36 449.17,260.73 466.9,237.72 464.55,234.04 
		467.35,230.06"/>
                  <polygon
                    className="reg reg-76"
                    onClick={() => loadInfos("76", "")} data-uk-tooltip={`title: ${data.find((el: "76") => el.region === "76")?.name}; cls: tooltip-map; animation: uk-animation-scale-up;`} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
		389.03,408.43 376.93,403.16 373.3,407.61 367.25,403.59 365.62,396.73 359.58,389.86 359.18,383.02 351.9,376.95 342.24,376.95 
		339.4,372.11 336.56,371.68 331.72,376.16 328.92,376.16 323.66,385.41 320.82,377.35 316.37,374.1 305.48,390.26 295,390.26 
		293.81,377.35 291.34,374.93 291.34,370.91 279.66,370.91 273.64,366.46 265.55,366.46 265.55,376.16 259.48,385.41 255.07,385.83 
		251.19,391.83 248.58,395.9 251.42,403.99 245.35,404.39 243.75,407.2 246.17,410.06 242.94,416.89 234.84,421.74 230.82,419.7 
		220.33,424.17 217.51,422.15 212.06,424.46 207.04,426.58 201.79,427.77 201.79,437.48 198.55,438.68 198.55,442.69 206.23,446.34 
		205.81,459.69 207.83,462.51 201.79,473.4 196.33,475.42 196.27,485.1 203.39,493.18 211.05,494.78 213.47,493.18 217.91,494.38 
		223.57,492.35 226.91,492.87 234.07,493.97 232.46,487.93 234.84,485.52 242.56,487.86 262.31,493.97 266.34,498.82 272.41,499.21 
		275.63,504.05 288.94,512.14 298.65,509.72 311.56,514.58 314.77,509.72 326.48,507.3 332.11,510.14 332.11,504.45 328.5,502.05 
		328.13,485.92 328.07,483.5 332.53,478.66 330.49,473.4 337.15,467.48 341.42,463.71 343.42,464.92 349.48,456.45 353.51,458.87 
		366.44,449.18 369.66,454.02 373.3,453.81 385,441.5 386.19,432.65 392.2,427.33 397.12,422.95"/>
                </g>
              }


            </>
          }

          {'departement' === mode &&
            <>
              <g className="map-svg map-fr map-fr-departement map-decorative" fill="none" stroke="#CDEEF5" strokeLinejoin="round" strokeWidth="1">

                <path className="reg_under" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M314.72 148.09l-8.03-2.98 2.04 7.43 2.75 8.74-2.9 8.28-2.32 7.06 1.63 8.07-7.62 5.74 6.42 2.75-1.62 4.41 4.04 2.85 6.87-3.23h6.44l2.84-4.43-2.03-3.25 12.5-4.84.09-5.62 3.58-4.9-.83-6.04-4.03-2.98 3.76-4.84-11.68-15.07-4.99 2.41z" />
                <path className="reg_under" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M220.32 424l10.48-4.47 4.03 2.04 8.1-4.85 3.23-6.83-2.42-2.86 1.6-2.81 6.06-.4-2.83-8.09 2.61-4.07-8.76-5.78-17.64.39-6.8-4.06-6.72 12.92-5.67 17.14 10.72 3.04-4.27 8.98 5.46-2.31z" />
                <path className="reg_under" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M178.74 398.36l7.88-.61 11.92 10.11-1.62 4.41h8.67l5.67-17.14 6.72-12.92-5.71-3.44v-15.71l-13.73-4.84-7.25-8.9-7.72-.74 1.67 11.66 5.66 11.27-6.48-7.25-4.44-14.5-9.28-10.11-2.43 2.43-5.22 45.18 4.84-4.03v5.65l-3.65 3.63-.86 5.05 11.15-2.59z" />
                <path className="reg_under" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M169.28 450.22l6.67-4.45 22.59 1.44v-8.7l3.23-1.2v-9.71l5.26-1.19 5.01-2.12 4.27-8.98-10.72-3.04h-8.67l1.62-4.41-11.92-10.11-7.88.61-4.21-3.39-11.15 2.59-6.01 34.52-4.83 8.87v3.63l-1.3 1.46 14.83.03z" />
                <path className="reg_under" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M196.32 475.25l5.45-2.02 6.05-10.89-2.02-2.82.42-13.35-7.68-3.65v4.69l-22.59-1.44-6.67 4.45-3.21-4.15-14.83-.03-9.59 10.64 6.06 4.84 4.44-1.21 2.39 4.83-2.39 6.49 10.07 2.01 5.67 5.23 12.09 1.22 5.26 8.07 11.01-3.23z" />
                <path className="reg_under" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M242.42 385.88l8.76 5.78 3.88-6 4.41-.41 6.07-9.25V363.06l-4.86-1.59-1.6-14.95-6.86-5.64-1.6-4.86h-7.69l-.81-5.25-7.29-.42-3.59 2.86-2.04 8.05-7.25 4.84-1.2 7.28-8.09 5.27-.39 4.41v15.71l5.71 3.44 6.8 4.06z" />
                <path className="reg_under" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M334.54 275.48l-4.82 2.02-4.46-5.26h-6.07l-2.42 2.82h-3.21l-5.67 5.68v6.44l-6.83-.78-4.45 6.05 9.65 10.08 1.44 6.89 12.72-8.09 5.25 7.66 13.33.79 10.88 7.28 4.04-3.66.81-11.68 5.63-6.06-2-6.46-8.48-4.84-4.45-9.66-4.44 4.01z" />
                <path className="reg_under" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M325.67 308.99l-5.25-7.66-12.72 8.09 1.41 6.83-7.29 6.88 4.44 5.62-1.19 2.02v6.05l2.04 2.02v5.67l21.79 6.44 5.64-5.67 23.39 1.62 3.24-4.05-10.9-16.91 2.04-6.06-2.43-2.82-10.88-7.28z" />
                <path className="reg_under" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M334.54 355.8l-5.64-4.85-21.79-6.44-7.68 3.63v4.44l-4.86 5.24v5.24l-3.24 2.84v8.86l2.47 2.42 1.19 12.91h10.48l10.88-16.16 4.46 3.25 2.83 8.07 5.26-9.25h2.81l4.84-4.49 1.22-9.27z" />
                <path className="reg_under" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M374.06 346.9h-16.13l-23.39-1.62-5.64 5.67 5.64 4.85 3.23 6.44-1.22 9.27 2.84.43 2.83 4.84h9.67l7.28 6.07 3.61-9.31h6.07l7.25-8.06v-6.47h3.63v-4.85l-4.41-2.01z" />
                <path className="reg_under" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M220.75 310.22l.39-4.88-8.48-12.5 3.22-10.87-3.22-13.34 2.42-5.66-3.61-8.47h-13.73l-6.45 5.25-8.48-1.63 8.48 19.38-1.23 11.31 6.06 6.06h-8.9l1.64 7.25 14.52 5.64 6.86 7.66 3.65-6.43z" />
                <path className="reg_under" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M246.96 299.28h4.44l6.86-6.44-3.2-6.85h-3.66l-3.22-3.24v-7.27l-12.51-14.91-4.43 3.63h-3.65v-6.08l-9.28-8.05-6.84 4.43 3.61 8.47-2.42 5.66 3.22 13.34-3.22 10.87 8.48 12.5-.39 4.88 7.67 1.99 4.03-4.03 2.8 2.04 7.89-5.27v-1.83z" />
                <path className="reg_under" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M198.54 358.22l13.73 4.84.39-4.41-7.07-8.11-5.43-1.22 3.03-8.65-5.89-14.73 9.73-.22 3.21-10.3-6.86-7.66-14.52-5.64-1.64-7.25h-7.66l-6.83 2.81-5.67 8.88 6.43 8.08-3.61 7.26 2.03 4.44-6.09.4v4.84l8.9 4.44 8.48 9.7.37 2.86 7.72.74z" />
                <path className="reg_under" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M221.95 346.1l7.25-4.84 2.04-8.05 3.59-2.86 8.1-10.47 5.64-2.42v-4.44h-5.43v-8.07l-7.89 5.27-2.8-2.04-4.03 4.03-7.67-1.99-6.86-1.23-3.65 6.43-3.21 10.3-9.73.22 5.89 14.73-3.03 8.65 5.43 1.22 7.07 8.11 8.09-5.27z" />
                <path className="reg_under" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M330 395.96l-6.36-10.71-2.83-8.07-4.46-3.25-10.88 16.16h-10.48l-5.25 4.44-6.84 4.85 2 3.61-2.83 2.85 4.85 2.83.82 7.26 6.06-2.03 12.46 6.04 5.67 15.35 7.26-.39 3.35 6.86 6.27-5.35.57-3.93h6.35v-2.35l5.06-3.3v-5.97l-4.64-2.83 3.45-4.33-8.8-4.62z" />
                <path className="reg_under" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M311.93 435.29l-5.67-15.35-12.46-6.04-6.06 2.03-2.84 2.42-5.25.4-8.44 9.28.37 4.45 4.83 7.26-.37 4.43h2l9.28 12.97 5.76 1.17 4.13-5.28 12.1 2.24 3.44-12.59 3.91 2.3 5.88-3.22-3.35-6.86z" />
                <path className="reg_under" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M247.79 478.49l2.38-9.31 10.13 2.83 1.18-7.26 13.13-.4 6.48-8.49 6.23 1.28-9.28-12.97h-2l.37-4.43-4.83-7.26-.37-4.45-10.13 2.03-2 6.05-5.66-2.84-6.46 1.23 10.09 10.48-8.48 4.03-.39 10.51-5.25-2.84-5.26 3.63-10.47 7.29 4.84 9.26-4.84 6.85-.3 8.98 7.16 1.11-1.61-6.04 2.38-2.41 7.72 2.34-.43-5.16z" />
                <path className="reg_under" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M232.04 476.86l-4.84-9.26 10.47-7.29-16.92-4.03-4.87 1.59.43-5.21-6.07-7.68-4.02 1.19-.42 13.35 2.02 2.82-6.05 10.89-5.45 2.02-.07 9.68 7.13 8.08 7.66 1.6 2.42-1.6 4.44 1.2 5.66-2.03 3.34.51.3-8.98z" />
                <path className="reg_under" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M234.83 421.57l-4.03-2.04-10.48 4.47-2.82-2.02-5.46 2.31-5.01 2.12-5.26 1.19v9.71l-3.23 1.2v4.01l7.68 3.65 4.02-1.19 6.07 7.68-.43 5.21 4.87-1.59 16.92 4.03 5.26-3.63 5.25 2.84.39-10.51 8.48-4.03-10.09-10.48-3.22-5.67z" />
                <path className="reg_under" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M284.9 418.35l2.84-2.42-.82-7.26-4.85-2.83h-6.85l-4.01 4.05-4.07-2.45-5.22 3.66-10.52-7.28-6.06.4-1.6 2.81 2.42 2.86-3.23 6.83-8.1 4.85 8.91 7.26 3.22 5.67 6.46-1.23 5.66 2.84 2-6.05 10.13-2.03 8.44-9.28z" />
                <path className="reg_under" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M293.46 495.73l-2.22-5.35-6.47.99-3-6.32 3.85-.52-.52-4.34 1.82-1.22-2.83-8.35-9.48-6.27-13.13.4-1.18 7.26-10.13-2.83-2.38 9.31-5.67 4.04.43 5.16 19.75 6.11 4.03 4.85 6.07.39 3.22 4.84 10.18-6.13z" />
                <path className="reg_under" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M315.16 486.94l-13.69-.39v7.25l-8.01 1.93-7.66 2.02-10.18 6.13 13.31 8.09 9.71-2.42 12.9 4.86 3.21-4.86 11.71-2.42 5.64 2.84v-5.69l-3.61-2.4-.38-16.13-6.88-3.22z" />
                <path className="reg_under" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M317.17 464.75l-8.06-4.85.2-4.63-12.1-2.24-4.13 5.28-5.76-1.17-6.23-1.28-6.48 8.49 9.48 6.27 2.83 8.35-1.82 1.22.52 4.34-3.85.52 3 6.32 6.47-.99 2.22 5.35 8.01-1.93v-7.25l13.69.39 6.07-4.41 6.88 3.22-.05-2.42 4.45-4.84-2.03-5.26 6.66-5.92-16.72-7.79z" />
                <path className="reg_under" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M354.73 425.2l-7.29 4.42-6.65-2.79-5.06 3.3v2.35h-6.35l-.57 3.93-6.27 5.35-5.88 3.22-3.91-2.3-3.44 12.59-.2 4.63 8.06 4.85 3.25-5.23 16.72 7.79 4.27-3.77 2 1.21 6.06-8.47 4.03 2.42 12.93-9.69 3.22-11.29z" />
                <path className="reg_under" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M386.18 432.48l6-5.32 4.93-4.38-8.09-14.52-12.11-5.27-3.62 4.45-6.06-4.02-1.62-6.86-5.67 4.84 2.84 10.87-6.45 4.06-6.86-3.23-9.87.6-3.45 4.33 4.64 2.83v5.97l6.65 2.79 7.29-4.42 14.92 12.52-3.22 11.29 3.22 4.84 3.64-.21 11.7-12.31z" />
                <path className="reg_under" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M359.17 382.85l-7.28-6.07h-9.67l-2.83-4.84-2.84-.43-4.84 4.49h-2.81l-5.26 9.25 6.36 10.71.8 13.12 8.8 4.62 9.87-.6 6.86 3.23 6.45-4.06-2.84-10.87 5.67-4.84-6.04-6.87z" />
                <path className="reg_under" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M461.26 392.51l-10.9-1.59-9.7 4.05-7.24 8.45 3.2 6.03-9.26-.78-8.07 5.63 5.65 4.05 1.19 10.48 6.47 7.28 6.86-2.01 3.22 2.39 6.08-5.64 4.84 1.63 4.43-4.05h6.44l3.23-4.84 6.88-.81-1.23-4.84-3.6-.41-5.28-11.29 6.07-6.07 3.69-15.8-4.91-2.35z" />
                <path className="reg_under" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M451.96 360.64l-4.83-2.82-2.03 5.24 4.84 5.67v5.2l-5.64-1.99-12.12 5.24-3.61 3.62-3.63 3.64-3.24 9.7-5.26-.4 1.64 7.66 6.04 1.59 3.24 5.68 9.26.78-3.2-6.03 7.24-8.45 9.7-4.05 10.9 1.59 8.06-10.49 4.91 2.35 1.15-4.77-14.12-20.59-6.43-.36z" />
                <path className="reg_under" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M485.05 435.7l13.75-5.25v-7.67l-2.82-2.02 6.83-12.5-5.22-3.65-6.87 4.47-20.18-8.91-6.07 6.07 5.28 11.29 3.6.41 1.23 4.84-6.88.81-3.23 4.84 4.46 7.68 8.87 9.26 7.25-4.04z" />
                <path className="reg_under" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M464.47 428.43h-6.44l-4.43 4.05-4.84-1.63-6.08 5.64-3.22-2.39-6.86 2.01-4.44 10.48 2.83 5.65-3.63 4.44 4.44 3.63-4.84 6.06 11.28 6.46 2.03-4.43 4.83 2.39 4.84-4.04 5.26 2.43 12.13-6.07v-6.43l10.08-6.46.39-4.85-8.87-9.26z" />
                <path className="reg_under" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M424.94 418.35l-5.65-4.05-14.12-5.63v-4.06l-3.65.4 1.2-6.03-3.22-2.42-2.81 4.44 1.22 4.41-2.04 1.24-4.43-2.04-2.42 3.65 8.09 14.52-4.93 4.38 14.18 7.74 9.71.39 9.25 4.45 7.28-3.63-6.47-7.28z" />
                <path className="reg_under" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M427.36 456.68l3.63-4.44-2.83-5.65 4.44-10.48-7.28 3.63-9.25-4.45-9.71-.39-14.18-7.74-6 5.32-1.19 8.85-11.7 12.31 11.7-.61 3.61 5.67h6.09l2.42-5.26 8.06 6.87 8.48-3.63 3.22 2.02v6.05l10.09 1.62 4.84-6.06z" />
                <path className="reg_under" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M387 394.97l4.44-9.72 4.04-9.67-4.45-14.94.8-8.87-3.23-4.04-8.87 6.43v4.85h-3.63v6.47l-7.25 8.06h-6.07l-3.61 9.31.4 6.84 6.04 6.87 1.62 6.86 6.06 4.02 3.62-4.45 12.11 5.27 2.42-3.65z" />
                <path className="reg_under" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M420.1 377.18l-2.43-15.71-10.51 1.59-1.99-9.29-8.89-5.22-4.45 3.22-.8 8.87 4.45 14.94-4.04 9.67-4.44 9.72 4.44 9.64 4.43 2.04 2.04-1.24-1.22-4.41 2.81-4.44 3.22 2.42-1.2 6.03 3.65-.4v4.06l14.12 5.63 8.07-5.63-3.24-5.68-6.04-1.59-1.64-7.66 5.26.4 3.24-9.7 3.63-3.64z" />
                <path className="reg_under" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M395.09 335.63l-1.24 6.45-3.2.41-2.05 5.24 3.23 4.04 4.45-3.22 8.89 5.22 1.99 9.29 10.51-1.59 2.43 15.71 8.47 3.62 3.61-3.62 12.12-5.24 5.64 1.99v-5.2l-4.84-5.67 2.03-5.24-5.25-1.63 1.2-12.51-8.48-4.03-2 3.2-9.28-2.79.43-6.85L412 319.08l-10.48 5.64 1.66 4.03z" />
                <path className="reg_under" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M385.79 338.44l.39-2.42h-5.65l-6.04-5.25 1.61-6.05-5.23-15.33 4.45-2.83-.83-3.21-11.71-1.63-2.42-6.06-5.63 6.06-.81 11.68-4.04 3.66 2.43 2.82-2.04 6.06 10.9 16.91-3.24 4.05h16.13l1.26 5.25 4.41 2.01 8.87-6.43 2.05-5.24z" />
                <path className="reg_under" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M378.5 298.89l-4.44-1.6.43 6.06.83 3.21-4.45 2.83 5.23 15.33-1.61 6.05 6.04 5.25h5.65l-.39 2.42 4.86 4.05 3.2-.41 1.24-6.45 8.09-6.88-1.66-4.03-6.04-1.23-2.82-6.43-4.88.4-1.19-6.86 2.83-5.26-1.19-4.84-5.27-4.41z" />
                <path className="reg_under" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M424.12 296.46l-.8-3.22-3.22-.4-4.85 4.84-7.24-9.27-7.28-3.25-5.64 1.62-6.86 13.72 1.19 4.84-2.83 5.26 1.19 6.86 4.88-.4 2.82 6.43 6.04 1.23 10.48-5.64 11.75 14.13 1.57-17.79 2.04-8.45 6.85-6.47.28-10.1-6.33 6.06z" />
                <path className="reg_under" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M425.32 315.42l21.41 12.92 7.65-14.52 6.07 9.31 6.88-2.04 4.82-5.25-4.45-8.87-2.42.79-2.02-15.31-3.24-3.25-15.33 3.25-6.84 8.45-3.64-.4-6.85 6.47z" />
                <path className="reg_under" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M454.38 313.82l-7.65 14.52-21.41-12.92-1.57 17.79-.43 6.85 9.28 2.79 2-3.2 8.48 4.03-1.2 12.51 5.25 1.63 4.83 2.82 2.87-1.99 6.43.36 12.09-6.03 5.25-12.1-7.66-15.33-3.24-1.23-.37-3.23-6.88 2.04z" />
                <path className="reg_under" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M281.25 323.49l-7.24-2.82-6.05-15.33-3.24-1.6 3.63-4.85-2.18-4.26-7.91-1.79-6.86 6.44h-4.44l-3.82 3.84v9.9h5.43v4.44l-5.64 2.42-8.1 10.47 7.29.42.81 5.25h7.69l1.6 4.86 6.86 5.64 24.2-15.75z" />
                <path className="reg_under" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M301.82 323.13l7.29-6.88-1.41-6.83-1.44-6.89-9.65-10.08-2.81-.1-4.27-.1-1.79-1.43-2.42 1.21-6.69-1.42-1.19 3.82-9.88-1.79-1.39 1.99 2.18 4.26-3.63 4.85 3.24 1.6 6.05 15.33 7.24 2.82 2.03 7.28 6.86-2.84 9.29 3.65 5.64-.81 1.19-2.02z" />
                <path className="reg_under" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M279.65 370.74h11.68v-4.84l3.24-2.84v-5.24l4.86-5.24v-4.44l7.68-3.63v-5.67l-2.04-2.02v-6.05l-5.64.81-9.29-3.65-6.86 2.84-24.2 15.75 1.6 14.95 4.86 1.59v3.23h8.09z" />
                <path className="reg_under" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M158.19 258.12l6.86 4.04 4.41-7.66 2.04-14.51 10.5-.83-2.02-12.91-4.84-2.02-1.65-7.26-3.18.41-3.65-2.85-8.05 2.44-.81 4.02-8.9-1.59-8.05 3.23v5.64l-14.55 4.04-3.22 5.24v5.26l6.07 3.19 6.82-4.02 9.69 3.24-8.85 1.22-3.65 4.44 10.11 7.24 14.1 5.26z" />
                <path className="reg_under" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M197.74 254.5h13.73l6.84-4.43v-4.44l4.86-11.69v-6.47l-21.78-10.5-7.71 2.43-20.19-2.43 1.65 7.26 4.84 2.02 2.02 12.91-10.5.83-2.04 14.51 13.35 3.62 8.48 1.63z" />
                <path className="reg_under" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M201.39 216.97l2.41-10.1 5.65-2.8-.81-6.48 4.02-4.04-1.19-4.83 3.22-5.24-3.65-3.63v-5.68h-4.82l-10.91 7.3-4.02-1.62-2.83 1.62-3.22-4.85h-6.87l1.61 10.49-2.43 2.02v8.08l3.64 3.23-2.45 6.43-5.25 2.45v7.65l20.19 2.43z" />
                <path className="reg_under" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M233.23 227.07l4.44-2.84 8.49-14.91-1.19-6.06 4.44-2.82-4.07-5.67-15.72-7.23.42-5.27-8.09-2.04v3.25h-7.26l-3.22 5.24 1.19 4.83-4.02 4.04.81 6.48-5.65 2.8-2.41 10.1 21.78 10.5 7.63-2.82z" />
                <path className="reg_under" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M187.22 294.87h8.9l-6.06-6.06 1.23-11.31-8.48-19.38-13.35-3.62-4.41 7.66-6.86-4.04-.82 5.26-14.1-5.26-8.06 8.88 8.47 7.7.39 11.7 20.98 12.08 7.68-.8 6.83-2.81z" />
                <path className="reg_under" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M89.58 179.04l.81 8.86 12.09 5.65 4.45-6.01 13.72 8.45 3.22 4.84 3.24-8.48 5.26 3.64 3.6-2.44 12.93-8.86-1.19-9.3-13.74-6.43-7.27 1.19-3.23 5.24h-2.42l-12.48-20.95-17.37 3.21-1.18 5.24 1.99 8.09z" />
                <path className="reg_under" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M140.85 222.63l3.22-8.5-1.62-9.28-6.48-11.3-3.6 2.44-5.26-3.64-3.24 8.48-3.22-4.84-13.72-8.45-4.45 6.01-12.09-5.65-6.05 7.67 11.69 8.5-7.76 11.52 8.58 2.61 5.24 11.69 3.61-3.23 7.29 2.41 13.31 3.24 14.55-4.04z" />
                <path className="reg_under" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M84.34 195.57l6.05-7.67-.81-8.86 2.43-8.06-1.99-8.09-6.91-.8-.81 3.63-5.63-.41-1.21-4.04-6.49-.39-2.41 3.25-4.42-2.04L46 172.18l2 6.86 15.34.81 1.22 5.67-10.88-2.04v4.81l7.67.43-.42 4.83-11.29.42-.42 5.64 4.04-.8 5.63 7.26-.79 5.26 7.26 1.6-.4-4.82 13.34 4.41 9.97 3.07 7.76-11.52z" />
                <path className="reg_under" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M135.97 193.55l6.48 11.3 1.62 9.28-3.22 8.5 8.05-3.23 8.9 1.59.81-4.02 8.05-2.44 3.65 2.85 3.18-.41v-7.65l5.25-2.45 2.45-6.43-3.64-3.23v-8.08l2.43-2.02-1.61-10.49-5.64-1.23-5.27 4.05-6.43-4.45-10.13-8.05-5.63 2.82 2.44 5.63 1.19 9.3z" />
                <path className="reg_under" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M186.83 149.98l-4.83 3.23 5.65 8.88 17.75-5.23 9.29 1.59 6.06-6.43 9.29 2.82 2.41-2.82-3.64-20.2-10.5-.83-2.43 5.26-8.85 2.44-7.27-4.45-22.21-4.02-.38 3.21 8.07 6.47z" />
                <path className="reg_under" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M191.29 179.85l4.02 1.62 10.91-7.3h4.82v5.68l3.65 3.63h7.26v-3.25l8.09 2.04-.42 5.27 15.72 7.23-1.6-8.06 7.66-4.03v-5.67l-6.43-6.03-2.85-10.1h-4.83v-2.83l-7.25-3.21-9.29-2.82-6.06 6.43-9.29-1.59-17.75 5.23 3.25 7.67-5.66 6.86 3.22 4.85z" />
                <path className="reg_under" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M172.73 175.39l5.64 1.23h6.87l5.66-6.86-3.25-7.67-5.65-8.88 4.83-3.23-1.59-10.08-8.07-6.47-4.05-.42-2.42-11.67 3.23-4.04-1.6-3.24-7.28-2.82-2.42 5.26-7.27-2.44-2.42-2.82h-3.23l2.83 18.98 9.28 10.08-4.02 23 6.44 6.02-3.21 5.67 6.43 4.45z" />
                <path className="reg_under" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M230.04 154.84l7.25 3.21v2.83h4.83l2.85 10.1 3.6-6.07 14.92-2.02 6.88-7.26-1.21-5.65 4.04-1.62 2.84-6.46 4.84-3.21-3.65-8.47-8.67-3.05-14.32 10.09-12.53-7.04-12.09-.83-.81 2.43 3.64 20.2z" />
                <path className="reg_under" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M254.24 137.26l14.32-10.09 8.67 3.05 2.83-4.46-4.02-7.24 1.63-9.92.37-2.2-6.83-10.9-6.97-.76-5.16 6.82-12.52 5.66-12.92.38-14.11 9.7.38 8.06 9.71 4.03 12.09.83z" />
                <path className="reg_under" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M463.68 148.77l-3.66 2.85v4.22l12.13 8.48-.22 7.03-5.83 4.04 2.62 12.9 13.91 7.7 9.31-28.26 11.67-15.71-9.67-6.91-10.08 1.23-7.08 6.26z" />
                <path className="reg_under" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M459.05 211.11l6.23 4.66 2.84 7.26-.79 6.86 2.42-1.19 5.63 4.01 3.64.4 6.44-8.88-2.42-6.49-.41-21.75-13.91-7.7z" />
                <path className="reg_under" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M344.21 198.81h3.23l8.5 10.08 5.65-2.02 4.84 2.85h10.08v-4.44h6.07v-4.84l5.2-3.23v-9.67l-5.63-3.66-1.2-6.87-11.3-2.84-3.63-7.66-8.87 2.81-9.71 7.69-8.04-2.84-3.58 4.9-.09 5.62 8.1 7.66z" />
                <path className="reg_under" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M380.95 177.01l1.2 6.87 5.63 3.66v9.67l-5.2 3.23v4.84h3.6l.82 4.83 5.23 4.42-2.4 2.85 1.61 4.02h8.89l2 3.25 7.28.4v-3.25h8.47v-6.03l5.67-5.25v-4.45l-4.89-6.06h-3.2l2.83-6.46-7.29-8.43h-4.84l-1.19-5.68-15.75-12.1-4.06 2.42z" />
                <path className="reg_under" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M338.57 168.13l.83 6.04 8.04 2.84 9.71-7.69 8.87-2.81 3.63 7.66 11.3 2.84 4.41-7.25 4.06-2.42 1.23-2.43-3.65-3.23 1.6-8.88h4.06l-4.88-10.06v-5.28l-8.05 1.02-5.67-3.84h-4.81l-3.86-4.42-6.64-.83-1.6 3.54-6.58-2.53-5.85 3.16v5.63l2.34 2.42-3.15 3 2.94 4.26-8.55 11.44-3.76 4.84z" />
                <path className="reg_under" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M364.01 111.24l-7.68 9.67 2.42 8.48 6.64.83 3.86 4.42h4.81l5.67 3.84 8.05-1.02h4.05l3.65-9.66-3.25-3.24v-3.65l6.48.82 6.86-1.2-14.13-12.11-6.84-1.2V94.31l-4.44-3.25-5.67 3.63-.43 6.87-12.07 1.2.4 5.66z" />
                <path className="reg_under" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M281.25 78.96l10.08 8.06h12.94l-2.02 5.65h12.1v2.43l10.49.4 2.83 2.01.19-5.23-1.59-3.46 3-4.22-8.46-13.72-4.22-1-4.06-6.26-9.45-1.62-6.69-9.28-4.24-8.06-13.32 4.03-7.62 4.85-1.36 24.99 8.19 2.43z" />
                <path className="reg_under" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M303.08 62l9.45 1.62 4.06 6.26 4.22 1 8.46 13.72-3 4.22 1.59 3.46-.19 5.23 15.34-2.01 15.35 3.22 2.43-14.92-4.85-3.24-10.88 1.62-.85-8.06-6.87-6.05-3.21 5.65-4.86-4.85V60.8l-2.81-5.26-10.9 5.66-6.84-7.66-2.03-13.33-14.54 4.45 4.24 8.06z" />
                <path className="reg_under" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M291.76 141.09l14.93 4.02 8.03 2.98 6.91-.44 4.99-2.41-3.99-10.6 7.09-11.3-3.85-4.22 1.43-6.07-11.53 2.63-4.83 5.66-6.47-4.04-17.33-4.67-2.24 3.24-7.23-7.27-1.63 9.92 4.02 7.24-2.83 4.46 3.65 8.47-4.84 3.21 11.7-.81z" />
                <path className="reg_under" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M278.04 106.4l-.37 2.2 7.23 7.27 2.24-3.24 17.33 4.67 6.47 4.04 4.83-5.66 11.53-2.63-1.87-6.05 2.24-7.07v-2.42l-2.83-2.01-10.49-.4v-2.43h-12.1l2.02-5.65h-12.94l-10.08-8.06-3.21 2-8.19-2.43-.27 5.27 3.23 4.04-4.85 2-3.72 4.9 6.97.76z" />
                <path className="reg_under" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M327.67 97.51v2.42l-2.24 7.07 1.87 6.05-1.43 6.07 3.85 4.22-7.09 11.3 3.99 10.6 11.68 15.07 8.55-11.44-2.94-4.26 3.15-3-2.34-2.42v-5.63l5.85-3.16 6.58 2.53 1.6-3.54-2.42-8.48 7.68-9.67-1.62-2.82-.4-5.66-3.63-4.04-15.35-3.22z" />
                <path className="reg_under" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M418.49 193.55l-2.83 6.46h3.2l4.89 6.06 6.83-3.21 7.66 4.44 4.44-1.62 4.45 2.81 4.83-2.42 7.09 5.04 9.67-22.82-2.62-12.9-8.88 5.87-8.46-4.02-1.44 3.39-10.28-1-9.3 4.66-4.62-9.88h-3.83l-9.49 6.85-3.44 3.86h4.84z" />
                <path className="reg_under" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M425.11 144.53l1.85 9.28h10.51l.57 6.07 11.3 3.42 5.27 5.45 8.87 1.6 2.62 5.04 5.83-4.04.22-7.03-12.13-8.48v-4.22l3.66-2.85 13.1 3.83 7.08-6.26-11.71-7.65-3.63 2.81-20.57-2.81-4.45-11.71-15.34-3.64-.8 2.84h-4.43l3.82 10.5z" />
                <path className="reg_under" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M413.23 127.6l.79 5.41 7.09 10.72-.82 8.07-2.62 4.23 1.62 3.85-2.24 3.42 2.24 11.11h3.83l4.62 9.88 9.3-4.66 10.28 1 1.44-3.39 8.46 4.02 8.88-5.87-2.62-5.04-8.87-1.6-5.27-5.45-11.3-3.42-.57-6.07h-10.51l-1.85-9.28 1.64-7.85-3.82-10.5-8.48-6.46-8.88.81 1.42 9.07z" />
                <path className="reg_under" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M392.23 120.91v3.65l3.25 3.24-3.65 9.66h-4.05v5.28l4.88 10.06h-4.06l-1.6 8.88 3.65 3.23-1.23 2.43 15.75 12.1 1.19 5.68 3.44-3.86 9.49-6.85-2.24-11.11 2.24-3.42-1.62-3.85 2.62-4.23.82-8.07-7.09-10.72-.79-5.41-6.24 2-1.42-9.07-6.86 1.2z" />
                <path className="reg_under" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M274.01 171.78h3.22l2.83 8.85h4.03l6.25-4.41v-6.64l3.31-3.53 1.55-9.26-5.61.41-13.34-3.18-3.05-5.66-4.04 1.62 1.21 5.65 3.64 6.05z" />
                <path className="reg_under" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M289.59 157.2l5.61-.41 3.15-1.97 5.17-2.37 5.21.09-2.04-7.43-14.93-4.02h-4.02l-11.7.81-2.84 6.46 3.05 5.66z" />
                <path className="reg_under" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M298.35 154.82l3.57-1.64" />
                <path className="reg_under" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M303.52 152.45l-1.6.73" />
                <path className="reg_under" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M273.2 148.36l3.05 5.66 13.34 3.18 5.61-.41" />
                <path className="reg_under" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M303.52 152.45l-1.6.73" />
                <path className="reg_under" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M303.52 152.45l5.21.09" />
                <path className="reg_under" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M305.44 161.39l6.04-.11" />
                <path className="reg_under" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M301.92 153.18l-3.57 1.64" />
                <path className="reg_under" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M295.2 156.79l-1.55 9.26" />
                <path className="reg_under" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M295.2 156.79l3.15-1.97" />
                <path className="reg_under" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M301.82 168.83l.33-4.94" />
                <path className="reg_under" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M301.92 153.18" />
                <path className="reg_under" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M302.57 158.54l-.65-5.36" />
                <path className="reg_under" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M305.44 161.39v1.32l-3.29 1.18" />
                <path className="reg_under" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M302.57 158.54l2.87 1.25v1.6" />
                <path className="reg_under" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M301.82 168.83l6.76.73" />
                <path className="reg_under" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M293.65 166.05l5.85 2.75 2.32.03" />
                <path className="reg_under" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M306.69 145.11l2.04 7.43" />
                <path className="reg_under" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M308.58 169.56l2.9-8.28" />
                <path className="reg_under" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M308.73 152.54l2.75 8.74" />
                <path className="reg_under" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M439.86 268.03l-11.12-12.52h-6.02l2.39-6.44-2.39-5.44-9.07-1.24-9.71 18.96 6.07 2.85-.82 8.04 4.46 3.24-5.64 12.93 7.24 9.27 4.85-4.84 3.22.4.8 3.22h4.04l6.33-6.06.11-4.83 3.64-4.83v-3.24l-3.41-3.63z" />
                <path className="reg_under" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M447.13 208.49l-4.45-2.81-4.44 1.62-7.66-4.44-6.83 3.21v4.45l-5.67 5.25v6.03h-8.47v3.25l2.84 2.42-3.67 6.07 4.87 8.85 9.07 1.24 22.6-16.56 4.44 2.82 2.42-3.64 7.24.41-1.97-10.11 1.6-5.44-7.09-5.04z" />
                <path className="reg_under" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M465.28 215.77l-6.23-4.66-1.6 5.44 1.97 10.11.83 5.18 4.28 2.03 2.8-3.98.79-6.86z" />
                <path className="reg_under" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M459.42 226.66l-7.24-.41-2.42 3.64-4.44-2.82-22.6 16.56 2.39 5.44-2.39 6.44h6.02l11.12 12.52-5.03 5.84 3.41 3.63 10.52-11.3.39-5.63 17.73-23.02-2.35-3.68-4.28-2.03z" />
                <path className="reg_under" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M323.64 210.52v8.05l-5.23 2.04 2.4 8.85 8.91.23 5.83 5.04 6.46-3.21 16.35 11.29 4.62-1.62.81-11.3 8.48-13.52-5.84-6.65-4.84-2.85-5.65 2.02-8.5-10.08h-3.23l-.38-6.46-8.1-7.66-12.5 4.84 2.03 3.25-2.84 4.43 4.44 10.9z" />
                <path className="reg_under" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M342.01 231.52l-6.46 3.21-5.83-5.04-8.91-.23-2.81 3.65.8 9.28 6.46 9.29v20.56l4.46 5.26 4.82-2.02 6.45 3.23 4.44-4.01 8.28 1.41 8.08-5.06.4-9.09-3.23-1.82 3.82-8.27 2.61 1.41 3.26-2.62-5.67-9.47-4.62 1.62z" />
                <path className="reg_under" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M385.58 262.76l-16.93-12.1-3.26 2.62-2.61-1.41-3.82 8.27 3.23 1.82-.4 9.09-8.08 5.06-8.28-1.41 4.45 9.66 8.48 4.84 2 6.46 2.42 6.06 11.71 1.63-.43-6.06 4.44 1.6 4.46-2.8 5.27 4.41 6.86-13.72 5.64-1.62 7.28 3.25 5.64-12.93-4.46-3.24.82-8.04-6.07-2.85-6.03-2.8z" />
                <path className="reg_under" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M227.59 264.2h3.65l4.43-3.63 12.51 14.91 5.04-4.03 4.46-14.73 3.8 1.83 3.46-2.82v-5.26l-3.46-6.26-5.42.6-.22-13.29-18.17-7.29-4.44 2.84-2.43-2.42-7.63 2.82v6.47l-4.86 11.69v4.44l9.28 8.05z" />
                <path className="reg_under" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M277.44 294.43l1.19-3.82 6.69 1.42 2.42-1.21 1.79 1.43 4.27.1.38-13.24-4.44-8.06 2.62-2.02-3.81-14.92-7.67.19 5.63-6.85-13.91-.82-7.66 3.84v5.26l-3.46 2.82-3.8-1.83-4.46 14.73-5.04 4.03v7.27l3.22 3.24h3.66l3.2 6.85 7.91 1.79 1.39-1.99z" />
                <path className="reg_under" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M288.55 254.11l3.81 14.92-2.62 2.02 4.44 8.06-.38 13.24 2.81.1 4.45-6.05 6.83.78v-6.44l5.67-5.68h3.21l2.42-2.82h6.07v-20.56l-6.46-9.29-.8-9.28-11.11-.8-12.92-8.29 1.02 7.86 2.6 6.86-5.83 1.01 2.21 4.86-7.46 2.84-5.63 6.85z" />
                <path className="reg_under" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M246.16 209.32l-8.49 14.91 18.17 7.29.22 13.29 5.42-.6 3.46 6.26 7.66-3.84 13.91.82 7.46-2.84-2.21-4.86 5.83-1.01-2.6-6.86-1.02-7.86-13.69.21-7.47-7.86-1.23-10.1-8.65 3.05-3.03-3.64-10.49-5.24-4.44 2.82z" />
                <path className="reg_under" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M280.28 224.23l13.69-.21 12.92 8.29 11.11.8 2.81-3.65-2.4-8.85 5.23-2.04v-8.05l3.22-2.41-4.44-10.9h-6.44l-6.87 3.23-4.04-2.85 1.62-4.41-6.42-2.75-6.89-2.89-5.23 2.82-3.85 8.65-3.05 4.84-9.67 2.42 1.23 10.1z" />
                <path className="reg_under" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M251.4 182.68l-7.66 4.03 1.6 8.06 4.07 5.67 10.49 5.24 3.03 3.64 8.65-3.05 9.67-2.42 3.05-4.84 3.85-8.65-4.06-9.73h-4.03l-2.83-8.85h-3.22v-10.1l-3.64-6.05-6.88 7.26-14.92 2.02-3.6 6.07 6.43 6.03z" />
                <path className="reg_under" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M412.45 227.47l-2.84-2.42-7.28-.4-2-3.25h-8.89l-1.61-4.02 2.4-2.85-5.23-4.42-.82-4.83h-9.67v4.44h-10.08l5.84 6.65-8.48 13.52-.81 11.3 5.67 9.47 16.93 12.1 12.33-4.21 6.03 2.8 9.71-18.96-4.87-8.85z" />
                <path className="reg_under" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M291.33 374.76v-4.02h-11.68l-6.02-4.45h-8.09V376l-6.07 9.25-4.41.41-3.88 6-2.61 4.07 2.83 8.09 10.52 7.28 5.22-3.66 4.07 2.45 4.01-4.05h6.85l2.83-2.85-2-3.61 6.84-4.85 5.25-4.44-1.19-12.91z" />
                <path className="reg_under" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M308.73 152.54l-5.21-.09-1.6.73-3.57 1.64-3.15 1.97-1.55 9.26 5.85 2.75 2.32.03 6.76.73 2.9-8.28z" />
                <path className="reg_under" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M300.27 190.43l7.62-5.74-1.63-8.07 2.32-7.06-6.76-.73-2.32-.03-5.85-2.75-3.31 3.53v6.64l-6.25 4.41 4.06 9.73 5.23-2.82z" />
                {/* <g>
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M314.72 148.09l-8.03-2.98 2.04 7.43 2.75 8.74-2.9 8.28-2.32 7.06 1.63 8.07-7.62 5.74 6.42 2.75-1.62 4.41 4.04 2.85 6.87-3.23h6.44l2.84-4.43-2.03-3.25 12.5-4.84.09-5.62 3.58-4.9-.83-6.04-4.03-2.98 3.76-4.84-11.68-15.07-4.99 2.41z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M220.32 424l10.48-4.47 4.03 2.04 8.1-4.85 3.23-6.83-2.42-2.86 1.6-2.81 6.06-.4-2.83-8.09 2.61-4.07-8.76-5.78-17.64.39-6.8-4.06-6.72 12.92-5.67 17.14 10.72 3.04-4.27 8.98 5.46-2.31z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M178.74 398.36l7.88-.61 11.92 10.11-1.62 4.41h8.67l5.67-17.14 6.72-12.92-5.71-3.44v-15.71l-13.73-4.84-7.25-8.9-7.72-.74 1.67 11.66 5.66 11.27-6.48-7.25-4.44-14.5-9.28-10.11-2.43 2.43-5.22 45.18 4.84-4.03v5.65l-3.65 3.63-.86 5.05 11.15-2.59z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M169.28 450.22l6.67-4.45 22.59 1.44v-8.7l3.23-1.2v-9.71l5.26-1.19 5.01-2.12 4.27-8.98-10.72-3.04h-8.67l1.62-4.41-11.92-10.11-7.88.61-4.21-3.39-11.15 2.59-6.01 34.52-4.83 8.87v3.63l-1.3 1.46 14.83.03z" />
                  <path d="M196.32 475.25l5.45-2.02 6.05-10.89-2.02-2.82.42-13.35-7.68-3.65v4.69l-22.59-1.44-6.67 4.45-3.21-4.15-14.83-.03-9.59 10.64 6.06 4.84 4.44-1.21 2.39 4.83-2.39 6.49 10.07 2.01 5.67 5.23 12.09 1.22 5.26 8.07 11.01-3.23z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M242.42 385.88l8.76 5.78 3.88-6 4.41-.41 6.07-9.25V363.06l-4.86-1.59-1.6-14.95-6.86-5.64-1.6-4.86h-7.69l-.81-5.25-7.29-.42-3.59 2.86-2.04 8.05-7.25 4.84-1.2 7.28-8.09 5.27-.39 4.41v15.71l5.71 3.44 6.8 4.06z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M334.54 275.48l-4.82 2.02-4.46-5.26h-6.07l-2.42 2.82h-3.21l-5.67 5.68v6.44l-6.83-.78-4.45 6.05 9.65 10.08 1.44 6.89 12.72-8.09 5.25 7.66 13.33.79 10.88 7.28 4.04-3.66.81-11.68 5.63-6.06-2-6.46-8.48-4.84-4.45-9.66-4.44 4.01z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M325.67 308.99l-5.25-7.66-12.72 8.09 1.41 6.83-7.29 6.88 4.44 5.62-1.19 2.02v6.05l2.04 2.02v5.67l21.79 6.44 5.64-5.67 23.39 1.62 3.24-4.05-10.9-16.91 2.04-6.06-2.43-2.82-10.88-7.28z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M334.54 355.8l-5.64-4.85-21.79-6.44-7.68 3.63v4.44l-4.86 5.24v5.24l-3.24 2.84v8.86l2.47 2.42 1.19 12.91h10.48l10.88-16.16 4.46 3.25 2.83 8.07 5.26-9.25h2.81l4.84-4.49 1.22-9.27z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M374.06 346.9h-16.13l-23.39-1.62-5.64 5.67 5.64 4.85 3.23 6.44-1.22 9.27 2.84.43 2.83 4.84h9.67l7.28 6.07 3.61-9.31h6.07l7.25-8.06v-6.47h3.63v-4.85l-4.41-2.01z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M220.75 310.22l.39-4.88-8.48-12.5 3.22-10.87-3.22-13.34 2.42-5.66-3.61-8.47h-13.73l-6.45 5.25-8.48-1.63 8.48 19.38-1.23 11.31 6.06 6.06h-8.9l1.64 7.25 14.52 5.64 6.86 7.66 3.65-6.43z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M246.96 299.28h4.44l6.86-6.44-3.2-6.85h-3.66l-3.22-3.24v-7.27l-12.51-14.91-4.43 3.63h-3.65v-6.08l-9.28-8.05-6.84 4.43 3.61 8.47-2.42 5.66 3.22 13.34-3.22 10.87 8.48 12.5-.39 4.88 7.67 1.99 4.03-4.03 2.8 2.04 7.89-5.27v-1.83z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M198.54 358.22l13.73 4.84.39-4.41-7.07-8.11-5.43-1.22 3.03-8.65-5.89-14.73 9.73-.22 3.21-10.3-6.86-7.66-14.52-5.64-1.64-7.25h-7.66l-6.83 2.81-5.67 8.88 6.43 8.08-3.61 7.26 2.03 4.44-6.09.4v4.84l8.9 4.44 8.48 9.7.37 2.86 7.72.74z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M221.95 346.1l7.25-4.84 2.04-8.05 3.59-2.86 8.1-10.47 5.64-2.42v-4.44h-5.43v-8.07l-7.89 5.27-2.8-2.04-4.03 4.03-7.67-1.99-6.86-1.23-3.65 6.43-3.21 10.3-9.73.22 5.89 14.73-3.03 8.65 5.43 1.22 7.07 8.11 8.09-5.27z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M330 395.96l-6.36-10.71-2.83-8.07-4.46-3.25-10.88 16.16h-10.48l-5.25 4.44-6.84 4.85 2 3.61-2.83 2.85 4.85 2.83.82 7.26 6.06-2.03 12.46 6.04 5.67 15.35 7.26-.39 3.35 6.86 6.27-5.35.57-3.93h6.35v-2.35l5.06-3.3v-5.97l-4.64-2.83 3.45-4.33-8.8-4.62z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M311.93 435.29l-5.67-15.35-12.46-6.04-6.06 2.03-2.84 2.42-5.25.4-8.44 9.28.37 4.45 4.83 7.26-.37 4.43h2l9.28 12.97 5.76 1.17 4.13-5.28 12.1 2.24 3.44-12.59 3.91 2.3 5.88-3.22-3.35-6.86z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M247.79 478.49l2.38-9.31 10.13 2.83 1.18-7.26 13.13-.4 6.48-8.49 6.23 1.28-9.28-12.97h-2l.37-4.43-4.83-7.26-.37-4.45-10.13 2.03-2 6.05-5.66-2.84-6.46 1.23 10.09 10.48-8.48 4.03-.39 10.51-5.25-2.84-5.26 3.63-10.47 7.29 4.84 9.26-4.84 6.85-.3 8.98 7.16 1.11-1.61-6.04 2.38-2.41 7.72 2.34-.43-5.16z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M232.04 476.86l-4.84-9.26 10.47-7.29-16.92-4.03-4.87 1.59.43-5.21-6.07-7.68-4.02 1.19-.42 13.35 2.02 2.82-6.05 10.89-5.45 2.02-.07 9.68h0l7.13 8.08 7.66 1.6 2.42-1.6 4.44 1.2 5.66-2.03 3.34.51.3-8.98z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M234.83 421.57l-4.03-2.04-10.48 4.47-2.82-2.02-5.46 2.31-5.01 2.12-5.26 1.19v9.71l-3.23 1.2v4.01l7.68 3.65 4.02-1.19 6.07 7.68-.43 5.21 4.87-1.59 16.92 4.03 5.26-3.63 5.25 2.84.39-10.51 8.48-4.03-10.09-10.48-3.22-5.67z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M284.9 418.35l2.84-2.42-.82-7.26-4.85-2.83h-6.85l-4.01 4.05-4.07-2.45-5.22 3.66-10.52-7.28-6.06.4-1.6 2.81 2.42 2.86-3.23 6.83-8.1 4.85 8.91 7.26 3.22 5.67 6.46-1.23 5.66 2.84 2-6.05 10.13-2.03 8.44-9.28z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M293.46 495.73l-2.22-5.35-6.47.99-3-6.32 3.85-.52-.52-4.34 1.82-1.22-2.83-8.35-9.48-6.27-13.13.4-1.18 7.26-10.13-2.83-2.38 9.31-5.67 4.04.43 5.16 19.75 6.11 4.03 4.85 6.07.39 3.22 4.84 10.18-6.13z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M315.16 486.94l-13.69-.39v7.25l-8.01 1.93-7.66 2.02-10.18 6.13 13.31 8.09 9.71-2.42 12.9 4.86 3.21-4.86 11.71-2.42 5.64 2.84v-5.69l-3.61-2.4-.38-16.13-6.88-3.22z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M317.17 464.75l-8.06-4.85.2-4.63-12.1-2.24-4.13 5.28-5.76-1.17-6.23-1.28-6.48 8.49 9.48 6.27 2.83 8.35-1.82 1.22.52 4.34-3.85.52 3 6.32 6.47-.99 2.22 5.35 8.01-1.93v-7.25l13.69.39 6.07-4.41 6.88 3.22-.05-2.42 4.45-4.84-2.03-5.26 6.66-5.92-16.72-7.79z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M354.73 425.2l-7.29 4.42-6.65-2.79-5.06 3.3v2.35h-6.35l-.57 3.93-6.27 5.35-5.88 3.22-3.91-2.3-3.44 12.59-.2 4.63 8.06 4.85 3.25-5.23 16.72 7.79 4.27-3.77 2 1.21 6.06-8.47 4.03 2.42 12.93-9.69 3.22-11.29z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M386.18 432.48l6-5.32 4.93-4.38-8.09-14.52-12.11-5.27-3.62 4.45-6.06-4.02-1.62-6.86-5.67 4.84 2.84 10.87-6.45 4.06-6.86-3.23-9.87.6-3.45 4.33 4.64 2.83v5.97l6.65 2.79 7.29-4.42 14.92 12.52-3.22 11.29 3.22 4.84 3.64-.21 11.7-12.31z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M359.17 382.85l-7.28-6.07h-9.67l-2.83-4.84-2.84-.43-4.84 4.49h-2.81l-5.26 9.25 6.36 10.71.8 13.12 8.8 4.62 9.87-.6 6.86 3.23 6.45-4.06-2.84-10.87 5.67-4.84-6.04-6.87z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M461.26 392.51l-10.9-1.59-9.7 4.05-7.24 8.45 3.2 6.03-9.26-.78-8.07 5.63 5.65 4.05 1.19 10.48 6.47 7.28 6.86-2.01 3.22 2.39 6.08-5.64 4.84 1.63 4.43-4.05h6.44l3.23-4.84 6.88-.81-1.23-4.84-3.6-.41-5.28-11.29 6.07-6.07 3.69-15.8-4.91-2.35z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M451.96 360.64l-4.83-2.82-2.03 5.24 4.84 5.67v5.2l-5.64-1.99-12.12 5.24-3.61 3.62-3.63 3.64-3.24 9.7-5.26-.4 1.64 7.66 6.04 1.59 3.24 5.68 9.26.78-3.2-6.03 7.24-8.45 9.7-4.05 10.9 1.59 8.06-10.49 4.91 2.35 1.15-4.77-14.12-20.59-6.43-.36z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M485.05 435.7l13.75-5.25v-7.67l-2.82-2.02 6.83-12.5-5.22-3.65-6.87 4.47-20.18-8.91-6.07 6.07 5.28 11.29 3.6.41 1.23 4.84-6.88.81-3.23 4.84 4.46 7.68 8.87 9.26 7.25-4.04z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M464.47 428.43h-6.44l-4.43 4.05-4.84-1.63-6.08 5.64-3.22-2.39-6.86 2.01-4.44 10.48 2.83 5.65-3.63 4.44 4.44 3.63-4.84 6.06 11.28 6.46 2.03-4.43 4.83 2.39 4.84-4.04 5.26 2.43 12.13-6.07v-6.43l10.08-6.46.39-4.85-8.87-9.26z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M424.94 418.35l-5.65-4.05-14.12-5.63v-4.06l-3.65.4 1.2-6.03-3.22-2.42-2.81 4.44 1.22 4.41-2.04 1.24-4.43-2.04-2.42 3.65 8.09 14.52-4.93 4.38 14.18 7.74 9.71.39 9.25 4.45 7.28-3.63-6.47-7.28z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M427.36 456.68l3.63-4.44-2.83-5.65 4.44-10.48-7.28 3.63-9.25-4.45-9.71-.39-14.18-7.74-6 5.32-1.19 8.85-11.7 12.31 11.7-.61 3.61 5.67h6.09l2.42-5.26 8.06 6.87 8.48-3.63 3.22 2.02v6.05l10.09 1.62 4.84-6.06z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M387 394.97l4.44-9.72 4.04-9.67-4.45-14.94.8-8.87-3.23-4.04-8.87 6.43v4.85h-3.63v6.47l-7.25 8.06h-6.07l-3.61 9.31.4 6.84 6.04 6.87 1.62 6.86 6.06 4.02 3.62-4.45 12.11 5.27 2.42-3.65z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M420.1 377.18l-2.43-15.71-10.51 1.59-1.99-9.29-8.89-5.22-4.45 3.22-.8 8.87 4.45 14.94-4.04 9.67-4.44 9.72 4.44 9.64 4.43 2.04 2.04-1.24-1.22-4.41 2.81-4.44 3.22 2.42-1.2 6.03 3.65-.4v4.06l14.12 5.63 8.07-5.63-3.24-5.68-6.04-1.59-1.64-7.66 5.26.4 3.24-9.7 3.63-3.64z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M395.09 335.63l-1.24 6.45-3.2.41-2.05 5.24 3.23 4.04 4.45-3.22 8.89 5.22 1.99 9.29 10.51-1.59 2.43 15.71 8.47 3.62 3.61-3.62 12.12-5.24 5.64 1.99v-5.2l-4.84-5.67 2.03-5.24-5.25-1.63 1.2-12.51-8.48-4.03-2 3.2-9.28-2.79.43-6.85L412 319.08l-10.48 5.64 1.66 4.03z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M385.79 338.44l.39-2.42h-5.65l-6.04-5.25 1.61-6.05-5.23-15.33 4.45-2.83-.83-3.21-11.71-1.63-2.42-6.06-5.63 6.06-.81 11.68-4.04 3.66 2.43 2.82-2.04 6.06 10.9 16.91-3.24 4.05h16.13l1.26 5.25 4.41 2.01 8.87-6.43 2.05-5.24z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M378.5 298.89l-4.44-1.6.43 6.06.83 3.21-4.45 2.83 5.23 15.33-1.61 6.05 6.04 5.25h5.65l-.39 2.42 4.86 4.05 3.2-.41 1.24-6.45 8.09-6.88-1.66-4.03-6.04-1.23-2.82-6.43-4.88.4-1.19-6.86 2.83-5.26-1.19-4.84-5.27-4.41z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M424.12 296.46l-.8-3.22-3.22-.4-4.85 4.84-7.24-9.27-7.28-3.25-5.64 1.62-6.86 13.72 1.19 4.84-2.83 5.26 1.19 6.86 4.88-.4 2.82 6.43 6.04 1.23 10.48-5.64 11.75 14.13 1.57-17.79 2.04-8.45 6.85-6.47.28-10.1-6.33 6.06z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M425.32 315.42l21.41 12.92 7.65-14.52 6.07 9.31 6.88-2.04 4.82-5.25-4.45-8.87-2.42.79-2.02-15.31-3.24-3.25-15.33 3.25-6.84 8.45-3.64-.4-6.85 6.47z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M454.38 313.82l-7.65 14.52-21.41-12.92-1.57 17.79-.43 6.85 9.28 2.79 2-3.2 8.48 4.03-1.2 12.51 5.25 1.63 4.83 2.82 2.87-1.99 6.43.36 12.09-6.03 5.25-12.1-7.66-15.33-3.24-1.23-.37-3.23-6.88 2.04z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M281.25 323.49l-7.24-2.82-6.05-15.33-3.24-1.6 3.63-4.85-2.18-4.26-7.91-1.79-6.86 6.44h-4.44l-3.82 3.84v9.9h5.43v4.44l-5.64 2.42-8.1 10.47 7.29.42.81 5.25h7.69l1.6 4.86 6.86 5.64 24.2-15.75z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M301.82 323.13l7.29-6.88-1.41-6.83-1.44-6.89-9.65-10.08-2.81-.1-4.27-.1-1.79-1.43-2.42 1.21-6.69-1.42-1.19 3.82-9.88-1.79-1.39 1.99 2.18 4.26-3.63 4.85 3.24 1.6 6.05 15.33 7.24 2.82 2.03 7.28 6.86-2.84 9.29 3.65 5.64-.81 1.19-2.02z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M279.65 370.74h11.68v-4.84l3.24-2.84v-5.24l4.86-5.24v-4.44l7.68-3.63v-5.67l-2.04-2.02v-6.05l-5.64.81-9.29-3.65-6.86 2.84-24.2 15.75 1.6 14.95 4.86 1.59v3.23h8.09z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M158.19 258.12l6.86 4.04 4.41-7.66 2.04-14.51 10.5-.83-2.02-12.91-4.84-2.02-1.65-7.26-3.18.41-3.65-2.85-8.05 2.44-.81 4.02-8.9-1.59-8.05 3.23v5.64l-14.55 4.04-3.22 5.24v5.26l6.07 3.19 6.82-4.02 9.69 3.24-8.85 1.22-3.65 4.44 10.11 7.24 14.1 5.26z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M197.74 254.5h13.73l6.84-4.43v-4.44l4.86-11.69v-6.47l-21.78-10.5-7.71 2.43-20.19-2.43 1.65 7.26 4.84 2.02 2.02 12.91-10.5.83-2.04 14.51 13.35 3.62 8.48 1.63z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M201.39 216.97l2.41-10.1 5.65-2.8-.81-6.48 4.02-4.04-1.19-4.83 3.22-5.24-3.65-3.63v-5.68h-4.82l-10.91 7.3-4.02-1.62-2.83 1.62-3.22-4.85h-6.87l1.61 10.49-2.43 2.02v8.08l3.64 3.23-2.45 6.43-5.25 2.45v7.65l20.19 2.43z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M233.23 227.07l4.44-2.84 8.49-14.91-1.19-6.06 4.44-2.82-4.07-5.67-15.72-7.23.42-5.27-8.09-2.04v3.25h-7.26l-3.22 5.24 1.19 4.83-4.02 4.04.81 6.48-5.65 2.8-2.41 10.1 21.78 10.5 7.63-2.82z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M187.22 294.87h8.9l-6.06-6.06 1.23-11.31-8.48-19.38-13.35-3.62-4.41 7.66-6.86-4.04-.82 5.26-14.1-5.26-8.06 8.88 8.47 7.7.39 11.7 20.98 12.08 7.68-.8 6.83-2.81z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M89.58 179.04l.81 8.86 12.09 5.65 4.45-6.01 13.72 8.45 3.22 4.84 3.24-8.48 5.26 3.64 3.6-2.44 12.93-8.86-1.19-9.3-13.74-6.43-7.27 1.19-3.23 5.24h-2.42l-12.48-20.95-17.37 3.21-1.18 5.24 1.99 8.09z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M140.85 222.63l3.22-8.5-1.62-9.28-6.48-11.3-3.6 2.44-5.26-3.64-3.24 8.48-3.22-4.84-13.72-8.45-4.45 6.01-12.09-5.65-6.05 7.67 11.69 8.5-7.76 11.52 8.58 2.61 5.24 11.69 3.61-3.23 7.29 2.41 13.31 3.24 14.55-4.04z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M84.34 195.57l6.05-7.67-.81-8.86 2.43-8.06-1.99-8.09-6.91-.8-.81 3.63-5.63-.41-1.21-4.04-6.49-.39-2.41 3.25-4.42-2.04L46 172.18l2 6.86 15.34.81 1.22 5.67-10.88-2.04v4.81l7.67.43-.42 4.83-11.29.42-.42 5.64 4.04-.8 5.63 7.26-.79 5.26 7.26 1.6-.4-4.82 13.34 4.41 9.97 3.07 7.76-11.52z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M135.97 193.55l6.48 11.3 1.62 9.28-3.22 8.5 8.05-3.23 8.9 1.59.81-4.02 8.05-2.44 3.65 2.85 3.18-.41v-7.65l5.25-2.45 2.45-6.43-3.64-3.23v-8.08l2.43-2.02-1.61-10.49-5.64-1.23-5.27 4.05-6.43-4.45-10.13-8.05-5.63 2.82 2.44 5.63 1.19 9.3z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M186.83 149.98l-4.83 3.23 5.65 8.88 17.75-5.23 9.29 1.59 6.06-6.43 9.29 2.82 2.41-2.82-3.64-20.2-10.5-.83-2.43 5.26-8.85 2.44-7.27-4.45-22.21-4.02-.38 3.21 8.07 6.47z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M191.29 179.85l4.02 1.62 10.91-7.3h4.82v5.68l3.65 3.63h7.26v-3.25l8.09 2.04-.42 5.27 15.72 7.23-1.6-8.06 7.66-4.03v-5.67l-6.43-6.03-2.85-10.1h-4.83v-2.83l-7.25-3.21-9.29-2.82-6.06 6.43-9.29-1.59-17.75 5.23 3.25 7.67-5.66 6.86 3.22 4.85z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M172.73 175.39l5.64 1.23h6.87l5.66-6.86-3.25-7.67-5.65-8.88 4.83-3.23-1.59-10.08-8.07-6.47-4.05-.42-2.42-11.67 3.23-4.04-1.6-3.24-7.28-2.82-2.42 5.26-7.27-2.44-2.42-2.82h-3.23l2.83 18.98 9.28 10.08-4.02 23 6.44 6.02-3.21 5.67 6.43 4.45z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M230.04 154.84l7.25 3.21v2.83h4.83l2.85 10.1 3.6-6.07 14.92-2.02 6.88-7.26-1.21-5.65 4.04-1.62 2.84-6.46 4.84-3.21-3.65-8.47-8.67-3.05-14.32 10.09-12.53-7.04-12.09-.83-.81 2.43 3.64 20.2z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M254.24 137.26l14.32-10.09 8.67 3.05 2.83-4.46-4.02-7.24 1.63-9.92.37-2.2-6.83-10.9-6.97-.76-5.16 6.82-12.52 5.66-12.92.38-14.11 9.7.38 8.06 9.71 4.03 12.09.83z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M463.68 148.77l-3.66 2.85v4.22l12.13 8.48-.22 7.03-5.83 4.04 2.62 12.9 13.91 7.7 9.31-28.26 11.67-15.71-9.67-6.91-10.08 1.23-7.08 6.26z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M459.05 211.11l6.23 4.66 2.84 7.26-.79 6.86 2.42-1.19 5.63 4.01 3.64.4 6.44-8.88-2.42-6.49-.41-21.75-13.91-7.7z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M344.21 198.81h3.23l8.5 10.08 5.65-2.02 4.84 2.85h10.08v-4.44h6.07v-4.84l5.2-3.23v-9.67l-5.63-3.66-1.2-6.87-11.3-2.84-3.63-7.66-8.87 2.81-9.71 7.69-8.04-2.84-3.58 4.9-.09 5.62 8.1 7.66z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M380.95 177.01l1.2 6.87 5.63 3.66v9.67l-5.2 3.23v4.84h3.6l.82 4.83 5.23 4.42-2.4 2.85 1.61 4.02h8.89l2 3.25 7.28.4v-3.25h8.47v-6.03l5.67-5.25v-4.45l-4.89-6.06h-3.2l2.83-6.46-7.29-8.43h-4.84l-1.19-5.68-15.75-12.1-4.06 2.42z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M338.57 168.13l.83 6.04 8.04 2.84 9.71-7.69 8.87-2.81 3.63 7.66 11.3 2.84 4.41-7.25 4.06-2.42 1.23-2.43-3.65-3.23 1.6-8.88h4.06l-4.88-10.06v-5.28l-8.05 1.02-5.67-3.84h-4.81l-3.86-4.42-6.64-.83-1.6 3.54-6.58-2.53-5.85 3.16v5.63l2.34 2.42-3.15 3 2.94 4.26-8.55 11.44-3.76 4.84z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M364.01 111.24l-7.68 9.67 2.42 8.48 6.64.83 3.86 4.42h4.81l5.67 3.84 8.05-1.02h4.05l3.65-9.66-3.25-3.24v-3.65l6.48.82 6.86-1.2-14.13-12.11-6.84-1.2V94.31l-4.44-3.25-5.67 3.63-.43 6.87-12.07 1.2.4 5.66z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M281.25 78.96l10.08 8.06h12.94l-2.02 5.65h12.1v2.43l10.49.4 2.83 2.01.19-5.23-1.59-3.46 3-4.22-8.46-13.72-4.22-1-4.06-6.26-9.45-1.62-6.69-9.28-4.24-8.06-13.32 4.03-7.62 4.85-1.36 24.99 8.19 2.43z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M303.08 62l9.45 1.62 4.06 6.26 4.22 1 8.46 13.72-3 4.22 1.59 3.46-.19 5.23 15.34-2.01 15.35 3.22 2.43-14.92-4.85-3.24-10.88 1.62-.85-8.06-6.87-6.05-3.21 5.65-4.86-4.85V60.8l-2.81-5.26-10.9 5.66-6.84-7.66-2.03-13.33-14.54 4.45 4.24 8.06z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M291.76 141.09l14.93 4.02 8.03 2.98 6.91-.44 4.99-2.41-3.99-10.6 7.09-11.3-3.85-4.22 1.43-6.07-11.53 2.63-4.83 5.66-6.47-4.04-17.33-4.67-2.24 3.24-7.23-7.27-1.63 9.92 4.02 7.24-2.83 4.46 3.65 8.47-4.84 3.21 11.7-.81z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M278.04 106.4l-.37 2.2 7.23 7.27 2.24-3.24 17.33 4.67 6.47 4.04 4.83-5.66 11.53-2.63-1.87-6.05 2.24-7.07v-2.42l-2.83-2.01-10.49-.4v-2.43h-12.1l2.02-5.65h-12.94l-10.08-8.06-3.21 2-8.19-2.43-.27 5.27 3.23 4.04-4.85 2-3.72 4.9 6.97.76z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M327.67 97.51v2.42l-2.24 7.07 1.87 6.05-1.43 6.07 3.85 4.22-7.09 11.3 3.99 10.6 11.68 15.07 8.55-11.44-2.94-4.26 3.15-3-2.34-2.42v-5.63l5.85-3.16 6.58 2.53 1.6-3.54-2.42-8.48 7.68-9.67-1.62-2.82-.4-5.66-3.63-4.04-15.35-3.22z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M418.49 193.55l-2.83 6.46h3.2l4.89 6.06 6.83-3.21 7.66 4.44 4.44-1.62 4.45 2.81 4.83-2.42 7.09 5.04 9.67-22.82-2.62-12.9-8.88 5.87-8.46-4.02-1.44 3.39-10.28-1-9.3 4.66-4.62-9.88h-3.83l-9.49 6.85-3.44 3.86h4.84z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M425.11 144.53l1.85 9.28h10.51l.57 6.07 11.3 3.42 5.27 5.45 8.87 1.6 2.62 5.04 5.83-4.04.22-7.03-12.13-8.48v-4.22l3.66-2.85 13.1 3.83 7.08-6.26-11.71-7.65-3.63 2.81-20.57-2.81-4.45-11.71-15.34-3.64-.8 2.84h-4.43l3.82 10.5z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M413.23 127.6l.79 5.41 7.09 10.72-.82 8.07-2.62 4.23 1.62 3.85-2.24 3.42 2.24 11.11h3.83l4.62 9.88 9.3-4.66 10.28 1 1.44-3.39 8.46 4.02 8.88-5.87-2.62-5.04-8.87-1.6-5.27-5.45-11.3-3.42-.57-6.07h-10.51l-1.85-9.28 1.64-7.85-3.82-10.5-8.48-6.46-8.88.81 1.42 9.07z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M392.23 120.91v3.65l3.25 3.24-3.65 9.66h-4.05v5.28l4.88 10.06h-4.06l-1.6 8.88 3.65 3.23-1.23 2.43 15.75 12.1 1.19 5.68 3.44-3.86 9.49-6.85-2.24-11.11 2.24-3.42-1.62-3.85 2.62-4.23.82-8.07-7.09-10.72-.79-5.41-6.24 2-1.42-9.07-6.86 1.2z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M274.01 171.78h3.22l2.83 8.85h4.03l6.25-4.41v-6.64l3.31-3.53 1.55-9.26-5.61.41-13.34-3.18-3.05-5.66-4.04 1.62 1.21 5.65 3.64 6.05z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M289.59 157.2l5.61-.41 3.15-1.97 5.17-2.37 5.21.09-2.04-7.43-14.93-4.02h-4.02l-11.7.81-2.84 6.46 3.05 5.66z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M298.35 154.82l3.57-1.64" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M303.52 152.45l-1.6.73" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M273.2 148.36l3.05 5.66 13.34 3.18 5.61-.41" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M303.52 152.45l-1.6.73" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M303.52 152.45l5.21.09" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M301.92 153.18l-3.57 1.64" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M295.2 156.79l-1.55 9.26" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M295.2 156.79l3.15-1.97" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M301.92 153.18h0" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M301.82 168.83l6.76.73" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M293.65 166.05l5.85 2.75 2.32.03" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M306.69 145.11l2.04 7.43" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M308.58 169.56l2.9-8.28" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M308.73 152.54l2.75 8.74" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M439.86 268.03l-11.12-12.52h-6.02l2.39-6.44-2.39-5.44-9.07-1.24-9.71 18.96 6.07 2.85-.82 8.04 4.46 3.24-5.64 12.93 7.24 9.27 4.85-4.84 3.22.4.8 3.22h4.04l6.33-6.06.11-4.83 3.64-4.83v-3.24l-3.41-3.63z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M447.13 208.49l-4.45-2.81-4.44 1.62-7.66-4.44-6.83 3.21v4.45l-5.67 5.25v6.03h-8.47v3.25l2.84 2.42-3.67 6.07 4.87 8.85 9.07 1.24 22.6-16.56 4.44 2.82 2.42-3.64 7.24.41-1.97-10.11 1.6-5.44-7.09-5.04z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M465.28 215.77l-6.23-4.66-1.6 5.44 1.97 10.11.83 5.18 4.28 2.03 2.8-3.98.79-6.86z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M459.42 226.66l-7.24-.41-2.42 3.64-4.44-2.82-22.6 16.56 2.39 5.44-2.39 6.44h6.02l11.12 12.52-5.03 5.84 3.41 3.63 10.52-11.3.39-5.63 17.73-23.02-2.35-3.68-4.28-2.03z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M323.64 210.52v8.05l-5.23 2.04 2.4 8.85 8.91.23 5.83 5.04 6.46-3.21 16.35 11.29 4.62-1.62.81-11.3 8.48-13.52-5.84-6.65-4.84-2.85-5.65 2.02-8.5-10.08h-3.23l-.38-6.46-8.1-7.66-12.5 4.84 2.03 3.25-2.84 4.43 4.44 10.9z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M342.01 231.52l-6.46 3.21-5.83-5.04-8.91-.23-2.81 3.65.8 9.28 6.46 9.29v20.56l4.46 5.26 4.82-2.02 6.45 3.23 4.44-4.01 8.28 1.41 8.08-5.06.4-9.09-3.23-1.82 3.82-8.27 2.61 1.41 3.26-2.62-5.67-9.47-4.62 1.62z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M385.58 262.76l-16.93-12.1-3.26 2.62-2.61-1.41-3.82 8.27 3.23 1.82-.4 9.09-8.08 5.06-8.28-1.41 4.45 9.66 8.48 4.84 2 6.46 2.42 6.06 11.71 1.63-.43-6.06 4.44 1.6 4.46-2.8 5.27 4.41 6.86-13.72 5.64-1.62 7.28 3.25 5.64-12.93-4.46-3.24.82-8.04-6.07-2.85-6.03-2.8z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M227.59 264.2h3.65l4.43-3.63 12.51 14.91 5.04-4.03 4.46-14.73 3.8 1.83 3.46-2.82v-5.26l-3.46-6.26-5.42.6-.22-13.29-18.17-7.29-4.44 2.84-2.43-2.42-7.63 2.82v6.47l-4.86 11.69v4.44l9.28 8.05z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M277.44 294.43l1.19-3.82 6.69 1.42 2.42-1.21 1.79 1.43 4.27.1.38-13.24-4.44-8.06 2.62-2.02-3.81-14.92-7.67.19 5.63-6.85-13.91-.82-7.66 3.84v5.26l-3.46 2.82-3.8-1.83-4.46 14.73-5.04 4.03v7.27l3.22 3.24h3.66l3.2 6.85 7.91 1.79 1.39-1.99z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M288.55 254.11l3.81 14.92-2.62 2.02 4.44 8.06-.38 13.24 2.81.1 4.45-6.05 6.83.78v-6.44l5.67-5.68h3.21l2.42-2.82h6.07v-20.56l-6.46-9.29-.8-9.28-11.11-.8-12.92-8.29 1.02 7.86 2.6 6.86-5.83 1.01 2.21 4.86-7.46 2.84-5.63 6.85z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M246.16 209.32l-8.49 14.91 18.17 7.29.22 13.29 5.42-.6 3.46 6.26 7.66-3.84 13.91.82 7.46-2.84-2.21-4.86 5.83-1.01-2.6-6.86-1.02-7.86-13.69.21-7.47-7.86-1.23-10.1-8.65 3.05-3.03-3.64-10.49-5.24-4.44 2.82z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M280.28 224.23l13.69-.21 12.92 8.29 11.11.8 2.81-3.65-2.4-8.85 5.23-2.04v-8.05l3.22-2.41-4.44-10.9h-6.44l-6.87 3.23-4.04-2.85 1.62-4.41-6.42-2.75-6.89-2.89-5.23 2.82-3.85 8.65-3.05 4.84-9.67 2.42 1.23 10.1z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M251.4 182.68l-7.66 4.03 1.6 8.06 4.07 5.67 10.49 5.24 3.03 3.64 8.65-3.05 9.67-2.42 3.05-4.84 3.85-8.65-4.06-9.73h-4.03l-2.83-8.85h-3.22v-10.1l-3.64-6.05-6.88 7.26-14.92 2.02-3.6 6.07 6.43 6.03z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M412.45 227.47l-2.84-2.42-7.28-.4-2-3.25h-8.89l-1.61-4.02 2.4-2.85-5.23-4.42-.82-4.83h-9.67v4.44h-10.08l5.84 6.65-8.48 13.52-.81 11.3 5.67 9.47 16.93 12.1 12.33-4.21 6.03 2.8 9.71-18.96-4.87-8.85z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M291.33 374.76v-4.02h-11.68l-6.02-4.45h-8.09V376l-6.07 9.25-4.41.41-3.88 6-2.61 4.07 2.83 8.09 10.52 7.28 5.22-3.66 4.07 2.45 4.01-4.05h6.85l2.83-2.85-2-3.61 6.84-4.85 5.25-4.44-1.19-12.91z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M300.27 190.43l7.62-5.74-1.63-8.07 2.32-7.06-6.76-.73-2.32-.03-5.85-2.75-3.31 3.53v6.64l-6.25 4.41 4.06 9.73 5.23-2.82z" />
                </g> */}
                {/* <path className="reg_under reg_under_white" fill="#ffffff" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M531.25 462.38l-.61-19.82-4.96.43-1.94 18.76-8.84-2.17-12.92 6.46-11.85 14.69 4.95 5.17 9.45.2 13.59 12.3 12.28 8.61 4.98-9.04.42-24.58z" /> */}
                <path className="reg_under" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M504.53 486.1l-9.45-.2-3.68 2.99.87 5.62 6.23 4.1-4.94 9.27 6.91 1.73-3.69 7.94 8.43 4.53-4.32 4.33 14.65 12.29 6.25 1.3 6.89-12.74 1.72-20.25-12.28-8.61z" />
                <path className="reg_under" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M486.6 55.04l-7.17 4.26-1.4 2.07-3.52-.31-2.88 1.07-4.04 3.83-.24 2.84 5.24 1.51 1.1 3.21 2.03-.07 10.62 4.78h1.86l3.74.02 1.99 1.08 8.64-3.45 4.16 1.14 6.16 1.89.79-3.73.34-2.29-3.86-1.63-4.27 2.54-2.04-.18.86-4.68-.48-6.77-2.65-1.48-1.46-5.39z" />
                <path className="reg_under" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M486.6 55.04l-7.17 4.26-1.4 2.07-3.52-.31-2.88 1.07-4.04 3.83-.24 2.84 5.24 1.51 1.1 3.21 2.03-.07 10.62 4.78h1.86l-3.24 7.15 2.06 2.78-4.13 6.65-.4 3.58-4.21.16-.25-5.17-6.99-1.87-2-2.36-.24-3.76-3-.47-.5-3.06-3.75-.47-2.74-4.93-2.01 1.18-.99-4.24.75-12.45 3.5-5.88 6.73-3.29 7.74-6.34 7.5-3.06.63-.24 3.34.32 3.93 1.73-.22 2.79-4.05 4.26z" />
                <path className="reg_under" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M486.6 55.04l-.94-3.8 4.05-4.26.22-2.79-3.93-1.73-3.34-.32-.63.24.5-3.06 1.99-.7 5.52 2.14 8.21-3.79 4.75 4 10.24-.24 3.49-2.35 2.01.94 5.24-5.87 1.39-1.49 1.35-2.98 2.75.47 2.09-3.68 3.17 2.51-2.99 5.98 3.91 6.89-4.78 17.32 4.78 4.17-3.59 2.99 1.46 13.03-3.73-1.31-.45-2.69-9.31-7.32-5.41-.42-9.91 2.02-.48-6.77-2.65-1.48-1.46-5.39z" />
                <path className="reg_under" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M502.57 75.88l4.16 1.14 6.16 1.89.79-3.73.34-2.29-3.86-1.63-4.27 2.54-2.04-.18.86-4.68 9.91-2.02 5.41.42 9.31 7.32.45 2.69 3.73 1.31 3.91 9.07-5.96 11.94 2.39 8.36-5.37 4.75-2.29-2.23-4.72-9.88-14.24 3.76-4.24-.7-1-1.18-5.75 1.18-1.48-6.35-3.51.71-3.99-2.59-2.67.29-2.11 2.6.4-3.58 4.13-6.65-2.06-2.78 3.24-7.15 3.74.02 1.99 1.08z" />
                <path className="reg_under" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M531.25 462.38l-.61-19.82-4.96.43-1.94 18.76-8.84-2.17-12.92 6.46-11.85 14.69 4.95 5.17 9.45.2 13.59 12.3 12.28 8.61 4.98-9.04.42-24.58z" />
                <path className="reg_under" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M504.53 486.1l-9.45-.2-3.68 2.99.87 5.62 6.23 4.1-4.94 9.27 6.91 1.73-3.69 7.94 8.43 4.53-4.32 4.33 14.65 12.29 6.25 1.3 6.89-12.74 1.72-20.25-12.28-8.61z" />
                <path className="reg_under reg_under_white" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M486.6 55.04l-7.17 4.26-1.4 2.07-3.52-.31-2.88 1.07-4.04 3.83-.24 2.84 5.24 1.51 1.1 3.21 2.03-.07 10.62 4.78h1.86l3.74.02 1.99 1.08 8.64-3.45 4.16 1.14 6.16 1.89.79-3.73.34-2.29-3.86-1.63-4.27 2.54-2.04-.18.86-4.68-.48-6.77-2.65-1.48-1.46-5.39z" />
                <path className="reg_under reg_under_white" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M486.6 55.04l-7.17 4.26-1.4 2.07-3.52-.31-2.88 1.07-4.04 3.83-.24 2.84 5.24 1.51 1.1 3.21 2.03-.07 10.62 4.78h1.86l-3.24 7.15 2.06 2.78-4.13 6.65-.4 3.58-4.21.16-.25-5.17-6.99-1.87-2-2.36-.24-3.76-3-.47-.5-3.06-3.75-.47-2.74-4.93-2.01 1.18-.99-4.24.75-12.45 3.5-5.88 6.73-3.29 7.74-6.34 7.5-3.06.63-.24 3.34.32 3.93 1.73-.22 2.79-4.05 4.26z" />
                <path className="reg_under reg_under_white" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M486.6 55.04l-.94-3.8 4.05-4.26.22-2.79-3.93-1.73-3.34-.32-.63.24.5-3.06 1.99-.7 5.52 2.14 8.21-3.79 4.75 4 10.24-.24 3.49-2.35 2.01.94 5.24-5.87 1.39-1.49 1.35-2.98 2.75.47 2.09-3.68 3.17 2.51-2.99 5.98 3.91 6.89-4.78 17.32 4.78 4.17-3.59 2.99 1.46 13.03-3.73-1.31-.45-2.69-9.31-7.32-5.41-.42-9.91 2.02h0l-.48-6.77-2.65-1.48-1.46-5.39z" />
                <path className="reg_under reg_under_white" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M502.57 75.88l4.16 1.14 6.16 1.89.79-3.73.34-2.29-3.86-1.63-4.27 2.54-2.04-.18.86-4.68 9.91-2.02 5.41.42 9.31 7.32.45 2.69 3.73 1.31 3.91 9.07-5.96 11.94 2.39 8.36-5.37 4.75-2.29-2.23-4.72-9.88-14.24 3.76-4.24-.7-1-1.18-5.75 1.18-1.48-6.35-3.51.71-3.99-2.59-2.67.29-2.11 2.6.4-3.58 4.13-6.65-2.06-2.78 3.24-7.15 3.74.02 1.99 1.08z" />

              </g>

              {!!data &&

                <g className={`map-svg map-fr map-fr-departement departement-selection-${selected?.department}`} strokeWidth="2">


                  <path className="reg reg-77" onClick={() => loadInfosDept("77", "")} data-uk-tooltip={`title: ${data.find((el: "77") => el.department === "77")?.name}; cls: tooltip-map; animation: uk-animation-scale-up;`} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M314.72 148.09l-8.03-2.98 2.04 7.43 2.75 8.74-2.9 8.28-2.32 7.06 1.63 8.07-7.62 5.74 6.42 2.75-1.62 4.41 4.04 2.85 6.87-3.23h6.44l2.84-4.43-2.03-3.25 12.5-4.84.09-5.62 3.58-4.9-.83-6.04-4.03-2.98 3.76-4.84-11.68-15.07-4.99 2.41z" />
                  <path className="reg reg-47" onClick={() => loadInfosDept("47", "")} data-uk-tooltip={`title: ${data.find((el: "47") => el.department === "47")?.name}; cls: tooltip-map; animation: uk-animation-scale-up;`} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M220.32 424l10.48-4.47 4.03 2.04 8.1-4.85 3.23-6.83-2.42-2.86 1.6-2.81 6.06-.4-2.83-8.09 2.61-4.07-8.76-5.78-17.64.39-6.8-4.06-6.72 12.92-5.67 17.14 10.72 3.04-4.27 8.98 5.46-2.31z" />
                  <path className="reg reg-33" onClick={() => loadInfosDept("33", "")} data-uk-tooltip={`title: ${data.find((el: "33") => el.department === "33")?.name}; cls: tooltip-map; animation: uk-animation-scale-up;`} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M178.74 398.36l7.88-.61 11.92 10.11-1.62 4.41h8.67l5.67-17.14 6.72-12.92-5.71-3.44v-15.71l-13.73-4.84-7.25-8.9-7.72-.74 1.67 11.66 5.66 11.27-6.48-7.25-4.44-14.5-9.28-10.11-2.43 2.43-5.22 45.18 4.84-4.03v5.65l-3.65 3.63-.86 5.05 11.15-2.59z" />
                  <path className="reg reg-40" onClick={() => loadInfosDept("40", "")} data-uk-tooltip={`title: ${data.find((el: "40") => el.department === "40")?.name}; cls: tooltip-map; animation: uk-animation-scale-up;`} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M169.28 450.22l6.67-4.45 22.59 1.44v-8.7l3.23-1.2v-9.71l5.26-1.19 5.01-2.12 4.27-8.98-10.72-3.04h-8.67l1.62-4.41-11.92-10.11-7.88.61-4.21-3.39-11.15 2.59-6.01 34.52-4.83 8.87v3.63l-1.3 1.46 14.83.03z" />
                  <path className="reg reg-64" onClick={() => loadInfosDept("64", "")} data-uk-tooltip={`title: ${data.find((el: "64") => el.department === "64")?.name}; cls: tooltip-map; animation: uk-animation-scale-up;`} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M196.32 475.25l5.45-2.02 6.05-10.89-2.02-2.82.42-13.35-7.68-3.65v4.69l-22.59-1.44-6.67 4.45-3.21-4.15-14.83-.03-9.59 10.64 6.06 4.84 4.44-1.21 2.39 4.83-2.39 6.49 10.07 2.01 5.67 5.23 12.09 1.22 5.26 8.07 11.01-3.23z" />
                  <path className="reg reg-24" onClick={() => loadInfosDept("24", "")} data-uk-tooltip={`title: ${data.find((el: "24") => el.department === "24")?.name}; cls: tooltip-map; animation: uk-animation-scale-up;`} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M242.42 385.88l8.76 5.78 3.88-6 4.41-.41 6.07-9.25V363.06l-4.86-1.59-1.6-14.95-6.86-5.64-1.6-4.86h-7.69l-.81-5.25-7.29-.42-3.59 2.86-2.04 8.05-7.25 4.84-1.2 7.28-8.09 5.27-.39 4.41v15.71l5.71 3.44 6.8 4.06z" />
                  <path className="reg reg-03" onClick={() => loadInfosDept("03", "")} data-uk-tooltip={`title: ${data.find((el: "03") => el.department === "03")?.name}; cls: tooltip-map; animation: uk-animation-scale-up;`} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M334.54 275.48l-4.82 2.02-4.46-5.26h-6.07l-2.42 2.82h-3.21l-5.67 5.68v6.44l-6.83-.78-4.45 6.05 9.65 10.08 1.44 6.89 12.72-8.09 5.25 7.66 13.33.79 10.88 7.28 4.04-3.66.81-11.68 5.63-6.06-2-6.46-8.48-4.84-4.45-9.66-4.44 4.01z" />
                  <path className="reg reg-63" onClick={() => loadInfosDept("63", "")} data-uk-tooltip={`title: ${data.find((el: "63") => el.department === "63")?.name}; cls: tooltip-map; animation: uk-animation-scale-up;`} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M325.67 308.99l-5.25-7.66-12.72 8.09 1.41 6.83-7.29 6.88 4.44 5.62-1.19 2.02v6.05l2.04 2.02v5.67l21.79 6.44 5.64-5.67 23.39 1.62 3.24-4.05-10.9-16.91 2.04-6.06-2.43-2.82-10.88-7.28z" />
                  <path className="reg reg-15" onClick={() => loadInfosDept("15", "")} data-uk-tooltip={`title: ${data.find((el: "15") => el.department === "15")?.name}; cls: tooltip-map; animation: uk-animation-scale-up;`} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M334.54 355.8l-5.64-4.85-21.79-6.44-7.68 3.63v4.44l-4.86 5.24v5.24l-3.24 2.84v8.86l2.47 2.42 1.19 12.91h10.48l10.88-16.16 4.46 3.25 2.83 8.07 5.26-9.25h2.81l4.84-4.49 1.22-9.27z" />
                  <path className="reg reg-43" onClick={() => loadInfosDept("43", "")} data-uk-tooltip={`title: ${data.find((el: "43") => el.department === "43")?.name}; cls: tooltip-map; animation: uk-animation-scale-up;`} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M374.06 346.9h-16.13l-23.39-1.62-5.64 5.67 5.64 4.85 3.23 6.44-1.22 9.27 2.84.43 2.83 4.84h9.67l7.28 6.07 3.61-9.31h6.07l7.25-8.06v-6.47h3.63v-4.85l-4.41-2.01z" />
                  <path className="reg reg-79" onClick={() => loadInfosDept("79", "")} data-uk-tooltip={`title: ${data.find((el: "79") => el.department === "79")?.name}; cls: tooltip-map; animation: uk-animation-scale-up;`} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M220.75 310.22l.39-4.88-8.48-12.5 3.22-10.87-3.22-13.34 2.42-5.66-3.61-8.47h-13.73l-6.45 5.25-8.48-1.63 8.48 19.38-1.23 11.31 6.06 6.06h-8.9l1.64 7.25 14.52 5.64 6.86 7.66 3.65-6.43z" />
                  <path className="reg reg-86" onClick={() => loadInfosDept("86", "")} data-uk-tooltip={`title: ${data.find((el: "86") => el.department === "86")?.name}; cls: tooltip-map; animation: uk-animation-scale-up;`} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M246.96 299.28h4.44l6.86-6.44-3.2-6.85h-3.66l-3.22-3.24v-7.27l-12.51-14.91-4.43 3.63h-3.65v-6.08l-9.28-8.05-6.84 4.43 3.61 8.47-2.42 5.66 3.22 13.34-3.22 10.87 8.48 12.5-.39 4.88 7.67 1.99 4.03-4.03 2.8 2.04 7.89-5.27v-1.83z" />
                  <path className="reg reg-17" onClick={() => loadInfosDept("17", "")} data-uk-tooltip={`title: ${data.find((el: "17") => el.department === "17")?.name}; cls: tooltip-map; animation: uk-animation-scale-up;`} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M198.54 358.22l13.73 4.84.39-4.41-7.07-8.11-5.43-1.22 3.03-8.65-5.89-14.73 9.73-.22 3.21-10.3-6.86-7.66-14.52-5.64-1.64-7.25h-7.66l-6.83 2.81-5.67 8.88 6.43 8.08-3.61 7.26 2.03 4.44-6.09.4v4.84l8.9 4.44 8.48 9.7.37 2.86 7.72.74z" />
                  <path className="reg reg-16" onClick={() => loadInfosDept("16", "")} data-uk-tooltip={`title: ${data.find((el: "16") => el.department === "16")?.name}; cls: tooltip-map; animation: uk-animation-scale-up;`} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M221.95 346.1l7.25-4.84 2.04-8.05 3.59-2.86 8.1-10.47 5.64-2.42v-4.44h-5.43v-8.07l-7.89 5.27-2.8-2.04-4.03 4.03-7.67-1.99-6.86-1.23-3.65 6.43-3.21 10.3-9.73.22 5.89 14.73-3.03 8.65 5.43 1.22 7.07 8.11 8.09-5.27z" />
                  <path className="reg reg-12" onClick={() => loadInfosDept("12", "")} data-uk-tooltip={`title: ${data.find((el: "12") => el.department === "12")?.name}; cls: tooltip-map; animation: uk-animation-scale-up;`} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M330 395.96l-6.36-10.71-2.83-8.07-4.46-3.25-10.88 16.16h-10.48l-5.25 4.44-6.84 4.85 2 3.61-2.83 2.85 4.85 2.83.82 7.26 6.06-2.03 12.46 6.04 5.67 15.35 7.26-.39 3.35 6.86 6.27-5.35.57-3.93h6.35v-2.35l5.06-3.3v-5.97l-4.64-2.83 3.45-4.33-8.8-4.62z" />
                  <path className="reg reg-81" onClick={() => loadInfosDept("81", "")} data-uk-tooltip={`title: ${data.find((el: "81") => el.department === "81")?.name}; cls: tooltip-map; animation: uk-animation-scale-up;`} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M311.93 435.29l-5.67-15.35-12.46-6.04-6.06 2.03-2.84 2.42-5.25.4-8.44 9.28.37 4.45 4.83 7.26-.37 4.43h2l9.28 12.97 5.76 1.17 4.13-5.28 12.1 2.24 3.44-12.59 3.91 2.3 5.88-3.22-3.35-6.86z" />
                  <path className="reg reg-31" onClick={() => loadInfosDept("31", "")} data-uk-tooltip={`title: ${data.find((el: "31") => el.department === "31")?.name}; cls: tooltip-map; animation: uk-animation-scale-up;`} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M247.79 478.49l2.38-9.31 10.13 2.83 1.18-7.26 13.13-.4 6.48-8.49 6.23 1.28-9.28-12.97h-2l.37-4.43-4.83-7.26-.37-4.45-10.13 2.03-2 6.05-5.66-2.84-6.46 1.23 10.09 10.48-8.48 4.03-.39 10.51-5.25-2.84-5.26 3.63-10.47 7.29 4.84 9.26-4.84 6.85-.3 8.98 7.16 1.11-1.61-6.04 2.38-2.41 7.72 2.34-.43-5.16z" />
                  <path className="reg reg-65" onClick={() => loadInfosDept("65", "")} data-uk-tooltip={`title: ${data.find((el: "65") => el.department === "65")?.name}; cls: tooltip-map; animation: uk-animation-scale-up;`} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M232.04 476.86l-4.84-9.26 10.47-7.29-16.92-4.03-4.87 1.59.43-5.21-6.07-7.68-4.02 1.19-.42 13.35 2.02 2.82-6.05 10.89-5.45 2.02-.07 9.68 7.13 8.08 7.66 1.6 2.42-1.6 4.44 1.2 5.66-2.03 3.34.51.3-8.98z" />
                  <path className="reg reg-32" onClick={() => loadInfosDept("32", "")} data-uk-tooltip={`title: ${data.find((el: "32") => el.department === "32")?.name}; cls: tooltip-map; animation: uk-animation-scale-up;`} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M234.83 421.57l-4.03-2.04-10.48 4.47-2.82-2.02-5.46 2.31-5.01 2.12-5.26 1.19v9.71l-3.23 1.2v4.01l7.68 3.65 4.02-1.19 6.07 7.68-.43 5.21 4.87-1.59 16.92 4.03 5.26-3.63 5.25 2.84.39-10.51 8.48-4.03-10.09-10.48-3.22-5.67z" />
                  <path className="reg reg-82" onClick={() => loadInfosDept("82", "")} data-uk-tooltip={`title: ${data.find((el: "82") => el.department === "82")?.name}; cls: tooltip-map; animation: uk-animation-scale-up;`} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M284.9 418.35l2.84-2.42-.82-7.26-4.85-2.83h-6.85l-4.01 4.05-4.07-2.45-5.22 3.66-10.52-7.28-6.06.4-1.6 2.81 2.42 2.86-3.23 6.83-8.1 4.85 8.91 7.26 3.22 5.67 6.46-1.23 5.66 2.84 2-6.05 10.13-2.03 8.44-9.28z" />
                  <path className="reg reg-09" onClick={() => loadInfosDept("09", "")} data-uk-tooltip={`title: ${data.find((el: "09") => el.department === "09")?.name}; cls: tooltip-map; animation: uk-animation-scale-up;`} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M293.46 495.73l-2.22-5.35-6.47.99-3-6.32 3.85-.52-.52-4.34 1.82-1.22-2.83-8.35-9.48-6.27-13.13.4-1.18 7.26-10.13-2.83-2.38 9.31-5.67 4.04.43 5.16 19.75 6.11 4.03 4.85 6.07.39 3.22 4.84 10.18-6.13z" />
                  <path className="reg reg-66" onClick={() => loadInfosDept("66", "")} data-uk-tooltip={`title: ${data.find((el: "66") => el.department === "66")?.name}; cls: tooltip-map; animation: uk-animation-scale-up;`} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M315.16 486.94l-13.69-.39v7.25l-8.01 1.93-7.66 2.02-10.18 6.13 13.31 8.09 9.71-2.42 12.9 4.86 3.21-4.86 11.71-2.42 5.64 2.84v-5.69l-3.61-2.4-.38-16.13-6.88-3.22z" />
                  <path className="reg reg-11" onClick={() => loadInfosDept("11", "")} data-uk-tooltip={`title: ${data.find((el: "11") => el.department === "11")?.name}; cls: tooltip-map; animation: uk-animation-scale-up;`} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M317.17 464.75l-8.06-4.85.2-4.63-12.1-2.24-4.13 5.28-5.76-1.17-6.23-1.28-6.48 8.49 9.48 6.27 2.83 8.35-1.82 1.22.52 4.34-3.85.52 3 6.32 6.47-.99 2.22 5.35 8.01-1.93v-7.25l13.69.39 6.07-4.41 6.88 3.22-.05-2.42 4.45-4.84-2.03-5.26 6.66-5.92-16.72-7.79z" />
                  <path className="reg reg-34" onClick={() => loadInfosDept("34", "")} data-uk-tooltip={`title: ${data.find((el: "34") => el.department === "34")?.name}; cls: tooltip-map; animation: uk-animation-scale-up;`} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M354.73 425.2l-7.29 4.42-6.65-2.79-5.06 3.3v2.35h-6.35l-.57 3.93-6.27 5.35-5.88 3.22-3.91-2.3-3.44 12.59-.2 4.63 8.06 4.85 3.25-5.23 16.72 7.79 4.27-3.77 2 1.21 6.06-8.47 4.03 2.42 12.93-9.69 3.22-11.29z" />
                  <path className="reg reg-30" onClick={() => loadInfosDept("30", "")} data-uk-tooltip={`title: ${data.find((el: "30") => el.department === "30")?.name}; cls: tooltip-map; animation: uk-animation-scale-up;`} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M386.18 432.48l6-5.32 4.93-4.38-8.09-14.52-12.11-5.27-3.62 4.45-6.06-4.02-1.62-6.86-5.67 4.84 2.84 10.87-6.45 4.06-6.86-3.23-9.87.6-3.45 4.33 4.64 2.83v5.97l6.65 2.79 7.29-4.42 14.92 12.52-3.22 11.29 3.22 4.84 3.64-.21 11.7-12.31z" />
                  <path className="reg reg-48" onClick={() => loadInfosDept("48", "")} data-uk-tooltip={`title: ${data.find((el: "48") => el.department === "48")?.name}; cls: tooltip-map; animation: uk-animation-scale-up;`} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M359.17 382.85l-7.28-6.07h-9.67l-2.83-4.84-2.84-.43-4.84 4.49h-2.81l-5.26 9.25 6.36 10.71.8 13.12 8.8 4.62 9.87-.6 6.86 3.23 6.45-4.06-2.84-10.87 5.67-4.84-6.04-6.87z" />
                  <path className="reg reg-04" onClick={() => loadInfosDept("04", "")} data-uk-tooltip={`title: ${data.find((el: "04") => el.department === "04")?.name}; cls: tooltip-map; animation: uk-animation-scale-up;`} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M461.26 392.51l-10.9-1.59-9.7 4.05-7.24 8.45 3.2 6.03-9.26-.78-8.07 5.63 5.65 4.05 1.19 10.48 6.47 7.28 6.86-2.01 3.22 2.39 6.08-5.64 4.84 1.63 4.43-4.05h6.44l3.23-4.84 6.88-.81-1.23-4.84-3.6-.41-5.28-11.29 6.07-6.07 3.69-15.8-4.91-2.35z" />
                  <path className="reg reg-05" onClick={() => loadInfosDept("05", "")} data-uk-tooltip={`title: ${data.find((el: "05") => el.department === "05")?.name}; cls: tooltip-map; animation: uk-animation-scale-up;`} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M451.96 360.64l-4.83-2.82-2.03 5.24 4.84 5.67v5.2l-5.64-1.99-12.12 5.24-3.61 3.62-3.63 3.64-3.24 9.7-5.26-.4 1.64 7.66 6.04 1.59 3.24 5.68 9.26.78-3.2-6.03 7.24-8.45 9.7-4.05 10.9 1.59 8.06-10.49 4.91 2.35 1.15-4.77-14.12-20.59-6.43-.36z" />
                  <path className="reg reg-06" onClick={() => loadInfosDept("06", "")} data-uk-tooltip={`title: ${data.find((el: "06") => el.department === "06")?.name}; cls: tooltip-map; animation: uk-animation-scale-up;`} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M485.05 435.7l13.75-5.25v-7.67l-2.82-2.02 6.83-12.5-5.22-3.65-6.87 4.47-20.18-8.91-6.07 6.07 5.28 11.29 3.6.41 1.23 4.84-6.88.81-3.23 4.84 4.46 7.68 8.87 9.26 7.25-4.04z" />
                  <path className="reg reg-83" onClick={() => loadInfosDept("83", "")} data-uk-tooltip={`title: ${data.find((el: "83") => el.department === "83")?.name}; cls: tooltip-map; animation: uk-animation-scale-up;`} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M464.47 428.43h-6.44l-4.43 4.05-4.84-1.63-6.08 5.64-3.22-2.39-6.86 2.01-4.44 10.48 2.83 5.65-3.63 4.44 4.44 3.63-4.84 6.06 11.28 6.46 2.03-4.43 4.83 2.39 4.84-4.04 5.26 2.43 12.13-6.07v-6.43l10.08-6.46.39-4.85-8.87-9.26z" />
                  <path className="reg reg-84" onClick={() => loadInfosDept("84", "")} data-uk-tooltip={`title: ${data.find((el: "84") => el.department === "84")?.name}; cls: tooltip-map; animation: uk-animation-scale-up;`} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M424.94 418.35l-5.65-4.05-14.12-5.63v-4.06l-3.65.4 1.2-6.03-3.22-2.42-2.81 4.44 1.22 4.41-2.04 1.24-4.43-2.04-2.42 3.65 8.09 14.52-4.93 4.38 14.18 7.74 9.71.39 9.25 4.45 7.28-3.63-6.47-7.28z" />
                  <path className="reg reg-13" onClick={() => loadInfosDept("13", "")} data-uk-tooltip={`title: ${data.find((el: "13") => el.department === "13")?.name}; cls: tooltip-map; animation: uk-animation-scale-up;`} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M427.36 456.68l3.63-4.44-2.83-5.65 4.44-10.48-7.28 3.63-9.25-4.45-9.71-.39-14.18-7.74-6 5.32-1.19 8.85-11.7 12.31 11.7-.61 3.61 5.67h6.09l2.42-5.26 8.06 6.87 8.48-3.63 3.22 2.02v6.05l10.09 1.62 4.84-6.06z" />
                  <path className="reg reg-07" onClick={() => loadInfosDept("07", "")} data-uk-tooltip={`title: ${data.find((el: "07") => el.department === "07")?.name}; cls: tooltip-map; animation: uk-animation-scale-up;`} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M387 394.97l4.44-9.72 4.04-9.67-4.45-14.94.8-8.87-3.23-4.04-8.87 6.43v4.85h-3.63v6.47l-7.25 8.06h-6.07l-3.61 9.31.4 6.84 6.04 6.87 1.62 6.86 6.06 4.02 3.62-4.45 12.11 5.27 2.42-3.65z" />
                  <path className="reg reg-26" onClick={() => loadInfosDept("26", "")} data-uk-tooltip={`title: ${data.find((el: "26") => el.department === "26")?.name}; cls: tooltip-map; animation: uk-animation-scale-up;`} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M420.1 377.18l-2.43-15.71-10.51 1.59-1.99-9.29-8.89-5.22-4.45 3.22-.8 8.87 4.45 14.94-4.04 9.67-4.44 9.72 4.44 9.64 4.43 2.04 2.04-1.24-1.22-4.41 2.81-4.44 3.22 2.42-1.2 6.03 3.65-.4v4.06l14.12 5.63 8.07-5.63-3.24-5.68-6.04-1.59-1.64-7.66 5.26.4 3.24-9.7 3.63-3.64z" />
                  <path className="reg reg-38" onClick={() => loadInfosDept("38", "")} data-uk-tooltip={`title: ${data.find((el: "38") => el.department === "38")?.name}; cls: tooltip-map; animation: uk-animation-scale-up;`} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M395.09 335.63l-1.24 6.45-3.2.41-2.05 5.24 3.23 4.04 4.45-3.22 8.89 5.22 1.99 9.29 10.51-1.59 2.43 15.71 8.47 3.62 3.61-3.62 12.12-5.24 5.64 1.99v-5.2l-4.84-5.67 2.03-5.24-5.25-1.63 1.2-12.51-8.48-4.03-2 3.2-9.28-2.79.43-6.85L412 319.08l-10.48 5.64 1.66 4.03z" />
                  <path className="reg reg-42" onClick={() => loadInfosDept("42", "")} data-uk-tooltip={`title: ${data.find((el: "42") => el.department === "42")?.name}; cls: tooltip-map; animation: uk-animation-scale-up;`} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M385.79 338.44l.39-2.42h-5.65l-6.04-5.25 1.61-6.05-5.23-15.33 4.45-2.83-.83-3.21-11.71-1.63-2.42-6.06-5.63 6.06-.81 11.68-4.04 3.66 2.43 2.82-2.04 6.06 10.9 16.91-3.24 4.05h16.13l1.26 5.25 4.41 2.01 8.87-6.43 2.05-5.24z" />
                  <path className="reg reg-69" onClick={() => loadInfosDept("69", "")} data-uk-tooltip={`title: ${data.find((el: "69") => el.department === "69")?.name}; cls: tooltip-map; animation: uk-animation-scale-up;`} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M378.5 298.89l-4.44-1.6.43 6.06.83 3.21-4.45 2.83 5.23 15.33-1.61 6.05 6.04 5.25h5.65l-.39 2.42 4.86 4.05 3.2-.41 1.24-6.45 8.09-6.88-1.66-4.03-6.04-1.23-2.82-6.43-4.88.4-1.19-6.86 2.83-5.26-1.19-4.84-5.27-4.41z" />
                  <path className="reg reg-01" onClick={() => loadInfosDept("01", "")} data-uk-tooltip={`title: ${data.find((el: "01") => el.department === "01")?.name}; cls: tooltip-map; animation: uk-animation-scale-up;`} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M424.12 296.46l-.8-3.22-3.22-.4-4.85 4.84-7.24-9.27-7.28-3.25-5.64 1.62-6.86 13.72 1.19 4.84-2.83 5.26 1.19 6.86 4.88-.4 2.82 6.43 6.04 1.23 10.48-5.64 11.75 14.13 1.57-17.79 2.04-8.45 6.85-6.47.28-10.1-6.33 6.06z" />
                  <path className="reg reg-74" onClick={() => loadInfosDept("74", "")} data-uk-tooltip={`title: ${data.find((el: "74") => el.department === "74")?.name}; cls: tooltip-map; animation: uk-animation-scale-up;`} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M425.32 315.42l21.41 12.92 7.65-14.52 6.07 9.31 6.88-2.04 4.82-5.25-4.45-8.87-2.42.79-2.02-15.31-3.24-3.25-15.33 3.25-6.84 8.45-3.64-.4-6.85 6.47z" />
                  <path className="reg reg-73" onClick={() => loadInfosDept("73", "")} data-uk-tooltip={`title: ${data.find((el: "73") => el.department === "73")?.name}; cls: tooltip-map; animation: uk-animation-scale-up;`} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M454.38 313.82l-7.65 14.52-21.41-12.92-1.57 17.79-.43 6.85 9.28 2.79 2-3.2 8.48 4.03-1.2 12.51 5.25 1.63 4.83 2.82 2.87-1.99 6.43.36 12.09-6.03 5.25-12.1-7.66-15.33-3.24-1.23-.37-3.23-6.88 2.04z" />
                  <path className="reg reg-87" onClick={() => loadInfosDept("87", "")} data-uk-tooltip={`title: ${data.find((el: "87") => el.department === "87")?.name}; cls: tooltip-map; animation: uk-animation-scale-up;`} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M281.25 323.49l-7.24-2.82-6.05-15.33-3.24-1.6 3.63-4.85-2.18-4.26-7.91-1.79-6.86 6.44h-4.44l-3.82 3.84v9.9h5.43v4.44l-5.64 2.42-8.1 10.47 7.29.42.81 5.25h7.69l1.6 4.86 6.86 5.64 24.2-15.75z" />
                  <path className="reg reg-23" onClick={() => loadInfosDept("23", "")} data-uk-tooltip={`title: ${data.find((el: "23") => el.department === "23")?.name}; cls: tooltip-map; animation: uk-animation-scale-up;`} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M301.82 323.13l7.29-6.88-1.41-6.83-1.44-6.89-9.65-10.08-2.81-.1-4.27-.1-1.79-1.43-2.42 1.21-6.69-1.42-1.19 3.82-9.88-1.79-1.39 1.99 2.18 4.26-3.63 4.85 3.24 1.6 6.05 15.33 7.24 2.82 2.03 7.28 6.86-2.84 9.29 3.65 5.64-.81 1.19-2.02z" />
                  <path className="reg reg-19" onClick={() => loadInfosDept("19", "")} data-uk-tooltip={`title: ${data.find((el: "19") => el.department === "19")?.name}; cls: tooltip-map; animation: uk-animation-scale-up;`} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M279.65 370.74h11.68v-4.84l3.24-2.84v-5.24l4.86-5.24v-4.44l7.68-3.63v-5.67l-2.04-2.02v-6.05l-5.64.81-9.29-3.65-6.86 2.84-24.2 15.75 1.6 14.95 4.86 1.59v3.23h8.09z" />
                  <path className="reg reg-44" onClick={() => loadInfosDept("44", "")} data-uk-tooltip={`title: ${data.find((el: "44") => el.department === "44")?.name}; cls: tooltip-map; animation: uk-animation-scale-up;`} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M158.19 258.12l6.86 4.04 4.41-7.66 2.04-14.51 10.5-.83-2.02-12.91-4.84-2.02-1.65-7.26-3.18.41-3.65-2.85-8.05 2.44-.81 4.02-8.9-1.59-8.05 3.23v5.64l-14.55 4.04-3.22 5.24v5.26l6.07 3.19 6.82-4.02 9.69 3.24-8.85 1.22-3.65 4.44 10.11 7.24 14.1 5.26z" />
                  <path className="reg reg-49" onClick={() => loadInfosDept("49", "")} data-uk-tooltip={`title: ${data.find((el: "49") => el.department === "49")?.name}; cls: tooltip-map; animation: uk-animation-scale-up;`} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M197.74 254.5h13.73l6.84-4.43v-4.44l4.86-11.69v-6.47l-21.78-10.5-7.71 2.43-20.19-2.43 1.65 7.26 4.84 2.02 2.02 12.91-10.5.83-2.04 14.51 13.35 3.62 8.48 1.63z" />
                  <path className="reg reg-53" onClick={() => loadInfosDept("53", "")} data-uk-tooltip={`title: ${data.find((el: "53") => el.department === "53")?.name}; cls: tooltip-map; animation: uk-animation-scale-up;`} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M201.39 216.97l2.41-10.1 5.65-2.8-.81-6.48 4.02-4.04-1.19-4.83 3.22-5.24-3.65-3.63v-5.68h-4.82l-10.91 7.3-4.02-1.62-2.83 1.62-3.22-4.85h-6.87l1.61 10.49-2.43 2.02v8.08l3.64 3.23-2.45 6.43-5.25 2.45v7.65l20.19 2.43z" />
                  <path className="reg reg-72" onClick={() => loadInfosDept("72", "")} data-uk-tooltip={`title: ${data.find((el: "72") => el.department === "72")?.name}; cls: tooltip-map; animation: uk-animation-scale-up;`} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M233.23 227.07l4.44-2.84 8.49-14.91-1.19-6.06 4.44-2.82-4.07-5.67-15.72-7.23.42-5.27-8.09-2.04v3.25h-7.26l-3.22 5.24 1.19 4.83-4.02 4.04.81 6.48-5.65 2.8-2.41 10.1 21.78 10.5 7.63-2.82z" />
                  <path className="reg reg-85" onClick={() => loadInfosDept("85", "")} data-uk-tooltip={`title: ${data.find((el: "85") => el.department === "85")?.name}; cls: tooltip-map; animation: uk-animation-scale-up;`} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M187.22 294.87h8.9l-6.06-6.06 1.23-11.31-8.48-19.38-13.35-3.62-4.41 7.66-6.86-4.04-.82 5.26-14.1-5.26-8.06 8.88 8.47 7.7.39 11.7 20.98 12.08 7.68-.8 6.83-2.81z" />
                  <path className="reg reg-22" onClick={() => loadInfosDept("22", "")} data-uk-tooltip={`title: ${data.find((el: "22") => el.department === "22")?.name}; cls: tooltip-map; animation: uk-animation-scale-up;`} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M89.58 179.04l.81 8.86 12.09 5.65 4.45-6.01 13.72 8.45 3.22 4.84 3.24-8.48 5.26 3.64 3.6-2.44 12.93-8.86-1.19-9.3-13.74-6.43-7.27 1.19-3.23 5.24h-2.42l-12.48-20.95-17.37 3.21-1.18 5.24 1.99 8.09z" />
                  <path className="reg reg-56" onClick={() => loadInfosDept("56", "")} data-uk-tooltip={`title: ${data.find((el: "56") => el.department === "56")?.name}; cls: tooltip-map; animation: uk-animation-scale-up;`} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M140.85 222.63l3.22-8.5-1.62-9.28-6.48-11.3-3.6 2.44-5.26-3.64-3.24 8.48-3.22-4.84-13.72-8.45-4.45 6.01-12.09-5.65-6.05 7.67 11.69 8.5-7.76 11.52 8.58 2.61 5.24 11.69 3.61-3.23 7.29 2.41 13.31 3.24 14.55-4.04z" />
                  <path className="reg reg-29" onClick={() => loadInfosDept("29", "")} data-uk-tooltip={`title: ${data.find((el: "29") => el.department === "29")?.name}; cls: tooltip-map; animation: uk-animation-scale-up;`} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M84.34 195.57l6.05-7.67-.81-8.86 2.43-8.06-1.99-8.09-6.91-.8-.81 3.63-5.63-.41-1.21-4.04-6.49-.39-2.41 3.25-4.42-2.04L46 172.18l2 6.86 15.34.81 1.22 5.67-10.88-2.04v4.81l7.67.43-.42 4.83-11.29.42-.42 5.64 4.04-.8 5.63 7.26-.79 5.26 7.26 1.6-.4-4.82 13.34 4.41 9.97 3.07 7.76-11.52z" />
                  <path className="reg reg-35" onClick={() => loadInfosDept("35", "")} data-uk-tooltip={`title: ${data.find((el: "35") => el.department === "35")?.name}; cls: tooltip-map; animation: uk-animation-scale-up;`} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M135.97 193.55l6.48 11.3 1.62 9.28-3.22 8.5 8.05-3.23 8.9 1.59.81-4.02 8.05-2.44 3.65 2.85 3.18-.41v-7.65l5.25-2.45 2.45-6.43-3.64-3.23v-8.08l2.43-2.02-1.61-10.49-5.64-1.23-5.27 4.05-6.43-4.45-10.13-8.05-5.63 2.82 2.44 5.63 1.19 9.3z" />
                  <path className="reg reg-14" onClick={() => loadInfosDept("14", "")} data-uk-tooltip={`title: ${data.find((el: "14") => el.department === "14")?.name}; cls: tooltip-map; animation: uk-animation-scale-up;`} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M186.83 149.98l-4.83 3.23 5.65 8.88 17.75-5.23 9.29 1.59 6.06-6.43 9.29 2.82 2.41-2.82-3.64-20.2-10.5-.83-2.43 5.26-8.85 2.44-7.27-4.45-22.21-4.02-.38 3.21 8.07 6.47z" />
                  <path className="reg reg-61" onClick={() => loadInfosDept("61", "")} data-uk-tooltip={`title: ${data.find((el: "61") => el.department === "61")?.name}; cls: tooltip-map; animation: uk-animation-scale-up;`} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M191.29 179.85l4.02 1.62 10.91-7.3h4.82v5.68l3.65 3.63h7.26v-3.25l8.09 2.04-.42 5.27 15.72 7.23-1.6-8.06 7.66-4.03v-5.67l-6.43-6.03-2.85-10.1h-4.83v-2.83l-7.25-3.21-9.29-2.82-6.06 6.43-9.29-1.59-17.75 5.23 3.25 7.67-5.66 6.86 3.22 4.85z" />
                  <path className="reg reg-50" onClick={() => loadInfosDept("50", "")} data-uk-tooltip={`title: ${data.find((el: "50") => el.department === "50")?.name}; cls: tooltip-map; animation: uk-animation-scale-up;`} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M172.73 175.39l5.64 1.23h6.87l5.66-6.86-3.25-7.67-5.65-8.88 4.83-3.23-1.59-10.08-8.07-6.47-4.05-.42-2.42-11.67 3.23-4.04-1.6-3.24-7.28-2.82-2.42 5.26-7.27-2.44-2.42-2.82h-3.23l2.83 18.98 9.28 10.08-4.02 23 6.44 6.02-3.21 5.67 6.43 4.45z" />


                  <path className="reg reg-27" onClick={() => loadInfosDept("27", "")} data-uk-tooltip={`title: ${data.find((el: "27") => el.department === "27")?.name}; cls: tooltip-map; animation: uk-animation-scale-up;`} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M230.04 154.84l7.25 3.21v2.83h4.83l2.85 10.1 3.6-6.07 14.92-2.02 6.88-7.26-1.21-5.65 4.04-1.62 2.84-6.46 4.84-3.21-3.65-8.47-8.67-3.05-14.32 10.09-12.53-7.04-12.09-.83-.81 2.43 3.64 20.2z" />
                  <path className="reg reg-76" onClick={() => loadInfosDept("76", "")} data-uk-tooltip={`title: ${data.find((el: "76") => el.department === "76")?.name}; cls: tooltip-map; animation: uk-animation-scale-up;`} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M254.24 137.26l14.32-10.09 8.67 3.05 2.83-4.46-4.02-7.24 1.63-9.92.37-2.2-6.83-10.9-6.97-.76-5.16 6.82-12.52 5.66-12.92.38-14.11 9.7.38 8.06 9.71 4.03 12.09.83z" />
                  <path className="reg reg-67" onClick={() => loadInfosDept("67", "")} data-uk-tooltip={`title: ${data.find((el: "67") => el.department === "67")?.name}; cls: tooltip-map; animation: uk-animation-scale-up;`} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M463.68 148.77l-3.66 2.85v4.22l12.13 8.48-.22 7.03-5.83 4.04 2.62 12.9 13.91 7.7 9.31-28.26 11.67-15.71-9.67-6.91-10.08 1.23-7.08 6.26z" />
                  <path className="reg reg-68" onClick={() => loadInfosDept("68", "")} data-uk-tooltip={`title: ${data.find((el: "68") => el.department === "68")?.name}; cls: tooltip-map; animation: uk-animation-scale-up;`} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M459.05 211.11l6.23 4.66 2.84 7.26-.79 6.86 2.42-1.19 5.63 4.01 3.64.4 6.44-8.88-2.42-6.49-.41-21.75-13.91-7.7z" />
                  <path className="reg reg-10" onClick={() => loadInfosDept("10", "")} data-uk-tooltip={`title: ${data.find((el: "10") => el.department === "10")?.name}; cls: tooltip-map; animation: uk-animation-scale-up;`} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M344.21 198.81h3.23l8.5 10.08 5.65-2.02 4.84 2.85h10.08v-4.44h6.07v-4.84l5.2-3.23v-9.67l-5.63-3.66-1.2-6.87-11.3-2.84-3.63-7.66-8.87 2.81-9.71 7.69-8.04-2.84-3.58 4.9-.09 5.62 8.1 7.66z" />
                  <path className="reg reg-52" onClick={() => loadInfosDept("52", "")} data-uk-tooltip={`title: ${data.find((el: "52") => el.department === "52")?.name}; cls: tooltip-map; animation: uk-animation-scale-up;`} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M380.95 177.01l1.2 6.87 5.63 3.66v9.67l-5.2 3.23v4.84h3.6l.82 4.83 5.23 4.42-2.4 2.85 1.61 4.02h8.89l2 3.25 7.28.4v-3.25h8.47v-6.03l5.67-5.25v-4.45l-4.89-6.06h-3.2l2.83-6.46-7.29-8.43h-4.84l-1.19-5.68-15.75-12.1-4.06 2.42z" />
                  <path className="reg reg-51" onClick={() => loadInfosDept("51", "")} data-uk-tooltip={`title: ${data.find((el: "51") => el.department === "51")?.name}; cls: tooltip-map; animation: uk-animation-scale-up;`} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M338.57 168.13l.83 6.04 8.04 2.84 9.71-7.69 8.87-2.81 3.63 7.66 11.3 2.84 4.41-7.25 4.06-2.42 1.23-2.43-3.65-3.23 1.6-8.88h4.06l-4.88-10.06v-5.28l-8.05 1.02-5.67-3.84h-4.81l-3.86-4.42-6.64-.83-1.6 3.54-6.58-2.53-5.85 3.16v5.63l2.34 2.42-3.15 3 2.94 4.26-8.55 11.44-3.76 4.84z" />
                  <path className="reg reg-08" onClick={() => loadInfosDept("08", "")} data-uk-tooltip={`title: ${data.find((el: "08") => el.department === "08")?.name}; cls: tooltip-map; animation: uk-animation-scale-up;`} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M364.01 111.24l-7.68 9.67 2.42 8.48 6.64.83 3.86 4.42h4.81l5.67 3.84 8.05-1.02h4.05l3.65-9.66-3.25-3.24v-3.65l6.48.82 6.86-1.2-14.13-12.11-6.84-1.2V94.31l-4.44-3.25-5.67 3.63-.43 6.87-12.07 1.2.4 5.66z" />
                  <path className="reg reg-62" onClick={() => loadInfosDept("62", "")} data-uk-tooltip={`title: ${data.find((el: "62") => el.department === "62")?.name}; cls: tooltip-map; animation: uk-animation-scale-up;`} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M281.25 78.96l10.08 8.06h12.94l-2.02 5.65h12.1v2.43l10.49.4 2.83 2.01.19-5.23-1.59-3.46 3-4.22-8.46-13.72-4.22-1-4.06-6.26-9.45-1.62-6.69-9.28-4.24-8.06-13.32 4.03-7.62 4.85-1.36 24.99 8.19 2.43z" />
                  <path className="reg reg-59" onClick={() => loadInfosDept("59", "")} data-uk-tooltip={`title: ${data.find((el: "59") => el.department === "59")?.name}; cls: tooltip-map; animation: uk-animation-scale-up;`} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M303.08 62l9.45 1.62 4.06 6.26 4.22 1 8.46 13.72-3 4.22 1.59 3.46-.19 5.23 15.34-2.01 15.35 3.22 2.43-14.92-4.85-3.24-10.88 1.62-.85-8.06-6.87-6.05-3.21 5.65-4.86-4.85V60.8l-2.81-5.26-10.9 5.66-6.84-7.66-2.03-13.33-14.54 4.45 4.24 8.06z" />
                  <path className="reg reg-60" onClick={() => loadInfosDept("60", "")} data-uk-tooltip={`title: ${data.find((el: "60") => el.department === "60")?.name}; cls: tooltip-map; animation: uk-animation-scale-up;`} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M291.76 141.09l14.93 4.02 8.03 2.98 6.91-.44 4.99-2.41-3.99-10.6 7.09-11.3-3.85-4.22 1.43-6.07-11.53 2.63-4.83 5.66-6.47-4.04-17.33-4.67-2.24 3.24-7.23-7.27-1.63 9.92 4.02 7.24-2.83 4.46 3.65 8.47-4.84 3.21 11.7-.81z" />
                  <path className="reg reg-80" onClick={() => loadInfosDept("80", "")} data-uk-tooltip={`title: ${data.find((el: "80") => el.department === "80")?.name}; cls: tooltip-map; animation: uk-animation-scale-up;`} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M278.04 106.4l-.37 2.2 7.23 7.27 2.24-3.24 17.33 4.67 6.47 4.04 4.83-5.66 11.53-2.63-1.87-6.05 2.24-7.07v-2.42l-2.83-2.01-10.49-.4v-2.43h-12.1l2.02-5.65h-12.94l-10.08-8.06-3.21 2-8.19-2.43-.27 5.27 3.23 4.04-4.85 2-3.72 4.9 6.97.76z" />
                  <path className="reg reg-02" onClick={() => loadInfosDept("02", "")} data-uk-tooltip={`title: ${data.find((el: "02") => el.department === "02")?.name}; cls: tooltip-map; animation: uk-animation-scale-up;`} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M327.67 97.51v2.42l-2.24 7.07 1.87 6.05-1.43 6.07 3.85 4.22-7.09 11.3 3.99 10.6 11.68 15.07 8.55-11.44-2.94-4.26 3.15-3-2.34-2.42v-5.63l5.85-3.16 6.58 2.53 1.6-3.54-2.42-8.48 7.68-9.67-1.62-2.82-.4-5.66-3.63-4.04-15.35-3.22z" />
                  <path className="reg reg-88" onClick={() => loadInfosDept("88", "")} data-uk-tooltip={`title: ${data.find((el: "88") => el.department === "88")?.name}; cls: tooltip-map; animation: uk-animation-scale-up;`} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M418.49 193.55l-2.83 6.46h3.2l4.89 6.06 6.83-3.21 7.66 4.44 4.44-1.62 4.45 2.81 4.83-2.42 7.09 5.04 9.67-22.82-2.62-12.9-8.88 5.87-8.46-4.02-1.44 3.39-10.28-1-9.3 4.66-4.62-9.88h-3.83l-9.49 6.85-3.44 3.86h4.84z" />
                  <path className="reg reg-57" onClick={() => loadInfosDept("57", "")} data-uk-tooltip={`title: ${data.find((el: "57") => el.department === "57")?.name}; cls: tooltip-map; animation: uk-animation-scale-up;`} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M425.11 144.53l1.85 9.28h10.51l.57 6.07 11.3 3.42 5.27 5.45 8.87 1.6 2.62 5.04 5.83-4.04.22-7.03-12.13-8.48v-4.22l3.66-2.85 13.1 3.83 7.08-6.26-11.71-7.65-3.63 2.81-20.57-2.81-4.45-11.71-15.34-3.64-.8 2.84h-4.43l3.82 10.5z" />
                  <path className="reg reg-54" onClick={() => loadInfosDept("54", "")} data-uk-tooltip={`title: ${data.find((el: "54") => el.department === "54")?.name}; cls: tooltip-map; animation: uk-animation-scale-up;`} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M413.23 127.6l.79 5.41 7.09 10.72-.82 8.07-2.62 4.23 1.62 3.85-2.24 3.42 2.24 11.11h3.83l4.62 9.88 9.3-4.66 10.28 1 1.44-3.39 8.46 4.02 8.88-5.87-2.62-5.04-8.87-1.6-5.27-5.45-11.3-3.42-.57-6.07h-10.51l-1.85-9.28 1.64-7.85-3.82-10.5-8.48-6.46-8.88.81 1.42 9.07z" />
                  <path className="reg reg-55" onClick={() => loadInfosDept("55", "")} data-uk-tooltip={`title: ${data.find((el: "55") => el.department === "55")?.name}; cls: tooltip-map; animation: uk-animation-scale-up;`} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M392.23 120.91v3.65l3.25 3.24-3.65 9.66h-4.05v5.28l4.88 10.06h-4.06l-1.6 8.88 3.65 3.23-1.23 2.43 15.75 12.1 1.19 5.68 3.44-3.86 9.49-6.85-2.24-11.11 2.24-3.42-1.62-3.85 2.62-4.23.82-8.07-7.09-10.72-.79-5.41-6.24 2-1.42-9.07-6.86 1.2z" />
                  <path className="reg reg-78" onClick={() => loadInfosDept("78", "")} data-uk-tooltip={`title: ${data.find((el: "78") => el.department === "78")?.name}; cls: tooltip-map; animation: uk-animation-scale-up;`} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M274.01 171.78h3.22l2.83 8.85h4.03l6.25-4.41v-6.64l3.31-3.53 1.55-9.26-5.61.41-13.34-3.18-3.05-5.66-4.04 1.62 1.21 5.65 3.64 6.05z" />
                  <path className="reg reg-95" onClick={() => loadInfosDept("95", "")} data-uk-tooltip={`title: ${data.find((el: "95") => el.department === "95")?.name}; cls: tooltip-map; animation: uk-animation-scale-up;`} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M289.59 157.2l5.61-.41 3.15-1.97 5.17-2.37 5.21.09-2.04-7.43-14.93-4.02h-4.02l-11.7.81-2.84 6.46 3.05 5.66z" />


                  {/* <path className="reg reg-" onClick={() => loadInfosDept("27", "")} data-uk-tooltip={`title: ${data.find((el: "27") => el.department === "27")?.name}; cls: tooltip-map; animation: uk-animation-scale-up;`} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M298.35 154.82l3.57-1.64" />
                  <path className="reg reg-" onClick={() => loadInfosDept("27", "")} data-uk-tooltip={`title: ${data.find((el: "27") => el.department === "27")?.name}; cls: tooltip-map; animation: uk-animation-scale-up;`} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M303.52 152.45l-1.6.73" />
                  <path className="reg reg-" onClick={() => loadInfosDept("27", "")} data-uk-tooltip={`title: ${data.find((el: "27") => el.department === "27")?.name}; cls: tooltip-map; animation: uk-animation-scale-up;`} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M273.2 148.36l3.05 5.66 13.34 3.18 5.61-.41" />
                  <path className="reg reg-" onClick={() => loadInfosDept("27", "")} data-uk-tooltip={`title: ${data.find((el: "27") => el.department === "27")?.name}; cls: tooltip-map; animation: uk-animation-scale-up;`} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M303.52 152.45l-1.6.73" />
                  <path className="reg reg-" onClick={() => loadInfosDept("27", "")} data-uk-tooltip={`title: ${data.find((el: "27") => el.department === "27")?.name}; cls: tooltip-map; animation: uk-animation-scale-up;`} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M303.52 152.45l5.21.09" />
                  <path className="reg reg-" onClick={() => loadInfosDept("27", "")} data-uk-tooltip={`title: ${data.find((el: "27") => el.department === "27")?.name}; cls: tooltip-map; animation: uk-animation-scale-up;`} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M305.44 161.39l6.04-.11" />
                  <path className="reg reg-" onClick={() => loadInfosDept("27", "")} data-uk-tooltip={`title: ${data.find((el: "27") => el.department === "27")?.name}; cls: tooltip-map; animation: uk-animation-scale-up;`} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M301.92 153.18l-3.57 1.64" />
                  <path className="reg reg-" onClick={() => loadInfosDept("27", "")} data-uk-tooltip={`title: ${data.find((el: "27") => el.department === "27")?.name}; cls: tooltip-map; animation: uk-animation-scale-up;`} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M295.2 156.79l-1.55 9.26" />
                  <path className="reg reg-" onClick={() => loadInfosDept("27", "")} data-uk-tooltip={`title: ${data.find((el: "27") => el.department === "27")?.name}; cls: tooltip-map; animation: uk-animation-scale-up;`} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M295.2 156.79l3.15-1.97" />
                  <path className="reg reg-" onClick={() => loadInfosDept("27", "")} data-uk-tooltip={`title: ${data.find((el: "27") => el.department === "27")?.name}; cls: tooltip-map; animation: uk-animation-scale-up;`} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M301.82 168.83l.33-4.94" />
                  <path className="reg reg-" onClick={() => loadInfosDept("27", "")} data-uk-tooltip={`title: ${data.find((el: "27") => el.department === "27")?.name}; cls: tooltip-map; animation: uk-animation-scale-up;`} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M301.92 153.18" />
                  <path className="reg reg-" onClick={() => loadInfosDept("27", "")} data-uk-tooltip={`title: ${data.find((el: "27") => el.department === "27")?.name}; cls: tooltip-map; animation: uk-animation-scale-up;`} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M302.57 158.54l-.65-5.36" />
                  <path className="reg reg-" onClick={() => loadInfosDept("27", "")} data-uk-tooltip={`title: ${data.find((el: "27") => el.department === "27")?.name}; cls: tooltip-map; animation: uk-animation-scale-up;`} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M305.44 161.39v1.32l-3.29 1.18" />
                  <path className="reg reg-" onClick={() => loadInfosDept("27", "")} data-uk-tooltip={`title: ${data.find((el: "27") => el.department === "27")?.name}; cls: tooltip-map; animation: uk-animation-scale-up;`} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M302.57 158.54l2.87 1.25v1.6" />
                  <path className="reg reg-" onClick={() => loadInfosDept("27", "")} data-uk-tooltip={`title: ${data.find((el: "27") => el.department === "27")?.name}; cls: tooltip-map; animation: uk-animation-scale-up;`} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M301.82 168.83l6.76.73" />
                  <path className="reg reg-" onClick={() => loadInfosDept("27", "")} data-uk-tooltip={`title: ${data.find((el: "27") => el.department === "27")?.name}; cls: tooltip-map; animation: uk-animation-scale-up;`} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M293.65 166.05l5.85 2.75 2.32.03" />
                  <path className="reg reg-" onClick={() => loadInfosDept("27", "")} data-uk-tooltip={`title: ${data.find((el: "27") => el.department === "27")?.name}; cls: tooltip-map; animation: uk-animation-scale-up;`} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M306.69 145.11l2.04 7.43" />
                  <path className="reg reg-" onClick={() => loadInfosDept("27", "")} data-uk-tooltip={`title: ${data.find((el: "27") => el.department === "27")?.name}; cls: tooltip-map; animation: uk-animation-scale-up;`} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M308.58 169.56l2.9-8.28" />
                  <path className="reg reg-" onClick={() => loadInfosDept("27", "")} data-uk-tooltip={`title: ${data.find((el: "27") => el.department === "27")?.name}; cls: tooltip-map; animation: uk-animation-scale-up;`} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M308.73 152.54l2.75 8.74" />
                  */}
                  <path className="reg reg-39" onClick={() => loadInfosDept("39", "")} data-uk-tooltip={`title: ${data.find((el: "39") => el.department === "39")?.name}; cls: tooltip-map; animation: uk-animation-scale-up;`} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M439.86 268.03l-11.12-12.52h-6.02l2.39-6.44-2.39-5.44-9.07-1.24-9.71 18.96 6.07 2.85-.82 8.04 4.46 3.24-5.64 12.93 7.24 9.27 4.85-4.84 3.22.4.8 3.22h4.04l6.33-6.06.11-4.83 3.64-4.83v-3.24l-3.41-3.63z" />
                  <path className="reg reg-70" onClick={() => loadInfosDept("70", "")} data-uk-tooltip={`title: ${data.find((el: "70") => el.department === "70")?.name}; cls: tooltip-map; animation: uk-animation-scale-up;`} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M447.13 208.49l-4.45-2.81-4.44 1.62-7.66-4.44-6.83 3.21v4.45l-5.67 5.25v6.03h-8.47v3.25l2.84 2.42-3.67 6.07 4.87 8.85 9.07 1.24 22.6-16.56 4.44 2.82 2.42-3.64 7.24.41-1.97-10.11 1.6-5.44-7.09-5.04z" />
                  <path className="reg reg-90" onClick={() => loadInfosDept("90", "")} data-uk-tooltip={`title: ${data.find((el: "90") => el.department === "90")?.name}; cls: tooltip-map; animation: uk-animation-scale-up;`} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M465.28 215.77l-6.23-4.66-1.6 5.44 1.97 10.11.83 5.18 4.28 2.03 2.8-3.98.79-6.86z" />
                  <path className="reg reg-25" onClick={() => loadInfosDept("25", "")} data-uk-tooltip={`title: ${data.find((el: "25") => el.department === "25")?.name}; cls: tooltip-map; animation: uk-animation-scale-up;`} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M459.42 226.66l-7.24-.41-2.42 3.64-4.44-2.82-22.6 16.56 2.39 5.44-2.39 6.44h6.02l11.12 12.52-5.03 5.84 3.41 3.63 10.52-11.3.39-5.63 17.73-23.02-2.35-3.68-4.28-2.03z" />
                  <path className="reg reg-89" onClick={() => loadInfosDept("89", "")} data-uk-tooltip={`title: ${data.find((el: "89") => el.department === "89")?.name}; cls: tooltip-map; animation: uk-animation-scale-up;`} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M323.64 210.52v8.05l-5.23 2.04 2.4 8.85 8.91.23 5.83 5.04 6.46-3.21 16.35 11.29 4.62-1.62.81-11.3 8.48-13.52-5.84-6.65-4.84-2.85-5.65 2.02-8.5-10.08h-3.23l-.38-6.46-8.1-7.66-12.5 4.84 2.03 3.25-2.84 4.43 4.44 10.9z" />
                  <path className="reg reg-58" onClick={() => loadInfosDept("58", "")} data-uk-tooltip={`title: ${data.find((el: "58") => el.department === "58")?.name}; cls: tooltip-map; animation: uk-animation-scale-up;`} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M342.01 231.52l-6.46 3.21-5.83-5.04-8.91-.23-2.81 3.65.8 9.28 6.46 9.29v20.56l4.46 5.26 4.82-2.02 6.45 3.23 4.44-4.01 8.28 1.41 8.08-5.06.4-9.09-3.23-1.82 3.82-8.27 2.61 1.41 3.26-2.62-5.67-9.47-4.62 1.62z" />
                  <path className="reg reg-71" onClick={() => loadInfosDept("71", "")} data-uk-tooltip={`title: ${data.find((el: "71") => el.department === "71")?.name}; cls: tooltip-map; animation: uk-animation-scale-up;`} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M385.58 262.76l-16.93-12.1-3.26 2.62-2.61-1.41-3.82 8.27 3.23 1.82-.4 9.09-8.08 5.06-8.28-1.41 4.45 9.66 8.48 4.84 2 6.46 2.42 6.06 11.71 1.63-.43-6.06 4.44 1.6 4.46-2.8 5.27 4.41 6.86-13.72 5.64-1.62 7.28 3.25 5.64-12.93-4.46-3.24.82-8.04-6.07-2.85-6.03-2.8z" />
                  <path className="reg reg-37" onClick={() => loadInfosDept("37", "")} data-uk-tooltip={`title: ${data.find((el: "37") => el.department === "37")?.name}; cls: tooltip-map; animation: uk-animation-scale-up;`} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M227.59 264.2h3.65l4.43-3.63 12.51 14.91 5.04-4.03 4.46-14.73 3.8 1.83 3.46-2.82v-5.26l-3.46-6.26-5.42.6-.22-13.29-18.17-7.29-4.44 2.84-2.43-2.42-7.63 2.82v6.47l-4.86 11.69v4.44l9.28 8.05z" />
                  <path className="reg reg-36" onClick={() => loadInfosDept("36", "")} data-uk-tooltip={`title: ${data.find((el: "36") => el.department === "36")?.name}; cls: tooltip-map; animation: uk-animation-scale-up;`} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M277.44 294.43l1.19-3.82 6.69 1.42 2.42-1.21 1.79 1.43 4.27.1.38-13.24-4.44-8.06 2.62-2.02-3.81-14.92-7.67.19 5.63-6.85-13.91-.82-7.66 3.84v5.26l-3.46 2.82-3.8-1.83-4.46 14.73-5.04 4.03v7.27l3.22 3.24h3.66l3.2 6.85 7.91 1.79 1.39-1.99z" />
                  <path className="reg reg-18" onClick={() => loadInfosDept("18", "")} data-uk-tooltip={`title: ${data.find((el: "18") => el.department === "18")?.name}; cls: tooltip-map; animation: uk-animation-scale-up;`} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M288.55 254.11l3.81 14.92-2.62 2.02 4.44 8.06-.38 13.24 2.81.1 4.45-6.05 6.83.78v-6.44l5.67-5.68h3.21l2.42-2.82h6.07v-20.56l-6.46-9.29-.8-9.28-11.11-.8-12.92-8.29 1.02 7.86 2.6 6.86-5.83 1.01 2.21 4.86-7.46 2.84-5.63 6.85z" />
                  <path className="reg reg-41" onClick={() => loadInfosDept("41", "")} data-uk-tooltip={`title: ${data.find((el: "41") => el.department === "41")?.name}; cls: tooltip-map; animation: uk-animation-scale-up;`} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M246.16 209.32l-8.49 14.91 18.17 7.29.22 13.29 5.42-.6 3.46 6.26 7.66-3.84 13.91.82 7.46-2.84-2.21-4.86 5.83-1.01-2.6-6.86-1.02-7.86-13.69.21-7.47-7.86-1.23-10.1-8.65 3.05-3.03-3.64-10.49-5.24-4.44 2.82z" />
                  <path className="reg reg-45" onClick={() => loadInfosDept("45", "")} data-uk-tooltip={`title: ${data.find((el: "45") => el.department === "45")?.name}; cls: tooltip-map; animation: uk-animation-scale-up;`} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M280.28 224.23l13.69-.21 12.92 8.29 11.11.8 2.81-3.65-2.4-8.85 5.23-2.04v-8.05l3.22-2.41-4.44-10.9h-6.44l-6.87 3.23-4.04-2.85 1.62-4.41-6.42-2.75-6.89-2.89-5.23 2.82-3.85 8.65-3.05 4.84-9.67 2.42 1.23 10.1z" />
                  <path className="reg reg-28" onClick={() => loadInfosDept("28", "")} data-uk-tooltip={`title: ${data.find((el: "28") => el.department === "28")?.name}; cls: tooltip-map; animation: uk-animation-scale-up;`} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M251.4 182.68l-7.66 4.03 1.6 8.06 4.07 5.67 10.49 5.24 3.03 3.64 8.65-3.05 9.67-2.42 3.05-4.84 3.85-8.65-4.06-9.73h-4.03l-2.83-8.85h-3.22v-10.1l-3.64-6.05-6.88 7.26-14.92 2.02-3.6 6.07 6.43 6.03z" />
                  <path className="reg reg-21" onClick={() => loadInfosDept("21", "")} data-uk-tooltip={`title: ${data.find((el: "21") => el.department === "21")?.name}; cls: tooltip-map; animation: uk-animation-scale-up;`} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M412.45 227.47l-2.84-2.42-7.28-.4-2-3.25h-8.89l-1.61-4.02 2.4-2.85-5.23-4.42-.82-4.83h-9.67v4.44h-10.08l5.84 6.65-8.48 13.52-.81 11.3 5.67 9.47 16.93 12.1 12.33-4.21 6.03 2.8 9.71-18.96-4.87-8.85z" />
                  <path className="reg reg-46" onClick={() => loadInfosDept("46", "")} data-uk-tooltip={`title: ${data.find((el: "46") => el.department === "46")?.name}; cls: tooltip-map; animation: uk-animation-scale-up;`} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M291.33 374.76v-4.02h-11.68l-6.02-4.45h-8.09V376l-6.07 9.25-4.41.41-3.88 6-2.61 4.07 2.83 8.09 10.52 7.28 5.22-3.66 4.07 2.45 4.01-4.05h6.85l2.83-2.85-2-3.61 6.84-4.85 5.25-4.44-1.19-12.91z" />
                  <path className="reg reg-75" onClick={() => loadInfosDept("75", "")} data-uk-tooltip={`title: ${data.find((el: "75") => el.department === "75")?.name}; cls: tooltip-map; animation: uk-animation-scale-up;`} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M308.73 152.54l-5.21-.09-1.6.73-3.57 1.64-3.15 1.97-1.55 9.26 5.85 2.75 2.32.03 6.76.73 2.9-8.28z" />
                  <path className="reg reg-91" onClick={() => loadInfosDept("91", "")} data-uk-tooltip={`title: ${data.find((el: "91") => el.department === "91")?.name}; cls: tooltip-map; animation: uk-animation-scale-up;`} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M300.27 190.43l7.62-5.74-1.63-8.07 2.32-7.06-6.76-.73-2.32-.03-5.85-2.75-3.31 3.53v6.64l-6.25 4.41 4.06 9.73 5.23-2.82z" />
                  {/* <g>
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M314.72 148.09l-8.03-2.98 2.04 7.43 2.75 8.74-2.9 8.28-2.32 7.06 1.63 8.07-7.62 5.74 6.42 2.75-1.62 4.41 4.04 2.85 6.87-3.23h6.44l2.84-4.43-2.03-3.25 12.5-4.84.09-5.62 3.58-4.9-.83-6.04-4.03-2.98 3.76-4.84-11.68-15.07-4.99 2.41z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M220.32 424l10.48-4.47 4.03 2.04 8.1-4.85 3.23-6.83-2.42-2.86 1.6-2.81 6.06-.4-2.83-8.09 2.61-4.07-8.76-5.78-17.64.39-6.8-4.06-6.72 12.92-5.67 17.14 10.72 3.04-4.27 8.98 5.46-2.31z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M178.74 398.36l7.88-.61 11.92 10.11-1.62 4.41h8.67l5.67-17.14 6.72-12.92-5.71-3.44v-15.71l-13.73-4.84-7.25-8.9-7.72-.74 1.67 11.66 5.66 11.27-6.48-7.25-4.44-14.5-9.28-10.11-2.43 2.43-5.22 45.18 4.84-4.03v5.65l-3.65 3.63-.86 5.05 11.15-2.59z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M169.28 450.22l6.67-4.45 22.59 1.44v-8.7l3.23-1.2v-9.71l5.26-1.19 5.01-2.12 4.27-8.98-10.72-3.04h-8.67l1.62-4.41-11.92-10.11-7.88.61-4.21-3.39-11.15 2.59-6.01 34.52-4.83 8.87v3.63l-1.3 1.46 14.83.03z" />
                  <path d="M196.32 475.25l5.45-2.02 6.05-10.89-2.02-2.82.42-13.35-7.68-3.65v4.69l-22.59-1.44-6.67 4.45-3.21-4.15-14.83-.03-9.59 10.64 6.06 4.84 4.44-1.21 2.39 4.83-2.39 6.49 10.07 2.01 5.67 5.23 12.09 1.22 5.26 8.07 11.01-3.23z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M242.42 385.88l8.76 5.78 3.88-6 4.41-.41 6.07-9.25V363.06l-4.86-1.59-1.6-14.95-6.86-5.64-1.6-4.86h-7.69l-.81-5.25-7.29-.42-3.59 2.86-2.04 8.05-7.25 4.84-1.2 7.28-8.09 5.27-.39 4.41v15.71l5.71 3.44 6.8 4.06z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M334.54 275.48l-4.82 2.02-4.46-5.26h-6.07l-2.42 2.82h-3.21l-5.67 5.68v6.44l-6.83-.78-4.45 6.05 9.65 10.08 1.44 6.89 12.72-8.09 5.25 7.66 13.33.79 10.88 7.28 4.04-3.66.81-11.68 5.63-6.06-2-6.46-8.48-4.84-4.45-9.66-4.44 4.01z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M325.67 308.99l-5.25-7.66-12.72 8.09 1.41 6.83-7.29 6.88 4.44 5.62-1.19 2.02v6.05l2.04 2.02v5.67l21.79 6.44 5.64-5.67 23.39 1.62 3.24-4.05-10.9-16.91 2.04-6.06-2.43-2.82-10.88-7.28z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M334.54 355.8l-5.64-4.85-21.79-6.44-7.68 3.63v4.44l-4.86 5.24v5.24l-3.24 2.84v8.86l2.47 2.42 1.19 12.91h10.48l10.88-16.16 4.46 3.25 2.83 8.07 5.26-9.25h2.81l4.84-4.49 1.22-9.27z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M374.06 346.9h-16.13l-23.39-1.62-5.64 5.67 5.64 4.85 3.23 6.44-1.22 9.27 2.84.43 2.83 4.84h9.67l7.28 6.07 3.61-9.31h6.07l7.25-8.06v-6.47h3.63v-4.85l-4.41-2.01z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M220.75 310.22l.39-4.88-8.48-12.5 3.22-10.87-3.22-13.34 2.42-5.66-3.61-8.47h-13.73l-6.45 5.25-8.48-1.63 8.48 19.38-1.23 11.31 6.06 6.06h-8.9l1.64 7.25 14.52 5.64 6.86 7.66 3.65-6.43z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M246.96 299.28h4.44l6.86-6.44-3.2-6.85h-3.66l-3.22-3.24v-7.27l-12.51-14.91-4.43 3.63h-3.65v-6.08l-9.28-8.05-6.84 4.43 3.61 8.47-2.42 5.66 3.22 13.34-3.22 10.87 8.48 12.5-.39 4.88 7.67 1.99 4.03-4.03 2.8 2.04 7.89-5.27v-1.83z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M198.54 358.22l13.73 4.84.39-4.41-7.07-8.11-5.43-1.22 3.03-8.65-5.89-14.73 9.73-.22 3.21-10.3-6.86-7.66-14.52-5.64-1.64-7.25h-7.66l-6.83 2.81-5.67 8.88 6.43 8.08-3.61 7.26 2.03 4.44-6.09.4v4.84l8.9 4.44 8.48 9.7.37 2.86 7.72.74z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M221.95 346.1l7.25-4.84 2.04-8.05 3.59-2.86 8.1-10.47 5.64-2.42v-4.44h-5.43v-8.07l-7.89 5.27-2.8-2.04-4.03 4.03-7.67-1.99-6.86-1.23-3.65 6.43-3.21 10.3-9.73.22 5.89 14.73-3.03 8.65 5.43 1.22 7.07 8.11 8.09-5.27z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M330 395.96l-6.36-10.71-2.83-8.07-4.46-3.25-10.88 16.16h-10.48l-5.25 4.44-6.84 4.85 2 3.61-2.83 2.85 4.85 2.83.82 7.26 6.06-2.03 12.46 6.04 5.67 15.35 7.26-.39 3.35 6.86 6.27-5.35.57-3.93h6.35v-2.35l5.06-3.3v-5.97l-4.64-2.83 3.45-4.33-8.8-4.62z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M311.93 435.29l-5.67-15.35-12.46-6.04-6.06 2.03-2.84 2.42-5.25.4-8.44 9.28.37 4.45 4.83 7.26-.37 4.43h2l9.28 12.97 5.76 1.17 4.13-5.28 12.1 2.24 3.44-12.59 3.91 2.3 5.88-3.22-3.35-6.86z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M247.79 478.49l2.38-9.31 10.13 2.83 1.18-7.26 13.13-.4 6.48-8.49 6.23 1.28-9.28-12.97h-2l.37-4.43-4.83-7.26-.37-4.45-10.13 2.03-2 6.05-5.66-2.84-6.46 1.23 10.09 10.48-8.48 4.03-.39 10.51-5.25-2.84-5.26 3.63-10.47 7.29 4.84 9.26-4.84 6.85-.3 8.98 7.16 1.11-1.61-6.04 2.38-2.41 7.72 2.34-.43-5.16z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M232.04 476.86l-4.84-9.26 10.47-7.29-16.92-4.03-4.87 1.59.43-5.21-6.07-7.68-4.02 1.19-.42 13.35 2.02 2.82-6.05 10.89-5.45 2.02-.07 9.68h0l7.13 8.08 7.66 1.6 2.42-1.6 4.44 1.2 5.66-2.03 3.34.51.3-8.98z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M234.83 421.57l-4.03-2.04-10.48 4.47-2.82-2.02-5.46 2.31-5.01 2.12-5.26 1.19v9.71l-3.23 1.2v4.01l7.68 3.65 4.02-1.19 6.07 7.68-.43 5.21 4.87-1.59 16.92 4.03 5.26-3.63 5.25 2.84.39-10.51 8.48-4.03-10.09-10.48-3.22-5.67z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M284.9 418.35l2.84-2.42-.82-7.26-4.85-2.83h-6.85l-4.01 4.05-4.07-2.45-5.22 3.66-10.52-7.28-6.06.4-1.6 2.81 2.42 2.86-3.23 6.83-8.1 4.85 8.91 7.26 3.22 5.67 6.46-1.23 5.66 2.84 2-6.05 10.13-2.03 8.44-9.28z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M293.46 495.73l-2.22-5.35-6.47.99-3-6.32 3.85-.52-.52-4.34 1.82-1.22-2.83-8.35-9.48-6.27-13.13.4-1.18 7.26-10.13-2.83-2.38 9.31-5.67 4.04.43 5.16 19.75 6.11 4.03 4.85 6.07.39 3.22 4.84 10.18-6.13z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M315.16 486.94l-13.69-.39v7.25l-8.01 1.93-7.66 2.02-10.18 6.13 13.31 8.09 9.71-2.42 12.9 4.86 3.21-4.86 11.71-2.42 5.64 2.84v-5.69l-3.61-2.4-.38-16.13-6.88-3.22z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M317.17 464.75l-8.06-4.85.2-4.63-12.1-2.24-4.13 5.28-5.76-1.17-6.23-1.28-6.48 8.49 9.48 6.27 2.83 8.35-1.82 1.22.52 4.34-3.85.52 3 6.32 6.47-.99 2.22 5.35 8.01-1.93v-7.25l13.69.39 6.07-4.41 6.88 3.22-.05-2.42 4.45-4.84-2.03-5.26 6.66-5.92-16.72-7.79z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M354.73 425.2l-7.29 4.42-6.65-2.79-5.06 3.3v2.35h-6.35l-.57 3.93-6.27 5.35-5.88 3.22-3.91-2.3-3.44 12.59-.2 4.63 8.06 4.85 3.25-5.23 16.72 7.79 4.27-3.77 2 1.21 6.06-8.47 4.03 2.42 12.93-9.69 3.22-11.29z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M386.18 432.48l6-5.32 4.93-4.38-8.09-14.52-12.11-5.27-3.62 4.45-6.06-4.02-1.62-6.86-5.67 4.84 2.84 10.87-6.45 4.06-6.86-3.23-9.87.6-3.45 4.33 4.64 2.83v5.97l6.65 2.79 7.29-4.42 14.92 12.52-3.22 11.29 3.22 4.84 3.64-.21 11.7-12.31z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M359.17 382.85l-7.28-6.07h-9.67l-2.83-4.84-2.84-.43-4.84 4.49h-2.81l-5.26 9.25 6.36 10.71.8 13.12 8.8 4.62 9.87-.6 6.86 3.23 6.45-4.06-2.84-10.87 5.67-4.84-6.04-6.87z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M461.26 392.51l-10.9-1.59-9.7 4.05-7.24 8.45 3.2 6.03-9.26-.78-8.07 5.63 5.65 4.05 1.19 10.48 6.47 7.28 6.86-2.01 3.22 2.39 6.08-5.64 4.84 1.63 4.43-4.05h6.44l3.23-4.84 6.88-.81-1.23-4.84-3.6-.41-5.28-11.29 6.07-6.07 3.69-15.8-4.91-2.35z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M451.96 360.64l-4.83-2.82-2.03 5.24 4.84 5.67v5.2l-5.64-1.99-12.12 5.24-3.61 3.62-3.63 3.64-3.24 9.7-5.26-.4 1.64 7.66 6.04 1.59 3.24 5.68 9.26.78-3.2-6.03 7.24-8.45 9.7-4.05 10.9 1.59 8.06-10.49 4.91 2.35 1.15-4.77-14.12-20.59-6.43-.36z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M485.05 435.7l13.75-5.25v-7.67l-2.82-2.02 6.83-12.5-5.22-3.65-6.87 4.47-20.18-8.91-6.07 6.07 5.28 11.29 3.6.41 1.23 4.84-6.88.81-3.23 4.84 4.46 7.68 8.87 9.26 7.25-4.04z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M464.47 428.43h-6.44l-4.43 4.05-4.84-1.63-6.08 5.64-3.22-2.39-6.86 2.01-4.44 10.48 2.83 5.65-3.63 4.44 4.44 3.63-4.84 6.06 11.28 6.46 2.03-4.43 4.83 2.39 4.84-4.04 5.26 2.43 12.13-6.07v-6.43l10.08-6.46.39-4.85-8.87-9.26z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M424.94 418.35l-5.65-4.05-14.12-5.63v-4.06l-3.65.4 1.2-6.03-3.22-2.42-2.81 4.44 1.22 4.41-2.04 1.24-4.43-2.04-2.42 3.65 8.09 14.52-4.93 4.38 14.18 7.74 9.71.39 9.25 4.45 7.28-3.63-6.47-7.28z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M427.36 456.68l3.63-4.44-2.83-5.65 4.44-10.48-7.28 3.63-9.25-4.45-9.71-.39-14.18-7.74-6 5.32-1.19 8.85-11.7 12.31 11.7-.61 3.61 5.67h6.09l2.42-5.26 8.06 6.87 8.48-3.63 3.22 2.02v6.05l10.09 1.62 4.84-6.06z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M387 394.97l4.44-9.72 4.04-9.67-4.45-14.94.8-8.87-3.23-4.04-8.87 6.43v4.85h-3.63v6.47l-7.25 8.06h-6.07l-3.61 9.31.4 6.84 6.04 6.87 1.62 6.86 6.06 4.02 3.62-4.45 12.11 5.27 2.42-3.65z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M420.1 377.18l-2.43-15.71-10.51 1.59-1.99-9.29-8.89-5.22-4.45 3.22-.8 8.87 4.45 14.94-4.04 9.67-4.44 9.72 4.44 9.64 4.43 2.04 2.04-1.24-1.22-4.41 2.81-4.44 3.22 2.42-1.2 6.03 3.65-.4v4.06l14.12 5.63 8.07-5.63-3.24-5.68-6.04-1.59-1.64-7.66 5.26.4 3.24-9.7 3.63-3.64z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M395.09 335.63l-1.24 6.45-3.2.41-2.05 5.24 3.23 4.04 4.45-3.22 8.89 5.22 1.99 9.29 10.51-1.59 2.43 15.71 8.47 3.62 3.61-3.62 12.12-5.24 5.64 1.99v-5.2l-4.84-5.67 2.03-5.24-5.25-1.63 1.2-12.51-8.48-4.03-2 3.2-9.28-2.79.43-6.85L412 319.08l-10.48 5.64 1.66 4.03z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M385.79 338.44l.39-2.42h-5.65l-6.04-5.25 1.61-6.05-5.23-15.33 4.45-2.83-.83-3.21-11.71-1.63-2.42-6.06-5.63 6.06-.81 11.68-4.04 3.66 2.43 2.82-2.04 6.06 10.9 16.91-3.24 4.05h16.13l1.26 5.25 4.41 2.01 8.87-6.43 2.05-5.24z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M378.5 298.89l-4.44-1.6.43 6.06.83 3.21-4.45 2.83 5.23 15.33-1.61 6.05 6.04 5.25h5.65l-.39 2.42 4.86 4.05 3.2-.41 1.24-6.45 8.09-6.88-1.66-4.03-6.04-1.23-2.82-6.43-4.88.4-1.19-6.86 2.83-5.26-1.19-4.84-5.27-4.41z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M424.12 296.46l-.8-3.22-3.22-.4-4.85 4.84-7.24-9.27-7.28-3.25-5.64 1.62-6.86 13.72 1.19 4.84-2.83 5.26 1.19 6.86 4.88-.4 2.82 6.43 6.04 1.23 10.48-5.64 11.75 14.13 1.57-17.79 2.04-8.45 6.85-6.47.28-10.1-6.33 6.06z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M425.32 315.42l21.41 12.92 7.65-14.52 6.07 9.31 6.88-2.04 4.82-5.25-4.45-8.87-2.42.79-2.02-15.31-3.24-3.25-15.33 3.25-6.84 8.45-3.64-.4-6.85 6.47z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M454.38 313.82l-7.65 14.52-21.41-12.92-1.57 17.79-.43 6.85 9.28 2.79 2-3.2 8.48 4.03-1.2 12.51 5.25 1.63 4.83 2.82 2.87-1.99 6.43.36 12.09-6.03 5.25-12.1-7.66-15.33-3.24-1.23-.37-3.23-6.88 2.04z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M281.25 323.49l-7.24-2.82-6.05-15.33-3.24-1.6 3.63-4.85-2.18-4.26-7.91-1.79-6.86 6.44h-4.44l-3.82 3.84v9.9h5.43v4.44l-5.64 2.42-8.1 10.47 7.29.42.81 5.25h7.69l1.6 4.86 6.86 5.64 24.2-15.75z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M301.82 323.13l7.29-6.88-1.41-6.83-1.44-6.89-9.65-10.08-2.81-.1-4.27-.1-1.79-1.43-2.42 1.21-6.69-1.42-1.19 3.82-9.88-1.79-1.39 1.99 2.18 4.26-3.63 4.85 3.24 1.6 6.05 15.33 7.24 2.82 2.03 7.28 6.86-2.84 9.29 3.65 5.64-.81 1.19-2.02z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M279.65 370.74h11.68v-4.84l3.24-2.84v-5.24l4.86-5.24v-4.44l7.68-3.63v-5.67l-2.04-2.02v-6.05l-5.64.81-9.29-3.65-6.86 2.84-24.2 15.75 1.6 14.95 4.86 1.59v3.23h8.09z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M158.19 258.12l6.86 4.04 4.41-7.66 2.04-14.51 10.5-.83-2.02-12.91-4.84-2.02-1.65-7.26-3.18.41-3.65-2.85-8.05 2.44-.81 4.02-8.9-1.59-8.05 3.23v5.64l-14.55 4.04-3.22 5.24v5.26l6.07 3.19 6.82-4.02 9.69 3.24-8.85 1.22-3.65 4.44 10.11 7.24 14.1 5.26z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M197.74 254.5h13.73l6.84-4.43v-4.44l4.86-11.69v-6.47l-21.78-10.5-7.71 2.43-20.19-2.43 1.65 7.26 4.84 2.02 2.02 12.91-10.5.83-2.04 14.51 13.35 3.62 8.48 1.63z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M201.39 216.97l2.41-10.1 5.65-2.8-.81-6.48 4.02-4.04-1.19-4.83 3.22-5.24-3.65-3.63v-5.68h-4.82l-10.91 7.3-4.02-1.62-2.83 1.62-3.22-4.85h-6.87l1.61 10.49-2.43 2.02v8.08l3.64 3.23-2.45 6.43-5.25 2.45v7.65l20.19 2.43z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M233.23 227.07l4.44-2.84 8.49-14.91-1.19-6.06 4.44-2.82-4.07-5.67-15.72-7.23.42-5.27-8.09-2.04v3.25h-7.26l-3.22 5.24 1.19 4.83-4.02 4.04.81 6.48-5.65 2.8-2.41 10.1 21.78 10.5 7.63-2.82z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M187.22 294.87h8.9l-6.06-6.06 1.23-11.31-8.48-19.38-13.35-3.62-4.41 7.66-6.86-4.04-.82 5.26-14.1-5.26-8.06 8.88 8.47 7.7.39 11.7 20.98 12.08 7.68-.8 6.83-2.81z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M89.58 179.04l.81 8.86 12.09 5.65 4.45-6.01 13.72 8.45 3.22 4.84 3.24-8.48 5.26 3.64 3.6-2.44 12.93-8.86-1.19-9.3-13.74-6.43-7.27 1.19-3.23 5.24h-2.42l-12.48-20.95-17.37 3.21-1.18 5.24 1.99 8.09z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M140.85 222.63l3.22-8.5-1.62-9.28-6.48-11.3-3.6 2.44-5.26-3.64-3.24 8.48-3.22-4.84-13.72-8.45-4.45 6.01-12.09-5.65-6.05 7.67 11.69 8.5-7.76 11.52 8.58 2.61 5.24 11.69 3.61-3.23 7.29 2.41 13.31 3.24 14.55-4.04z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M84.34 195.57l6.05-7.67-.81-8.86 2.43-8.06-1.99-8.09-6.91-.8-.81 3.63-5.63-.41-1.21-4.04-6.49-.39-2.41 3.25-4.42-2.04L46 172.18l2 6.86 15.34.81 1.22 5.67-10.88-2.04v4.81l7.67.43-.42 4.83-11.29.42-.42 5.64 4.04-.8 5.63 7.26-.79 5.26 7.26 1.6-.4-4.82 13.34 4.41 9.97 3.07 7.76-11.52z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M135.97 193.55l6.48 11.3 1.62 9.28-3.22 8.5 8.05-3.23 8.9 1.59.81-4.02 8.05-2.44 3.65 2.85 3.18-.41v-7.65l5.25-2.45 2.45-6.43-3.64-3.23v-8.08l2.43-2.02-1.61-10.49-5.64-1.23-5.27 4.05-6.43-4.45-10.13-8.05-5.63 2.82 2.44 5.63 1.19 9.3z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M186.83 149.98l-4.83 3.23 5.65 8.88 17.75-5.23 9.29 1.59 6.06-6.43 9.29 2.82 2.41-2.82-3.64-20.2-10.5-.83-2.43 5.26-8.85 2.44-7.27-4.45-22.21-4.02-.38 3.21 8.07 6.47z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M191.29 179.85l4.02 1.62 10.91-7.3h4.82v5.68l3.65 3.63h7.26v-3.25l8.09 2.04-.42 5.27 15.72 7.23-1.6-8.06 7.66-4.03v-5.67l-6.43-6.03-2.85-10.1h-4.83v-2.83l-7.25-3.21-9.29-2.82-6.06 6.43-9.29-1.59-17.75 5.23 3.25 7.67-5.66 6.86 3.22 4.85z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M172.73 175.39l5.64 1.23h6.87l5.66-6.86-3.25-7.67-5.65-8.88 4.83-3.23-1.59-10.08-8.07-6.47-4.05-.42-2.42-11.67 3.23-4.04-1.6-3.24-7.28-2.82-2.42 5.26-7.27-2.44-2.42-2.82h-3.23l2.83 18.98 9.28 10.08-4.02 23 6.44 6.02-3.21 5.67 6.43 4.45z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M230.04 154.84l7.25 3.21v2.83h4.83l2.85 10.1 3.6-6.07 14.92-2.02 6.88-7.26-1.21-5.65 4.04-1.62 2.84-6.46 4.84-3.21-3.65-8.47-8.67-3.05-14.32 10.09-12.53-7.04-12.09-.83-.81 2.43 3.64 20.2z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M254.24 137.26l14.32-10.09 8.67 3.05 2.83-4.46-4.02-7.24 1.63-9.92.37-2.2-6.83-10.9-6.97-.76-5.16 6.82-12.52 5.66-12.92.38-14.11 9.7.38 8.06 9.71 4.03 12.09.83z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M463.68 148.77l-3.66 2.85v4.22l12.13 8.48-.22 7.03-5.83 4.04 2.62 12.9 13.91 7.7 9.31-28.26 11.67-15.71-9.67-6.91-10.08 1.23-7.08 6.26z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M459.05 211.11l6.23 4.66 2.84 7.26-.79 6.86 2.42-1.19 5.63 4.01 3.64.4 6.44-8.88-2.42-6.49-.41-21.75-13.91-7.7z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M344.21 198.81h3.23l8.5 10.08 5.65-2.02 4.84 2.85h10.08v-4.44h6.07v-4.84l5.2-3.23v-9.67l-5.63-3.66-1.2-6.87-11.3-2.84-3.63-7.66-8.87 2.81-9.71 7.69-8.04-2.84-3.58 4.9-.09 5.62 8.1 7.66z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M380.95 177.01l1.2 6.87 5.63 3.66v9.67l-5.2 3.23v4.84h3.6l.82 4.83 5.23 4.42-2.4 2.85 1.61 4.02h8.89l2 3.25 7.28.4v-3.25h8.47v-6.03l5.67-5.25v-4.45l-4.89-6.06h-3.2l2.83-6.46-7.29-8.43h-4.84l-1.19-5.68-15.75-12.1-4.06 2.42z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M338.57 168.13l.83 6.04 8.04 2.84 9.71-7.69 8.87-2.81 3.63 7.66 11.3 2.84 4.41-7.25 4.06-2.42 1.23-2.43-3.65-3.23 1.6-8.88h4.06l-4.88-10.06v-5.28l-8.05 1.02-5.67-3.84h-4.81l-3.86-4.42-6.64-.83-1.6 3.54-6.58-2.53-5.85 3.16v5.63l2.34 2.42-3.15 3 2.94 4.26-8.55 11.44-3.76 4.84z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M364.01 111.24l-7.68 9.67 2.42 8.48 6.64.83 3.86 4.42h4.81l5.67 3.84 8.05-1.02h4.05l3.65-9.66-3.25-3.24v-3.65l6.48.82 6.86-1.2-14.13-12.11-6.84-1.2V94.31l-4.44-3.25-5.67 3.63-.43 6.87-12.07 1.2.4 5.66z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M281.25 78.96l10.08 8.06h12.94l-2.02 5.65h12.1v2.43l10.49.4 2.83 2.01.19-5.23-1.59-3.46 3-4.22-8.46-13.72-4.22-1-4.06-6.26-9.45-1.62-6.69-9.28-4.24-8.06-13.32 4.03-7.62 4.85-1.36 24.99 8.19 2.43z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M303.08 62l9.45 1.62 4.06 6.26 4.22 1 8.46 13.72-3 4.22 1.59 3.46-.19 5.23 15.34-2.01 15.35 3.22 2.43-14.92-4.85-3.24-10.88 1.62-.85-8.06-6.87-6.05-3.21 5.65-4.86-4.85V60.8l-2.81-5.26-10.9 5.66-6.84-7.66-2.03-13.33-14.54 4.45 4.24 8.06z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M291.76 141.09l14.93 4.02 8.03 2.98 6.91-.44 4.99-2.41-3.99-10.6 7.09-11.3-3.85-4.22 1.43-6.07-11.53 2.63-4.83 5.66-6.47-4.04-17.33-4.67-2.24 3.24-7.23-7.27-1.63 9.92 4.02 7.24-2.83 4.46 3.65 8.47-4.84 3.21 11.7-.81z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M278.04 106.4l-.37 2.2 7.23 7.27 2.24-3.24 17.33 4.67 6.47 4.04 4.83-5.66 11.53-2.63-1.87-6.05 2.24-7.07v-2.42l-2.83-2.01-10.49-.4v-2.43h-12.1l2.02-5.65h-12.94l-10.08-8.06-3.21 2-8.19-2.43-.27 5.27 3.23 4.04-4.85 2-3.72 4.9 6.97.76z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M327.67 97.51v2.42l-2.24 7.07 1.87 6.05-1.43 6.07 3.85 4.22-7.09 11.3 3.99 10.6 11.68 15.07 8.55-11.44-2.94-4.26 3.15-3-2.34-2.42v-5.63l5.85-3.16 6.58 2.53 1.6-3.54-2.42-8.48 7.68-9.67-1.62-2.82-.4-5.66-3.63-4.04-15.35-3.22z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M418.49 193.55l-2.83 6.46h3.2l4.89 6.06 6.83-3.21 7.66 4.44 4.44-1.62 4.45 2.81 4.83-2.42 7.09 5.04 9.67-22.82-2.62-12.9-8.88 5.87-8.46-4.02-1.44 3.39-10.28-1-9.3 4.66-4.62-9.88h-3.83l-9.49 6.85-3.44 3.86h4.84z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M425.11 144.53l1.85 9.28h10.51l.57 6.07 11.3 3.42 5.27 5.45 8.87 1.6 2.62 5.04 5.83-4.04.22-7.03-12.13-8.48v-4.22l3.66-2.85 13.1 3.83 7.08-6.26-11.71-7.65-3.63 2.81-20.57-2.81-4.45-11.71-15.34-3.64-.8 2.84h-4.43l3.82 10.5z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M413.23 127.6l.79 5.41 7.09 10.72-.82 8.07-2.62 4.23 1.62 3.85-2.24 3.42 2.24 11.11h3.83l4.62 9.88 9.3-4.66 10.28 1 1.44-3.39 8.46 4.02 8.88-5.87-2.62-5.04-8.87-1.6-5.27-5.45-11.3-3.42-.57-6.07h-10.51l-1.85-9.28 1.64-7.85-3.82-10.5-8.48-6.46-8.88.81 1.42 9.07z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M392.23 120.91v3.65l3.25 3.24-3.65 9.66h-4.05v5.28l4.88 10.06h-4.06l-1.6 8.88 3.65 3.23-1.23 2.43 15.75 12.1 1.19 5.68 3.44-3.86 9.49-6.85-2.24-11.11 2.24-3.42-1.62-3.85 2.62-4.23.82-8.07-7.09-10.72-.79-5.41-6.24 2-1.42-9.07-6.86 1.2z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M274.01 171.78h3.22l2.83 8.85h4.03l6.25-4.41v-6.64l3.31-3.53 1.55-9.26-5.61.41-13.34-3.18-3.05-5.66-4.04 1.62 1.21 5.65 3.64 6.05z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M289.59 157.2l5.61-.41 3.15-1.97 5.17-2.37 5.21.09-2.04-7.43-14.93-4.02h-4.02l-11.7.81-2.84 6.46 3.05 5.66z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M298.35 154.82l3.57-1.64" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M303.52 152.45l-1.6.73" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M273.2 148.36l3.05 5.66 13.34 3.18 5.61-.41" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M303.52 152.45l-1.6.73" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M303.52 152.45l5.21.09" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M301.92 153.18l-3.57 1.64" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M295.2 156.79l-1.55 9.26" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M295.2 156.79l3.15-1.97" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M301.92 153.18h0" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M301.82 168.83l6.76.73" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M293.65 166.05l5.85 2.75 2.32.03" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M306.69 145.11l2.04 7.43" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M308.58 169.56l2.9-8.28" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M308.73 152.54l2.75 8.74" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M439.86 268.03l-11.12-12.52h-6.02l2.39-6.44-2.39-5.44-9.07-1.24-9.71 18.96 6.07 2.85-.82 8.04 4.46 3.24-5.64 12.93 7.24 9.27 4.85-4.84 3.22.4.8 3.22h4.04l6.33-6.06.11-4.83 3.64-4.83v-3.24l-3.41-3.63z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M447.13 208.49l-4.45-2.81-4.44 1.62-7.66-4.44-6.83 3.21v4.45l-5.67 5.25v6.03h-8.47v3.25l2.84 2.42-3.67 6.07 4.87 8.85 9.07 1.24 22.6-16.56 4.44 2.82 2.42-3.64 7.24.41-1.97-10.11 1.6-5.44-7.09-5.04z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M465.28 215.77l-6.23-4.66-1.6 5.44 1.97 10.11.83 5.18 4.28 2.03 2.8-3.98.79-6.86z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M459.42 226.66l-7.24-.41-2.42 3.64-4.44-2.82-22.6 16.56 2.39 5.44-2.39 6.44h6.02l11.12 12.52-5.03 5.84 3.41 3.63 10.52-11.3.39-5.63 17.73-23.02-2.35-3.68-4.28-2.03z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M323.64 210.52v8.05l-5.23 2.04 2.4 8.85 8.91.23 5.83 5.04 6.46-3.21 16.35 11.29 4.62-1.62.81-11.3 8.48-13.52-5.84-6.65-4.84-2.85-5.65 2.02-8.5-10.08h-3.23l-.38-6.46-8.1-7.66-12.5 4.84 2.03 3.25-2.84 4.43 4.44 10.9z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M342.01 231.52l-6.46 3.21-5.83-5.04-8.91-.23-2.81 3.65.8 9.28 6.46 9.29v20.56l4.46 5.26 4.82-2.02 6.45 3.23 4.44-4.01 8.28 1.41 8.08-5.06.4-9.09-3.23-1.82 3.82-8.27 2.61 1.41 3.26-2.62-5.67-9.47-4.62 1.62z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M385.58 262.76l-16.93-12.1-3.26 2.62-2.61-1.41-3.82 8.27 3.23 1.82-.4 9.09-8.08 5.06-8.28-1.41 4.45 9.66 8.48 4.84 2 6.46 2.42 6.06 11.71 1.63-.43-6.06 4.44 1.6 4.46-2.8 5.27 4.41 6.86-13.72 5.64-1.62 7.28 3.25 5.64-12.93-4.46-3.24.82-8.04-6.07-2.85-6.03-2.8z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M227.59 264.2h3.65l4.43-3.63 12.51 14.91 5.04-4.03 4.46-14.73 3.8 1.83 3.46-2.82v-5.26l-3.46-6.26-5.42.6-.22-13.29-18.17-7.29-4.44 2.84-2.43-2.42-7.63 2.82v6.47l-4.86 11.69v4.44l9.28 8.05z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M277.44 294.43l1.19-3.82 6.69 1.42 2.42-1.21 1.79 1.43 4.27.1.38-13.24-4.44-8.06 2.62-2.02-3.81-14.92-7.67.19 5.63-6.85-13.91-.82-7.66 3.84v5.26l-3.46 2.82-3.8-1.83-4.46 14.73-5.04 4.03v7.27l3.22 3.24h3.66l3.2 6.85 7.91 1.79 1.39-1.99z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M288.55 254.11l3.81 14.92-2.62 2.02 4.44 8.06-.38 13.24 2.81.1 4.45-6.05 6.83.78v-6.44l5.67-5.68h3.21l2.42-2.82h6.07v-20.56l-6.46-9.29-.8-9.28-11.11-.8-12.92-8.29 1.02 7.86 2.6 6.86-5.83 1.01 2.21 4.86-7.46 2.84-5.63 6.85z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M246.16 209.32l-8.49 14.91 18.17 7.29.22 13.29 5.42-.6 3.46 6.26 7.66-3.84 13.91.82 7.46-2.84-2.21-4.86 5.83-1.01-2.6-6.86-1.02-7.86-13.69.21-7.47-7.86-1.23-10.1-8.65 3.05-3.03-3.64-10.49-5.24-4.44 2.82z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M280.28 224.23l13.69-.21 12.92 8.29 11.11.8 2.81-3.65-2.4-8.85 5.23-2.04v-8.05l3.22-2.41-4.44-10.9h-6.44l-6.87 3.23-4.04-2.85 1.62-4.41-6.42-2.75-6.89-2.89-5.23 2.82-3.85 8.65-3.05 4.84-9.67 2.42 1.23 10.1z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M251.4 182.68l-7.66 4.03 1.6 8.06 4.07 5.67 10.49 5.24 3.03 3.64 8.65-3.05 9.67-2.42 3.05-4.84 3.85-8.65-4.06-9.73h-4.03l-2.83-8.85h-3.22v-10.1l-3.64-6.05-6.88 7.26-14.92 2.02-3.6 6.07 6.43 6.03z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M412.45 227.47l-2.84-2.42-7.28-.4-2-3.25h-8.89l-1.61-4.02 2.4-2.85-5.23-4.42-.82-4.83h-9.67v4.44h-10.08l5.84 6.65-8.48 13.52-.81 11.3 5.67 9.47 16.93 12.1 12.33-4.21 6.03 2.8 9.71-18.96-4.87-8.85z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M291.33 374.76v-4.02h-11.68l-6.02-4.45h-8.09V376l-6.07 9.25-4.41.41-3.88 6-2.61 4.07 2.83 8.09 10.52 7.28 5.22-3.66 4.07 2.45 4.01-4.05h6.85l2.83-2.85-2-3.61 6.84-4.85 5.25-4.44-1.19-12.91z" />
                  <path fill="none" stroke="#FFF" stroke-width=".25" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M300.27 190.43l7.62-5.74-1.63-8.07 2.32-7.06-6.76-.73-2.32-.03-5.85-2.75-3.31 3.53v6.64l-6.25 4.41 4.06 9.73 5.23-2.82z" />
                </g> */}
                  <path className="reg reg-2B" onClick={() => loadInfosDept("2B", "")} data-uk-tooltip={`title: ${data.find((el: "2B") => el.department === "2B")?.name}; cls: tooltip-map; animation: uk-animation-scale-up;`} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M531.25 462.38l-.61-19.82-4.96.43-1.94 18.76-8.84-2.17-12.92 6.46-11.85 14.69 4.95 5.17 9.45.2 13.59 12.3 12.28 8.61 4.98-9.04.42-24.58z" />
                  <path className="reg reg-2A" onClick={() => loadInfosDept("2A", "")} data-uk-tooltip={`title: ${data.find((el: "2A") => el.department === "2A")?.name}; cls: tooltip-map; animation: uk-animation-scale-up;`} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M504.53 486.1l-9.45-.2-3.68 2.99.87 5.62 6.23 4.1-4.94 9.27 6.91 1.73-3.69 7.94 8.43 4.53-4.32 4.33 14.65 12.29 6.25 1.3 6.89-12.74 1.72-20.25-12.28-8.61z" />
                  <path className="reg reg-75" onClick={() => loadInfosDept("75", "")} data-uk-tooltip={`title: ${data.find((el: "75") => el.department === "75")?.name}; cls: tooltip-map; animation: uk-animation-scale-up;`} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M486.6 55.04l-7.17 4.26-1.4 2.07-3.52-.31-2.88 1.07-4.04 3.83-.24 2.84 5.24 1.51 1.1 3.21 2.03-.07 10.62 4.78h1.86l3.74.02 1.99 1.08 8.64-3.45 4.16 1.14 6.16 1.89.79-3.73.34-2.29-3.86-1.63-4.27 2.54-2.04-.18.86-4.68-.48-6.77-2.65-1.48-1.46-5.39z" />
                  <path className="reg reg-92" onClick={() => loadInfosDept("92", "")} data-uk-tooltip={`title: ${data.find((el: "92") => el.department === "92")?.name}; cls: tooltip-map; animation: uk-animation-scale-up;`} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M486.6 55.04l-7.17 4.26-1.4 2.07-3.52-.31-2.88 1.07-4.04 3.83-.24 2.84 5.24 1.51 1.1 3.21 2.03-.07 10.62 4.78h1.86l-3.24 7.15 2.06 2.78-4.13 6.65-.4 3.58-4.21.16-.25-5.17-6.99-1.87-2-2.36-.24-3.76-3-.47-.5-3.06-3.75-.47-2.74-4.93-2.01 1.18-.99-4.24.75-12.45 3.5-5.88 6.73-3.29 7.74-6.34 7.5-3.06.63-.24 3.34.32 3.93 1.73-.22 2.79-4.05 4.26z" />
                  <path className="reg reg-93" onClick={() => loadInfosDept("93", "")} data-uk-tooltip={`title: ${data.find((el: "93") => el.department === "93")?.name}; cls: tooltip-map; animation: uk-animation-scale-up;`} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M486.6 55.04l-.94-3.8 4.05-4.26.22-2.79-3.93-1.73-3.34-.32-.63.24.5-3.06 1.99-.7 5.52 2.14 8.21-3.79 4.75 4 10.24-.24 3.49-2.35 2.01.94 5.24-5.87 1.39-1.49 1.35-2.98 2.75.47 2.09-3.68 3.17 2.51-2.99 5.98 3.91 6.89-4.78 17.32 4.78 4.17-3.59 2.99 1.46 13.03-3.73-1.31-.45-2.69-9.31-7.32-5.41-.42-9.91 2.02-.48-6.77-2.65-1.48-1.46-5.39z" />
                  <path className="reg reg-94" onClick={() => loadInfosDept("94", "")} data-uk-tooltip={`title: ${data.find((el: "94") => el.department === "94")?.name}; cls: tooltip-map; animation: uk-animation-scale-up;`} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M502.57 75.88l4.16 1.14 6.16 1.89.79-3.73.34-2.29-3.86-1.63-4.27 2.54-2.04-.18.86-4.68 9.91-2.02 5.41.42 9.31 7.32.45 2.69 3.73 1.31 3.91 9.07-5.96 11.94 2.39 8.36-5.37 4.75-2.29-2.23-4.72-9.88-14.24 3.76-4.24-.7-1-1.18-5.75 1.18-1.48-6.35-3.51.71-3.99-2.59-2.67.29-2.11 2.6.4-3.58 4.13-6.65-2.06-2.78 3.24-7.15 3.74.02 1.99 1.08z" />
                  {/* <path className="reg reg-75" onClick={() => loadInfosDept("75", "")} data-uk-tooltip={`title: ${data.find((el: "75") => el.department === "75")?.name}; cls: tooltip-map; animation: uk-animation-scale-up;`} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M531.25 462.38l-.61-19.82-4.96.43-1.94 18.76-8.84-2.17-12.92 6.46-11.85 14.69 4.95 5.17 9.45.2 13.59 12.3 12.28 8.61 4.98-9.04.42-24.58z" /> */}
                  {/* <path className="reg reg-" onClick={() => loadInfosDept("27", "")} data-uk-tooltip={`title: ${data.find((el: "27") => el.department === "27")?.name}; cls: tooltip-map; animation: uk-animation-scale-up;`} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M504.53 486.1l-9.45-.2-3.68 2.99.87 5.62 6.23 4.1-4.94 9.27 6.91 1.73-3.69 7.94 8.43 4.53-4.32 4.33 14.65 12.29 6.25 1.3 6.89-12.74 1.72-20.25-12.28-8.61z" />
                  <path className="reg reg-" onClick={() => loadInfosDept("27", "")} data-uk-tooltip={`title: ${data.find((el: "27") => el.department === "27")?.name}; cls: tooltip-map; animation: uk-animation-scale-up;`} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M486.6 55.04l-7.17 4.26-1.4 2.07-3.52-.31-2.88 1.07-4.04 3.83-.24 2.84 5.24 1.51 1.1 3.21 2.03-.07 10.62 4.78h1.86l3.74.02 1.99 1.08 8.64-3.45 4.16 1.14 6.16 1.89.79-3.73.34-2.29-3.86-1.63-4.27 2.54-2.04-.18.86-4.68-.48-6.77-2.65-1.48-1.46-5.39z" />
                  <path className="reg reg-" onClick={() => loadInfosDept("27", "")} data-uk-tooltip={`title: ${data.find((el: "27") => el.department === "27")?.name}; cls: tooltip-map; animation: uk-animation-scale-up;`} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M486.6 55.04l-7.17 4.26-1.4 2.07-3.52-.31-2.88 1.07-4.04 3.83-.24 2.84 5.24 1.51 1.1 3.21 2.03-.07 10.62 4.78h1.86l-3.24 7.15 2.06 2.78-4.13 6.65-.4 3.58-4.21.16-.25-5.17-6.99-1.87-2-2.36-.24-3.76-3-.47-.5-3.06-3.75-.47-2.74-4.93-2.01 1.18-.99-4.24.75-12.45 3.5-5.88 6.73-3.29 7.74-6.34 7.5-3.06.63-.24 3.34.32 3.93 1.73-.22 2.79-4.05 4.26z" />
                  <path className="reg reg-" onClick={() => loadInfosDept("27", "")} data-uk-tooltip={`title: ${data.find((el: "27") => el.department === "27")?.name}; cls: tooltip-map; animation: uk-animation-scale-up;`} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M486.6 55.04l-.94-3.8 4.05-4.26.22-2.79-3.93-1.73-3.34-.32-.63.24.5-3.06 1.99-.7 5.52 2.14 8.21-3.79 4.75 4 10.24-.24 3.49-2.35 2.01.94 5.24-5.87 1.39-1.49 1.35-2.98 2.75.47 2.09-3.68 3.17 2.51-2.99 5.98 3.91 6.89-4.78 17.32 4.78 4.17-3.59 2.99 1.46 13.03-3.73-1.31-.45-2.69-9.31-7.32-5.41-.42-9.91 2.02h0l-.48-6.77-2.65-1.48-1.46-5.39z" />
                  <path className="reg reg-" onClick={() => loadInfosDept("27", "")} data-uk-tooltip={`title: ${data.find((el: "27") => el.department === "27")?.name}; cls: tooltip-map; animation: uk-animation-scale-up;`} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M502.57 75.88l4.16 1.14 6.16 1.89.79-3.73.34-2.29-3.86-1.63-4.27 2.54-2.04-.18.86-4.68 9.91-2.02 5.41.42 9.31 7.32.45 2.69 3.73 1.31 3.91 9.07-5.96 11.94 2.39 8.36-5.37 4.75-2.29-2.23-4.72-9.88-14.24 3.76-4.24-.7-1-1.18-5.75 1.18-1.48-6.35-3.51.71-3.99-2.59-2.67.29-2.11 2.6.4-3.58 4.13-6.65-2.06-2.78 3.24-7.15 3.74.02 1.99 1.08z" /> */}

                </g>
              }
            </>
          }


        </svg>
      </div >
    )
  }

export default MapFrance;